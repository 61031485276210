import React from 'react';
import { Label, NumericInput, RadioGroup, Radio, Checkbox } from '@blueprintjs/core';
import { appstyles } from '../styles/appstyles';

const GridSettings = ({ settings, setSettings }) => {
  const setSettingProp = (name, value) => {
    setSettings((prevSettings) => ({ ...prevSettings, [name]: value }));
  }

  return (
    <div>
    <div style={appstyles.controlsHorizontal}>
      <Label>
        Vertical lines:
        <NumericInput value={settings.thickLineEveryX} min="0" style={appstyles.numeric}
          onValueChange={(value) => setSettingProp("thickLineEveryX", value)} />
      </Label>
      <Label>
        Horizontal lines:
        <NumericInput min="0" style={appstyles.numeric} value={settings.thickLineEveryY}
          onValueChange={value => setSettingProp("thickLineEveryY", value)} />
      </Label>
      <Label>Cell Size:
        <NumericInput value={settings.cellSize} min="5" style={appstyles.numeric}
          onValueChange={(value) => setSettingProp("cellSize", value)} />
      </Label>
      </div>
      <RadioGroup inline selectedValue={settings.yscale}
        onChange={(e) => setSettingProp("yscale", Number(e.target.value))}>
        <Radio label="Square" value={1} />
        <Radio label="Rectangle (4:5 ratio)" value={0.8} />
        <Radio label="Rectangle (2:3 ratio)" value={0.67} />
      </RadioGroup>
      <Checkbox label="Show icons" checked={settings.showIcons} onChange={(e) => setSettingProp("showIcons", e.target.checked)} />    
    </div>
  );
};

export default GridSettings;
