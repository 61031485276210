import { Card } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import { useGetItemsQuery } from '../store/docApi';
import { formatDateShort } from '../utils';
import { appstyles } from '../styles/appstyles';

export default function EventList() {
  const { data, error, isLoading } = useGetItemsQuery({ container: 'event', label: 'event' })
  const navigate = useNavigate();

  let items = [];
  if (data) {
    items = [...data]
    items.sort((p1, p2) => { return new Date(p2.startdate) - new Date(p1.startdate) });
  }

  if (error) {
    console.log(error)
  }

  function isCurrent(event) {
    if (!event.startdate || !event.startdate)
      return false;
    let start = new Date(event.startdate);
    let end = new Date(event.enddate);
    let now = new Date(Date.now());
    return (start <= now && now <= end);
  }

  return error ? <span>Error {error.status}</span> :
    isLoading ? (<div>Loading...</div>) :
    <div style={appstyles.content}>
      <h2>Events</h2>
      <div style={styles.list}>
        {items.map((event, i) => (
          <Card style={isCurrent(event) ? styles.currentCard : styles.card} key={i}
            interactive onClick={() => navigate(`/event/${event.pk}/${event.id}`)}>
            <center>
              <div><b>{event.name}</b></div>
              <div className="bp4-text-small">
                {event.startdate && formatDateShort(event.startdate)}
                {event.enddate && <span> - {formatDateShort(event.enddate)}</span>}
              </div>
            </center>
          </Card>
        ))}
      </div>
      </div>
}

const styles = {
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  card: {
    width: 300,
    margin: 10
  },
  currentCard: {
    width: 300,
    margin: 10,
    backgroundColor: 'lightyellow'
  }
}