import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebaseConfig';
import { Spinner } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { store } from "./store/store";
import { loginAction, selectAccessToken } from "./store/userSlice";
import { docApi } from "./store/docApi";
import Layout from './Layout';
import ProfilePage from "./Pages/ProfilePage";
import Patterns from "./Pattern/Patterns";
import Pattern from "./Pattern/Pattern";
import PatternCreate from "./Pattern/PatternCreate";
import Library from "./Library/Library";
import MotifCreate from "./Motif/MotifCreate";
import WelcomePage from "./Pages/WelcomePage";
import HomePage from "./Pages/HomePage";
import Motifs from "./Motif/Motifs";
import LoginPage from "./Pages/LoginPage";
import Collections from "./Collection/Collections";
import UserPage from "./Pages/UserPage";
import ProjectCreate from "./Project/ProjectCreate";
import Projects from "./Project/Projects";
import SearchPage from "./Pages/SearchPage";
import EventList from "./Event/EventList";
import EventDetails from "./Event/EventDetails";
import ShopManage from "./Shop/ShopManage";
import ShopList from "./Shop/ShopList";
import ShopDetails from "./Shop/ShopDetails";
import ProductDetails from "./Shop/ProductDetails";
import CheckoutComplete from "./Shop/CheckoutComplete";
import Orders from "./Shop/Orders";
import Ravelry from "./Ravelry/Ravelry";
import CollectionDetails from "./Collection/CollectionDetails";
import DesignMotifDetails from "./Design/MotifDetails.js";
import MotifDetails from "./Motif/MotifDetails";
import NoteDetails from "./Note/NoteDetails";
import Notes from "./Note/Notes";
import NoteCreate from "./Note/NoteCreate";
import ExplorePage from "./Explore/ExplorePage.js";
import ShopDetailsPage from "./Explore/ShopDetails.js";
import References from "./Reference/References.js";
import ReferenceDetails from "./Reference/ReferenceDetails.js";
import Parts from "./Part/Parts.js";
import PartDetails from "./Part/PartDetails.js";
import PartCreate from "./Part/PartCreate.js";
import ContentPage from "./Content/ContentPage.js";
import Yarn from "./Yarn/Yarn.js";
import YarnList from "./Yarn/YarnList.js";
import YarnView from "./Yarn/YarnView.js";
import Design from "./Design/Design.js";
import ContentCreate from "./Content/ContentCreate.js";
import DesignLayoutDetails from "./Design/LayoutDetails.js";
import LibraryItemDetails from "./Library/LibraryItemDetails.js";

export default function App() {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState();
  document.title = "STITCH PORTAL";
  let location = useLocation();
  let dispatch = useDispatch();
  const currentToken = useSelector(selectAccessToken);

  function updateToken(idToken) {
    if (currentToken !== idToken) {
      console.log("Resetting token");
      store.dispatch(loginAction({ accessToken: idToken }));
      dispatch(docApi.util.resetApiState());
    }
  }

  function handleAuthStateChanged(user) {
    setUser(user);
    if (user) {
      console.log("logged in as " + user.email);
      auth.currentUser.getIdToken()
        .then(function (idToken) {
          updateToken(idToken);
          setInitializing(false);
        })
        .catch(function (error) {
          console.log("Error refreshing token", error);
          updateToken(null);
          setInitializing(false);
        });
    } else {
      console.log("unauthenticated");
      updateToken(null);
      setInitializing(false);
    }
  }

  useEffect(() => {
    return onAuthStateChanged(auth, handleAuthStateChanged);
  });

  return initializing ? <Spinner style={{ padding: 200 }} /> :
    location.pathname.startsWith("/@") && location.pathname.length > 3 ? (
      <Layout showLogo={false}><UserPage /></Layout>
    ) : user ? (
      <Layout showLogo={true}>
        <Routes>
          <Route exact path='/' element={<HomePage />} />

          {/* CONTENT */}
          <Route path='/designs' element={<ContentPage />} />
          <Route path='/content/create' element={<ContentCreate />} />
          <Route path='/content/create/:label' element={<ContentCreate />} />

          <Route path='/design/:pk/:id' element={<Design />} />
          <Route path='/design/:pk/:id/:tab' element={<Design />} />
          <Route path='/design/:pk/:id/motif/:index' element={<DesignMotifDetails />} />
          <Route path='/design/:pk/:id/layout/:index' element={<DesignLayoutDetails />} />

          {/* PROJECTS */}
          <Route path='/projects' element={<Projects />} />
          <Route path='/project/:pk/:id' element={<Projects />} />
          <Route path='/project/:pk/:id/:index' element={<Projects />} />
          <Route path='/project/:pk/:id/:index/:stepId' element={<Projects />} />
          <Route path='/project/create/:id' element={<ProjectCreate />} />
          <Route path='/project/create' element={<ProjectCreate />} />

          <Route path='/pattern/:pk/:id' element={<Pattern />} />
          <Route path='/pattern/create' element={<PatternCreate />} />

          <Route path='/collection/:id' element={<CollectionDetails />} />
          <Route path='/collection/:id/:index' element={<DesignMotifDetails />} />

          <Route path='/motif/:pk/:id' element={<MotifDetails />} />
          <Route path='/motif/create/collection/:pk/:id' element={<MotifCreate />} />
          <Route path='/motif/create' element={<MotifCreate />} />
          <Route path='/yarn/:pk/:id' element={<Yarn />} />

          <Route path='/part/:pk/:id' element={<PartDetails />} />
          <Route path='/part/create/pattern/:pk/:id' element={<PartCreate />} />
          <Route path='/part/create' element={<PartCreate />} />

          <Route path='/patterns' element={<Patterns />} />
          <Route path='/collections' element={<Collections />} />
          <Route path='/parts' element={<Parts />} />
          <Route path='/motifs' element={<Motifs />} />

          {/* LIBRARY */}
          <Route path='/library' element={<Library />} />
          <Route path='/library/:label/:pk/:id' element={<LibraryItemDetails />} />

          {/* EXPLORE */}
          <Route path='/explore' element={<ExplorePage />} />
          <Route path='/explore/:pk' element={<ShopDetailsPage />} />
          <Route path='/explore/:pk/:id' element={<ShopDetailsPage />} />

          {/* GLOBAL */}
          <Route path='/resources' element={<YarnList />} />
          <Route path='/resources/:label/:pk/:id' element={<YarnView />} />

          {/* USER ACCOUNT */}
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/orders' element={<Orders />} />

          {/* MORE (FUTURE) */}
          <Route path='/search' element={<SearchPage />} />
          <Route path='/notes' element={<Notes />} />
          <Route path='/note/:pk/:id' element={<NoteDetails />} />
          <Route path='/note/create' element={<NoteCreate />} />
          <Route path='/events' element={<EventList />} />
          <Route path='/event/:pk/:id' element={<EventDetails />} />
          <Route path='/shop/manage' element={<ShopManage />} />
          <Route path='/shops' element={<ShopList />} />
          <Route path='/shop/:pk/:id' element={<ShopDetails />} />
          <Route path='/shop/checkout' element={<CheckoutComplete />} />
          <Route path='/product/:accountId/:productId' element={<ProductDetails />} />
          <Route path='/ravelry' element={<Ravelry />} />
          <Route path='/references' element={<References />} />
          <Route path='/reference/:pk/:id' element={<ReferenceDetails />} />

          {/* DEFAULT */}
          <Route path='*' element={<HomePage />} />
        </Routes>
      </Layout>
    ) : (
      location.pathname.startsWith("/login") ?
        <LoginPage /> :
        <WelcomePage />
    )

}
