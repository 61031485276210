import { useNavigate } from "react-router-dom";
import { Button } from "@blueprintjs/core";
import { Card, Icon, Spinner } from "@blueprintjs/core";
import { useGetItemsQuery } from '../store/docApi';
import { getCraftIcon } from "../utils";
import Craft from "../Widgets/Craft";
import Error from "../Widgets/Error";
import { appstyles } from "../styles/appstyles";

export default function Patterns() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetItemsQuery({ container: 'design', label: 'pattern'})

  let items = [];
  if (data) {
    items = [...data]
    items.sort((p1, p2) => { return p2._ts - p1._ts });
  }

  if (error) {
    console.log(error)
  }
  return <div style={appstyles.content}>
    <div style={appstyles.titleWithButtons}>
      <h2>Patterns</h2>
      <Button text="New Pattern" onClick={() => navigate('/pattern/create')} />
    </div>
  {error ? <Error /> :
    isLoading ? (<Spinner style={appstyles.spinner} />) :
      <div style={appstyles.tileGrid}>
        {items.map((pattern, i) => {
          let pic = pattern.picture ? pattern.picture : pattern.pictures && pattern.pictures.length > 0 ? pattern.pictures[0] : null;
          return (
          <Card key={i} style={appstyles.tile} interactive
            onClick={()=>navigate(`/pattern/${pattern.pk}/${pattern.id}`)}>
            {pic ? <img style={appstyles.tileImg} src={pic} alt={pattern.name} /> :
            <div style={appstyles.tileNoimg}><Icon icon={getCraftIcon(pattern.craft)} size={80} /></div>}
            <div style={appstyles.tileTitle}>
                <Craft craft={pattern.craft} />
                {pattern.name}
            </div>
            {<span style={appstyles.tileLink}>{pattern.pk}</span>}
          </Card>
          )}
        )}
      </div>
  }</div>
}
