import React, { useState } from 'react';
import { Button, Card, Elevation, Dialog, Classes, FormGroup, InputGroup } from '@blueprintjs/core';
import Colorway from './Colorway';

const ColorwayList = ({ colorways, setColorways, selectColorway }) => {
    const [selectedColorway, setSelectedColorway] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newColorway, setNewColorway] = useState({ id: '', name: '', chromas: [] });

    const handleSelectColorway = (colorway) => {
        setSelectedColorway(colorway);
        selectColorway(colorway);
    };

    const handleAddColorway = () => {
        let updatedColorways = colorways ? [...colorways] : [];
        updatedColorways.push({ ...newColorway, id: Date.now() });
        setColorways(updatedColorways);
        setNewColorway({ id: '', name: '', chromas: [] });
        setIsDialogOpen(false);
    };

    const saveSelectedColorway = (colorway) => {
        const updatedColorways = colorways.map((cw) => cw.id === colorway.id ? colorway : cw);
        setColorways(updatedColorways);
        setSelectedColorway(null);
    };

    return (
        <div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px' }}>
                {colorways && colorways.map((colorway, index) => (
                    <Card key={index} interactive={true} style={{ padding: '10px' }}
                    elevation={Elevation.ONE} onClick={() => handleSelectColorway(colorway)}>
                        <b>{colorway.name}</b>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(20px, 1fr))', gap: '2px', marginTop: '10px' }}>
                            {colorway.chromas && colorway.chromas.map((color, index) => (
                                <div key={index} style={{ width: 20, height: 20, backgroundColor: color.color }}></div>
                            ))}
                        </div>
                    </Card>
                ))}
                <Card>
                    <Button icon="add" text="Add Colorway" onClick={() => setIsDialogOpen(true)} style={{ marginTop: '20px' }} />
                </Card>
            </div>
            <Dialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                title="Add New Colorway"
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup label="Name">
                        <InputGroup value={newColorway.name} onChange={(e) => setNewColorway({ ...newColorway, name: e.target.value })} />
                    </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button text="Cancel" onClick={() => setIsDialogOpen(false)} />
                        <Button intent="primary" text="Add" onClick={handleAddColorway} />
                    </div>
                </div>
            </Dialog>
            {selectedColorway && (
                <Card style={{ marginTop: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
                        <Colorway initialColorway={selectedColorway} onSave={saveSelectedColorway} />
                        <Button icon="cross" onClick={() => setSelectedColorway(null)} />
                    </div>
                </Card>
            )}
        </div>
    );
};

export default ColorwayList;