import { useState } from "react";
import { Alert, Button } from "@blueprintjs/core";
import { useUpdatePropertyMutation } from "../store/docApi";
import { useDeleteImageMutation } from '../store/mediaApi';
import FileUpload from "../Widgets/FileUpload";

export default function PictureManager({ item }) {
    const imgUrl = "https://m.stitchso.com/img/";
    const [updateProperty] = useUpdatePropertyMutation();
    const [deleteImage] = useDeleteImageMutation();
    const [showAddPicture, setShowAddPicture] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deleteUrl, setDeleteUrl] = useState("");

    function addPicture() {
        setShowAddPicture(true);
    }

    function deletePictureConfirm(url) {
        setDeleteUrl(url);
        setDeleteConfirmOpen(true);
    }

    function deletePicture() {
        setDeleteConfirmOpen(false);
        if (deleteUrl.startsWith(imgUrl)) {
            deleteImage({ name: deleteUrl.substr(imgUrl.length) })
        }
        let newpictures = item.pictures.filter((el) => { return el !== deleteUrl });
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "pictures", value: newpictures })
    }

    function setPicture(picture) {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "picture", value: picture })
    }

    function getPictureName(picture) {
        let tok = picture.split("/");
        return tok[tok.length - 1];

    }

    return (
        <div>
            {item.pictures && <div style={styles.pictures}>
                {item.pictures.map((picture, i) => (
                    <span key={i}>
                        <img style={styles.picture} src={picture} alt={picture} />
                        <br clear="all" />
                        <div style={styles.controls}>
                            <span>{false && getPictureName(picture)}</span>
                            <span>
                                <Button icon="confirm" minimal intent={picture === item.picture ? 'primary' : 'none'}
                                    onClick={() => setPicture(picture)} />
                                <Button icon="trash" minimal onClick={() => deletePictureConfirm(picture)} />
                            </span>
                        </div>
                    </span>

                ))}
            </div>}
            <Button outlined icon="add" onClick={() => addPicture()} >Add picture</Button>
            {showAddPicture && <div>
                <br />
                <FileUpload folder={item.pk} item={item} />
            </div>}
            <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
                isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
                onConfirm={() => deletePicture()}>
                <p>Delete this picture?</p>
            </Alert>

        </div>
    );
}

const styles = {
    pictures: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    picture: {
        maxHeight: 200,
        paddingRight: 10
    },
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 5
    }
};