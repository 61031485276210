import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from '@blueprintjs/core';
import { useGetItemsQuery } from '../store/docApi'
import MotifCard from "./MotifCard";
import { appstyles } from '../styles/appstyles';

export default function Motifs() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetItemsQuery({ label: 'motif' });

  document.title = "Motifs";

  let items = [];
  if (data) {
    items = [...data]
    items.sort((p1, p2) => { return p2._ts - p1._ts });
  }

  if (error) {
    console.log(error)
  }

  return <div style={appstyles.content}>
    <div style={appstyles.titleWithButtons}>
      <h2>Motifs</h2>
      <Button text="New Motif" onClick={() => navigate('/content/create/motif')} />
    </div>
    {error ? <span>Error {error.status}</span> :
    isLoading ? (<Spinner style={appstyles.spinner} />) :
    <div>
      <div style={styles.list}>
        {items.map((motif, i) => (
          <MotifCard motif={motif} key={i} />
        ))}
      </div>
    </div>}
  </div>
}

const styles = {
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 16
  }
}
