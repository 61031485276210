import Palette from '../components/Palette';
import Sizing from '../Widgets/Sizing';
import Instructions from './Instructions';

export default function PatternDetails({ item }) {

  return (<div>
    <p><b>Pattern</b></p>
    {item.sizing && <Sizing sizing={item.sizing} />}
    {item.yarns && <Palette yarns={item.yarns} />}
    {item.steps && <Instructions item={item} />}
  </div>
  );
}