import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Alert, Button, Spinner, Menu, MenuItem, Popover, Tabs } from '@blueprintjs/core';
import { useGetItemQuery, useDeleteItemMutation } from '../store/docApi';
import ItemMetadata from '../Widgets/ItemMetadata';
import { appstyles } from '../styles/appstyles';
import Layouts from './Layouts';
import Motifs from './Motifs';
import PatternDetails from './PatternDetails';
import PublishItem from '../Widgets/PublishItem';
import Colorways from './Colorways';
import DesignDetails from './DesignDetails';

export default function Design() {
  let params = useParams();
  const navigate = useNavigate();
  const { data: item, error, isLoading } = useGetItemQuery({ label: "design", pk: params.pk, id: params.id });
  const [deleteItem] = useDeleteItemMutation();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(params.tab ? params.tab : "details");
  const [isShowContent, setIsShowContent] = useState(true);

  function deleteDesign() {
    setDeleteConfirmOpen(false);
    deleteItem({ label: "design", pk: item.pk, id: item.id });
    navigate("/designs");
  }

  const actionMenu = (
    <Menu>
      <MenuItem minimal icon='trash' onClick={() => setDeleteConfirmOpen(true)} text="Delete design" />
    </Menu>
  );

  function onSelectTab(tabId) {
    setSelectedTab(tabId);
    navigate(`/design/${item.pk}/${item.id}/${tabId}`);
  }

  return error ? <span>Error {error.status}</span> :
    isLoading ? (<Spinner style={appstyles.spinner}>Loading...</Spinner>) :
      <div>
        <div style={appstyles.titleWithButtons}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
          <Button minimal icon={isShowContent ? "properties" : "properties"} onClick={() => setIsShowContent(!isShowContent)} />
          <h3>{item.name}</h3>
          </div>
          <div style={appstyles.tabButtonGroup}>
            <Popover content={actionMenu} position="bottom">
              <Button minimal icon="more" />
            </Popover>
            <PublishItem item={item} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            {isShowContent && (
              <Tabs animate size="small" vertical id="TabsExample" onChange={(tabId) => onSelectTab(tabId)} selectedTabId={selectedTab}>
                <Tabs.Tab id="details" title="Details" />
                <Tabs.Tab id="pattern" title="Pattern" />
                <Tabs.Tab id="motifs" title="Motifs" tagContent={item.motifs && item.motifs.length > 0 ? item.motifs.length : undefined} />
                <Tabs.Tab id="layouts" title="Layouts" tagContent={item.layouts && item.layouts.length > 0 ? item.layouts.length : undefined} />
                <Tabs.Tab id="colorways" title="Colorways" tagContent={item.colorways && item.colorways.length > 0 ? item.colorways.length : undefined} />
              </Tabs>
            )}
          </div>
          <div style={{ flexGrow: 1, marginLeft: isShowContent ? 16 : 0 }}>
            {selectedTab === "details" && <DesignDetails item={item} />}
            {selectedTab === "pattern" && <PatternDetails item={item} />}
            {selectedTab === "motifs" && <Motifs item={item} />}
            {selectedTab === "layouts" && <Layouts item={item} />}
            {selectedTab === "colorways" && <Colorways item={item} />}
          </div>
        </div>
        <br />
        <ItemMetadata item={item} />
        <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
          isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
          onConfirm={deleteDesign}>
          <p>Delete this design?</p>
        </Alert>
      </div>
}
