import { useEffect, useState } from "react";
import { auth } from '../firebaseConfig';
import { Button, ButtonGroup, Callout, FormGroup, NumericInput, TagInput } from "@blueprintjs/core";
import { useUpdatePropertyMutation } from '../store/docApi';
import { renderShapeSvg } from './shapesSvg';
import ColorwayLegend from '../Design/ColorwayLegend'

export default function ColorChart({ palette, thumbnail, item, depth, viewonly, progress, updateProgress, colorway }) {
  const [rows, setRows] = useState(item?.data?.length || 0);
  const [cols, setCols] = useState(item?.data?.[0]?.length || 0);
  const [data, setData] = useState(item?.data || []);
  const [edit, setEdit] = useState(false);
  const defaultColors = ['white', 'grey', 'lightpink', 'magenta'];
  const [colors, setColors] = useState(item?.colors || defaultColors);
  const defaultShapes = ['circle', 'square', 'triangle', 'star', 'diamond', 'x'];
  const [shapes, setShapes] = useState(item?.shapes || defaultShapes);
  const [color, setColor] = useState(1);

  const [mouseDown, setMouseDown] = useState(false);

  const [updateProperty, result] = useUpdatePropertyMutation()

  useEffect(() => {
    if (colorway && colorway.chromas) {
      const colors = colorway.chromas.map(chroma => chroma.color);
      if (colors.length > 0) {
        setColors(colors);
      }
      const shapes = colorway.chromas.map(chroma => chroma.shape);
      if (shapes.length > 0) {
        setShapes(shapes);
      }
    }
  }, [colorway]);

  const onSaveData = async () => {
    await updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "data", value: data })
    setEdit(false)
  }

  const onSaveColors = async () => {
    await updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "colors", value: colors })
    setEdit(false)
  }

  const onSaveShapes = async () => {
    await updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "shapes", value: shapes })
    setEdit(false)
  }

  async function setCurrentRow(num) {
    if (num >= item.data.length) num = -1;
    await updateProgress(`${depth}_row`, num);
  }

  function getCurrentRow() {
    return progress && progress[`${depth}_row`] !== undefined ? progress[`${depth}_row`] : -1;
  }

  function getCellColor(col) {
    if (palette && palette.shades && palette.shades[col]) {
      return palette.shades[col].color;
    }
    return colors[col] || "#f3f3f3"; // Default color if colors[col] is undefined
  }

  function isSolid(col) {
    return colorway && colorway.chromas && colorway.chromas.length > col && colorway.chromas[col].isSolid;
  }

  function setValue(i, j, val) {
    let newData = JSON.parse(JSON.stringify(data));
    newData[i][j] = val;
    setData(newData);
  }

  function updateGrid() {
    const newData = new Array(rows).fill(0).map(() => new Array(cols).fill(0));
    for (var i = 0; i < newData.length && i < data.length; i++)
      for (var j = 0; j < newData[0].length && j < data[0].length; j++) {
        newData[i][j] = data[i][j];
      }
    setData(newData);
  }

  function onMouseDown(i, j) {
    setValue(i, j, color);
    setMouseDown(true);
  }

  function copyData(data) {
    const formattedData = "[" + data.map(row => "[" + row.join(", ") + "]").join(",\n") + "]";
    navigator.clipboard.writeText(formattedData).then(() => {
      console.log('Data copied to clipboard', formattedData);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }

  let split = item && item.metadata && item.metadata.split ? item.metadata.split : 0;

  return data ? (
    <div>
      {!thumbnail && !viewonly && auth.currentUser &&
        <div style={{ padding: 5 }}>
          <div>{cols} x {rows}</div>
          <Button icon='edit' disabled={edit} onClick={() => setEdit(true)}>Edit</Button>
          &nbsp;&nbsp;
          <Button icon='duplicate' disabled={edit} onClick={() => copyData(item.data)}>Copy</Button>
        </div>}
      {result && result.isError && <Callout intent="danger">Error saving</Callout>}
      {edit && <div>
        <div style={styles.controls}>
          <FormGroup inline label="Cols:">
            <NumericInput value={cols} min={1} max={100} onValueChange={(valueAsNumber) => setCols(valueAsNumber)} style={{ width: 50 }} />
          </FormGroup>
          <FormGroup inline label="Rows:" style={styles.control}>
            <NumericInput value={rows} min={1} max={100} onValueChange={(valueAsNumber) => setRows(valueAsNumber)} style={{ width: 50 }} />
          </FormGroup>
          <FormGroup style={styles.control}><Button onClick={() => updateGrid()}>Update</Button></FormGroup>
        </div>
        <div style={styles.controls}>
          <FormGroup inline label="Colors:">
            <TagInput values={colors} onChange={(values) => setColors(values)} />
          </FormGroup>
          <FormGroup style={styles.control}><Button disabled={!edit} onClick={() => onSaveColors()}>Save</Button></FormGroup>
        </div>
        <div style={styles.controls}>
          <FormGroup inline label="Shapes:">
            <TagInput values={shapes} onChange={(values) => setShapes(values)} />
          </FormGroup>
          <FormGroup style={styles.control}><Button disabled={!edit} onClick={() => onSaveShapes()}>Save</Button></FormGroup>
        </div>
        <ButtonGroup style={styles.controls}>
          {colors.map((color, i) => {
            return <Button key={i} outlined={color === i} onClick={() => setColor(i)} style={{ ...styles.control, background: colors[i] }}>{i}</Button>
          })}
          <Button style={styles.control} onClick={() => onSaveData()}>Save</Button>
        </ButtonGroup>
      </div>}
      <br />
      <div style={styles.chartContainer}>
        {!thumbnail && !edit && cols && rows && split > 0 &&
          <div style={styles.horizontalDecor}>
            {Array(split).fill({}).map((s, i) => {
              return <div key={i} style={{ ...styles.section, height: rows * 16, width: cols / split * 16 }} />
            })}
          </div>}
        {!thumbnail && !edit && cols && rows &&
          <div style={styles.verticalDecor}>
            {data.map((step, i) => {
              return (getCurrentRow() === data.length - i - 1) ?
                <div key={i} style={{ ...styles.rowHighlight, cursor: 'pointer', height: 16, width: cols * 16 }}
                  onClick={() => setCurrentRow(-i)} /> :
                <div key={i} style={{ cursor: 'pointer', height: 16, width: cols * 16 }}
                  onClick={() => setCurrentRow(data.length - i - 1)} />
            })}
          </div>}
        <div style={styles.horizontal}>
          <div style={styles.chart}>
          {/* Stitch numbers at the top of the chart */}
          {!thumbnail && <div style={styles.step}>
              {data[0] ? data[0].map((col, j) => {
                return (
                  <span key={j} style={styles.cellnum}>{data[0].length - j}</span>
                )
              }) : <div />}
            </div>}
            {data.map((step, i) => {
              return (
                <div key={i} style={styles.step}>
                  {step.map((col, j) => {
                    const cellColor = getCellColor(col);
                    const shape = shapes[col % shapes.length];
                    return thumbnail ? (
                      <div key={j} style={{ ...styles.cellsmall, backgroundColor: cellColor }} />
                    ) : (
                      <div key={j} style={{ ...styles.cell, backgroundColor: cellColor }}
                        onMouseDown={() => onMouseDown(i, j)}
                        onMouseUp={() => setMouseDown(false)}
                        onMouseEnter={() => (mouseDown && setValue(i, j, color))}
                        onClick={() => setValue(i, j, color)}
                      >
                      <svg width="10" height="10" viewBox="0 0 10 10" style={{ display: 'block', margin: 'auto', transform: 'translate(0, 2px)' }}>
                       
                        {renderShapeSvg(shape, cellColor, isSolid(col))}
                      </svg>
                      </div>
                    );
                  })}
                  {!thumbnail && <span style={styles.cellnum}>{data.length - i}</span>}
                </div>
              )
            })}
            {!thumbnail && <div style={styles.step}>
              {data[0] ? data[0].map((col, j) => {
                return (
                  <span key={j} style={styles.cellnum}>{data[0].length - j}</span>
                )
              }) : <div />}
            </div>}
          </div>
          {colorway && <div style={{ paddingLeft: 32 }}> <ColorwayLegend colorway={colorway} /></div>}
        </div>
      </div>
      {viewonly && <Button style={styles.nextButton} icon="arrow-up" onClick={() => setCurrentRow(getCurrentRow() + 1)}>Next Row</Button>}
    </div>
  ) : <span>no data?</span>
}

const styles = {
  nextButton: {
    margin: 8
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflowX: 'auto',
  },
  horizontalDecor: {
    position: 'absolute',
    display: 'flex',
    direction: 'row'
  },
  section: {
    borderColor: 'darkslategrey',
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0
  },
  rowHighlight: {
    borderColor: 'gold',
    borderStyle: 'solid',
    borderWidth: 2
  },
  verticalDecor: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column'
  },
  chart: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
  },
  step: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start'
  },
  cellsmall: {
    height: 4,
    width: 4,
    borderWidth: 0,
    borderColor: 'black',
    borderStyle: 'solid'
  },
  cell: {
    height: 16,
    width: 16,
    borderWidth: 1,
    borderColor: 'rgba(0,0,0, 0.3)',
    borderStyle: 'solid',
    position: 'relative'
  },
  cellnum: {
    height: 16,
    width: 16,
    fontSize: 10,
    textAlign: 'center'
  },
  styleRS: {
    flexDirection: 'row-reverse',
    alignSelf: 'flex-end'
  },
  styleWS: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  chartsquareblank: {
    width: 18,
    height: 18,
    fontSize: 12,
    textAlign: 'center',
    color: 'blueviolet'
  },
  chartsquaretext: {
    position: 'absolute',
    color: 'red',
    fontSize: 10
  },
  charticon: {
    width: '100%',
    height: '100%'
  },
  controls: {
    display: 'flex',
    flexDirection: 'row'
  },
  control: {
    marginLeft: 10
  }
}

