import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Colors, InputGroup, Spinner } from '@blueprintjs/core';
import { useGetItemQuery, useImportPartMutation, useDeleteImportPartMutation } from '../store/docApi';
import ColorChartThumbnail from '../Charts/ColorChartThumbnail';
import Instructions from './Instructions';
import Overview from './Overview';
import ItemMetadata from '../Widgets/ItemMetadata';
import PublishItem from '../Widgets/PublishItem';
import { appstyles } from '../styles/appstyles';

export default function Pattern() {
  let params = useParams();
  const patternPk = params.pk;
  const patternId = params.id;

  const projectId = params.projectId;
  const stepId = params.stepId;

  const { data: item, isLoading } = useGetItemQuery({ container: 'design', label: 'pattern', pk: params.pk, id: params.id });
  const [importPart] = useImportPartMutation();
  const [deleteImportPart] = useDeleteImportPartMutation();

  const [selectedTab, setSelectedTab] = useState(params.projectId ? "instructions" : "overview");
  const [currentLang, setCurrentLang] = useState('en');
  const [partPk, setPartPk] = useState("");
  const [partId, setPartId] = useState("");
  const navigate = useNavigate();

  function tabStyle(selected) {
    return selected ? {
      borderColor: Colors.TURQUOISE3,
      borderWidth: "0 0 4px 0", borderStyle: 'solid',
    } : {}
  }

  function edit() {
    navigate(`/item/pattern/${item.pk}/${item.id}`)
  }

  async function onImportPart() {
    await importPart({ pk: patternPk, id: patternId, sourcepk: partPk, sourceid: partId });
  }

  async function onDeleteImportPart(_pk, _id) {
    await deleteImportPart({ pk: patternPk, id: patternId, sourcepk: _pk, sourceid: _id });
  }

  return isLoading ? <Spinner style={appstyles.spinner} /> :
    item && <div style={appstyles.content}>
      <div>
        <div>
          <h2>{item.name}</h2>
          {item.author && <p>by <Link to={`/@${item.author}`}>@{item.author}</Link> </p>}
        </div>
        <div>
          {item.lang && item.lang.map((lang, i) =>
            <Button small key={i} style={tabStyle(lang === currentLang)}
              onClick={() => setCurrentLang(lang)}>{lang}</Button>
          )}
        </div>
      </div>
      <div style={appstyles.tabContainer}>
        <div style={appstyles.tabButtonGroup}>
          <Button minimal style={selectedTab === "overview" ? appstyles.activeTabButton : appstyles.tabButton}
            onClick={() => setSelectedTab("overview")}>Overview</Button>
          <Button minimal style={selectedTab === "instructions" ? appstyles.activeTabButton : appstyles.tabButton}
            onClick={() => setSelectedTab("instructions")}>Instructions</Button>
          <Button minimal style={selectedTab === "parts" ? appstyles.activeTabButton : appstyles.tabButton}
            onClick={() => setSelectedTab("parts")}>Parts</Button>
        </div>
        <div style={appstyles.tabButtonGroup}>
          <Button minimal icon="edit" onClick={() => edit()} disabled />
          <PublishItem item={item} />
        </div>
      </div>
      <br />
      {selectedTab === "overview" && <Overview patternId={patternId} patternPk={patternPk} lang={currentLang} />}
      {selectedTab === "instructions" && <Instructions patternId={patternId} patternPk={patternPk} projectId={projectId} stepId={stepId} lang={currentLang} parts={item.parts} />}
      {selectedTab === "parts" &&
        <div>
          {item.parts && item.parts.map((part, i) => {
            return <div key={i}>
              {part.label} <Link to={`/part/${part._pk}/${part._id}`}>{part.name}</Link> ({part.id}) ({part._pk}/{part._id})
              <Button minimal icon="trash" onClick={() => onDeleteImportPart(part._pk, part._id)} />
              <br/>
              {part.colorchart && part.data && <ColorChartThumbnail data={part.data} colors={part.colors} mincols={24} />}
            </div>
          })}
          <br/>
          <div style={{ display: "flex" }}>
            <InputGroup placeholder="Part PK" value={partPk} onChange={(e) => setPartPk(e.target.value)} />
            &nbsp;
            <InputGroup placeholder="Part ID" value={partId} onChange={(e) => setPartId(e.target.value)} />
            &nbsp;
            <Button text="Import Part" onClick={() => onImportPart()} />
          </div>
        </div>}
      <ItemMetadata item={item} />
    </div>
}
