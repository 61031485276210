import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { Button, Callout, FormGroup, InputGroup } from '@blueprintjs/core';
import { useCreateItemMutation } from '../store/docApi';
import { generateNumericId } from '../utils';
import { appstyles } from '../styles/appstyles';

export default function ContentCreate() {
  const [createItem, result] = useCreateItemMutation();
  const params = useParams();

  const navigate = useNavigate();

  const [id, setId] = useState(generateNumericId(8));
  const [name, setName] = useState('');
  const labels = ["design", "project"];
  const labelTitles = { design: "Design", project: "Project" };
  const label = params.label && labels.includes(params.label) ? params.label : labels[0];
  const [description, setDescription] = useState('');


  async function handleSubmit(event) {
    event.preventDefault();
    var newDesign = {
      label: label,
      id: id,
      name: name
    };
    if (description.trim().length !== 0)
      newDesign.description = description;
    let response = await createItem({ label: label, data: newDesign }).unwrap();
    navigate(`/${response.label}/${response.pk}/${response.id}`);
  }

  function handleCancel() {
      navigate("/designs");
  }

  return (
    <div style={appstyles.content}>
      {result && result.isError && <Callout intent="danger">Error saving</Callout>}
      {result && result.isSuccess && <Callout intent="success">Saved successfully!</Callout>}
      <form onSubmit={handleSubmit}>
        <h3>New {labelTitles[label]}</h3>
        <FormGroup label="Id">
            <InputGroup name="id" value={id} onChange={(e)=>setId(e.target.value)} />
        </FormGroup>
        <FormGroup label="Name">
            <InputGroup name="name" value={name} onChange={(e)=>setName(e.target.value)} />
        </FormGroup>
        <FormGroup label="Description">
            <InputGroup name="description" value={description} onChange={(e)=>setDescription(e.target.value)} />
        </FormGroup>
        <Button type="submit" intent="primary">Submit</Button>
        &nbsp;&nbsp;&nbsp;
        <Button onClick={handleCancel}>Cancel</Button>
      </form>
    </div>
  );
}
