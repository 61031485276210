import { useEffect, useState } from 'react';
import { Button, Tag } from '@blueprintjs/core';
import { useUpdatePropertyMutation } from '../store/docApi';
import Notes from '../Widgets/Notes';
import { getReferencesHtml, NoteReferences } from './NoteReferences';
import Markdown from '../Widgets/Markdown';
import TiptapEditor, { markdownToHtml } from './TipTapEditor';

const styles = {
    textarea: {
        width: '100%',
        padding: 10,
        border: '1px solid rgb(191, 193, 199)',
        minHeight: '80px',
        marginBottom: 10,
    },
    nestedContent: {
        paddingLeft: 8
    },
};

const QStep = ({ step, depth = 0, onAnswerChange, answers, reference, updateReferences }) => {
    const [answer, setAnswer] = useState((answers[step.id] && answers[step.id].note) || '');
    const [isEditing, setIsEditing] = useState(false);
    return !step.noquestions && <div>
        {step.title && <h2>{step.title}</h2>}
        {!step.question &&  step.name && <p><b>{step.name}</b></p>}
        {step.tags && step.tags.map((tag, i) => (
            <Tag key={i} minimal intent='success' style={{ marginRight: 5, marginBottom: 10 }}>{tag}</Tag>
        ))}
        {step.notes && <div>
            <Notes style={{ marginBottom: 10 }} notes={step.notes} />
            <br />
        </div>}
        {step.question && <>
            {!step.verbal && <p><b>{step.name}</b> {step.question}</p>}
            {!isEditing && <div style={styles.textarea}>
                <Markdown text={answer} />
            </div>}
            {isEditing && <TiptapEditor content={answer} setContent={setAnswer} />}
            <div>
                {isEditing && <Button small intent='primary' onClick={() => { setIsEditing(false); onAnswerChange(step.id, answer) }} text="Save" />}
                &nbsp;&nbsp;
                {step.question && <Button small onClick={() => setIsEditing(!isEditing)} text={isEditing ? 'Cancel' : 'Edit'} style={{ marginRight: '1rem' }} />}
            </div>
            <NoteReferences references={(answers[step.id] && answers[step.id].references) || []} referencesId={step.id}
                reference={reference} updateReferences={updateReferences} />
        </>}
        {step.endnote && <p><i>{step.endnote}</i></p>}
        {step.steps && step.steps.length > 0 && (
            <div style={styles.nestedContent}>
                {step.steps.map((subStep, i) => (
                    <QStep key={i} step={subStep} depth={depth + 1} onAnswerChange={onAnswerChange} answers={answers} reference={reference} updateReferences={updateReferences} />
                ))}
            </div>
        )}
    </div>
};

const Questions = ({ item, connectionIndex, reference }) => {
    const [answers, setAnswers] = useState({});
    const [pattern, setPattern] = useState(null);
    const [updateProperty] = useUpdatePropertyMutation();

    useEffect(() => {
        if (item) {
            if (item.connections && item.connections.length > connectionIndex) {
                let connection = item.connections[connectionIndex];
                setAnswers(connection.answers ? JSON.parse(JSON.stringify(connection.answers)) : {});
                setPattern(connection.pattern);
            }
        }
    }, [item, connectionIndex]);

    async function handleAnswerChange(id, value) {
        let newanswers = { ...answers };
        if (!newanswers[id]) { newanswers[id] = {}; }
        newanswers[id].note = value;
        setAnswers(newanswers);
        let updateParams = {
            label: "project", pk: item.pk, id: item.id,
            name: `connections/${connectionIndex}/answers/${id}`,
            value: newanswers[id]
        };
        await updateProperty(updateParams);
    }

    async function updateReferences({ id, newrefs }) {
        let updateParams = {
            label: "project", pk: item.pk, id: item.id,
            name: `connections/${connectionIndex}/answers/${id}/references`,
            value: newrefs
        };
        await updateProperty(updateParams);
    }

    const handleSubmit = () => {
        console.log('Questions answers:', answers);
        // Here you can send the answers to your backend or handle them as needed
    };

    const printStep = (step, answers) => {
        if (step.noquestions) return '';
        let content = '';
        let answer = (answers[step.id] && answers[step.id].note) ? markdownToHtml(answers[step.id].note) : null;
        if (step.title) content += `<h2>${step.title}</h2>`;
        if (step.name && !step.verbal) content += `<b>${step.name}</b><br/>`;
        if (answer) content += `${answer}</p>`;
        if (answers[step.id] && answers[step.id].references && answers[step.id].references.length > 0) {
            content += `<b>References</b><br/>`;
            content += getReferencesHtml(answers[step.id].references, reference);
        }
        if (step.steps) {
            for (let subStep of step.steps) {
                content += printStep(subStep, answers);
            }
        }
        return content;
    }

    const handlePrint = () => {
        if (!pattern) return;
        const printWindow = window.open("", "_blank");
        let content = `<html><head><title>${pattern.name ? pattern.name : 'Print'}</title>
        <style>
          @page {
            margin: 0
          }
          body {
            font-family: "Times New Roman", Times, serif;
            font-size: 12pt;
            margin: 1in;
          }
        </style>  
        </head>
        <body>`;
        if (pattern.name) content += `<h1>${pattern.name}</h1>`;
        if (pattern.steps) {
            for (let step of pattern.steps) {
                content += printStep(step, answers);
            }
        }

        content += `
        </body>
        </html>`;

        printWindow.document.write(content);
        printWindow.document.close();
        printWindow.print();
    };

    return <div style={styles.container}>
        <Button onClick={handlePrint} text="Print Answers" />
        {pattern && pattern.name && <h1>{pattern.name}</h1>}
        {pattern && pattern.description && <p>{pattern.description}</p>}
        {pattern && pattern.steps && pattern.steps.map((step, i) => (
            <QStep key={i} step={step} onAnswerChange={handleAnswerChange} answers={answers} reference={reference} updateReferences={updateReferences} />
        ))}

        <Button onClick={handleSubmit} text="Save" />
    </div>
};

export default Questions;