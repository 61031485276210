import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useGetItemQuery } from "../store/docApi";
import { useUpdatePropertyMutation, useDeletePropertyMutation } from "../store/docApi";
import Picture from "../Widgets/Picture";
import Steps from "../components/Steps";
import KnitChart from "../Charts/KnitChart";
import ColorChart from "../Charts/ColorChart";
import Links from "../Widgets/Links";
import ItemMetadata from "../Widgets/ItemMetadata";
import ColorChartCanvas from "../Charts/ColorChartCanvas";
import ColorChartThumbnail from "../Charts/ColorChartThumbnail";
import { appstyles } from "../styles/appstyles";
import EditablePropery from "./EditableProperty";

export default function MotifDetails() {
    let params = useParams();
    const index = params.index;
    const [motif, setMotif] = useState(null);
    const { data: item } = useGetItemQuery({ label: 'design', pk: params.pk, id: params.id });
    const [updateProperty] = useUpdatePropertyMutation();
    const [deleteProperty] = useDeletePropertyMutation();
    const navigate = useNavigate();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [colors, setColors] = useState(["#ffffff", "#d3d3d3", "#808080", "#3d3d3d"]);
    const [view, setView] = useState('chart');
    const [selectedColorway, setSelectedColorway] = useState(null);

    useEffect(() => {
        if (item && item.motifs && index < item.motifs.length) {
            setMotif(item.motifs[index]);
            if (item.motifs[index].colors) setColors(item.motifs[index].colors)
        }
    }, [item, index, setColors]);

    function deleteConfirm() {
        setDeleteConfirmOpen(true);
    }

    function deleteMotif() {
        setDeleteConfirmOpen(false);
        deleteProperty({ pk: item.pk, id: item.id, name: `motifs/${index}` });
        navigate(`/design/${item.pk}/${item.id}`);
    }

    function onSaveProperty(name, value) {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: `motifs/${index}/${name}`, value: value });
    }

    const selectColorway = (index) => {
        if (item.colorways && index < item.colorways.length) {
            const colorway = item.colorways[index];
            if (colorway.chromas && colorway.chromas.length > 0) {
                const colors = colorway.chromas.map(chroma => chroma.color);
                if (colors.length > 0) {
                    setColors(colors);
                }
            }
            setSelectedColorway(colorway);
        }
    }

    return item && motif &&
        <div style={appstyles.content}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                {motif.name && <h2>{motif.name}</h2>}
                <span style={appstyles.tabButtonGroup}>
                    <Button icon='trash' onClick={() => deleteConfirm()} />
                </span>
            </div>
            <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
                Design:
                <Link to={`/design/${item.pk}/${item.id}`}>{item.name}</Link>
            </div>
            <br />
            <EditablePropery item={item} title="Name" propname={`motifs/${index}/name`} propvalue={motif.name} placeholder="Enter motif name..." />
            <EditablePropery item={item} title="Description" propname={`motifs/${index}/description`} propvalue={motif.description} placeholder="Enter motif description..." />
            <EditablePropery item={item} title="Keywords" propname={`motifs/${index}/keywords`} propvalue={motif.keywords} placeholder="Enter motif keywords..." />
            {motif.author && <div>Designed by {motif.author} </div>}
            {motif.cols > 1 && <div>Multiple of {motif.cols} sts {motif.extracols && <span>plus {motif.extracols}</span>}</div>}
            <Picture picture={motif.picture} pictures={motif.pictures} />
            <b>Colorways</b>
            <div style={{ display: 'flex', gap: 8, marginTop: 8 }}>
                {item.colorways && item.colorways.map((colorway, index) => (
                    <Card key={index} interactive={true} style={{ padding: '10px', width: 100 }} onClick={() => selectColorway(index)} >
                        <span>{colorway.name}</span>
                        <div style={{ display: 'flex' }}>
                            {colorway.chromas && colorway.chromas.map((color, index) => (
                                <div key={index} style={{ width: 10, height: 10, backgroundColor: color.color }}></div>
                            ))}
                        </div>
                    </Card>
                ))}
            </div>
            <br />
            {motif.colorchart && <ColorChartThumbnail data={motif.data} colors={colors} mincols={96} norepeat={motif.norepeat} />}
            <br /><br />
            <div style={{ display: 'flex', gap: 8 }}>
                <Button onClick={() => setView('chart')} intent={view === "chart" ? "primary" : "none"}>Chart</Button>
                <Button onClick={() => setView('canvas')} intent={view === "canvas" ? "primary" : "none"}>Canvas</Button>
            </div>
            {view === "chart" && <ColorChart item={motif} chartid={motif.id} colorway={selectedColorway} />}
            {motif.knitchart && motif.steps && <div><br /><KnitChart steps={motif.steps} item={item} chartid={motif.id} /></div>}
            {motif.steps && <Steps steps={motif.steps} showVerbal={true} expandAll={true} />}
            <Links links={motif.links} />
            {motif.sources && motif.sources.map((source, i) => {
                return <div key={i}>{source.id} {source.chapter} {source.page}</div>
            })}
            {view === "canvas" && <ColorChartCanvas colors={colors} data={motif.data} onSaveProperty={onSaveProperty} colorway={selectedColorway} />}
            <ItemMetadata item={item} />
            <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
                isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
                onConfirm={() => deleteMotif()}>
                <p>Delete this motif from {item.name} design?</p>
            </Alert>
        </div>
}
