import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from '../firebaseConfig';

const baseUrl = "https://a.stitchso.com/d";
//const baseUrl = "https://localhost:6001/d";

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: async (headers, { getState }) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const user = auth.currentUser;
    if (user) {
      await user.getIdToken(true); // force a refresh of the token
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const docApi = createApi({
  reducerPath: 'docApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['item', 'items', 'progress'],
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => {
        return {
          url: `user`
        }
      }
    }),
    getInstagram: builder.query({
      query: () => {
        return {
          url: `insta`
        }
      }
    }),
    getProfiles: builder.query({
      query: () => {
        return {
          url: `profile`
        }
      },
      providesTags: ['items']
    }),
    getProfile: builder.query({
      query: (arg) => {
        const { pk } = arg;
        return {
          url: `profile/${pk}`
        };
      },
      providesTags: []
    }),
    getContent: builder.query({
      query: () => {
        return {
          url: `content`
        };
      },
      providesTags: ['item']
    }),

    /* OBSOLETE */
    getItem: builder.query({
      query: (arg) => {
        const { label, id } = arg;
        return {
          url: `content/${label}/${id}`
        };
      },
      providesTags: ["item"]
    }),
    getItems: builder.query({
      query: (arg) => {
        const { label } = arg;
        return {
          url: `content/${label}`
        }
      },
      providesTags: ['items']
    }),

    getContentItems: builder.query({
      query: (arg) => {
        const { label } = arg;
        return {
          url: `content/${label}`
        }
      },
      providesTags: ['items']
    }),
    getContentItem: builder.query({
      query: (arg) => {
        const { label, id } = arg;
        return {
          url: `content/${label}/${id}`
        };
      },
      providesTags: ["item"]
    }),
    getSharedContentItem: builder.query({
      query: (arg) => {
        const { pk, id } = arg;
        return {
          url: `content/shared/${pk}/${id}`
        };
      },
      providesTags: ["item"]
    }),
    getConnectedAll: builder.query({
      query: (arg) => {
        const { _id, label } = arg;
        return {
          url: `connected/${label}`,
          method: 'POST',
          body: { _id }
        };
      },
      providesTags: []
    }),
    getConnected: builder.query({
      query: (arg) => {
        const { _id, container, pk } = arg;
        return {
          url: `connected/${container}/${pk}`,
          method: 'POST',
          body: { _id }
        };
      },
      providesTags: ['item', 'items']
    }),
    getRelated: builder.query({
      query: (arg) => {
        const { name, value, container, pk } = arg;
        return {
          url: `related/${container}/${pk}`,
          method: 'POST',
          body: {
            name: name,
            value: value
          }
        };
      },
      providesTags: ['progress']
    }),
    /*
    getCollection: builder.query({
      query: (arg) => {
        const { label, ids } = arg;
        return {
          url: `collection/${label}`,
          method: 'POST',
          body: ids
        }
      }
    }),*/
    updateProperty: builder.mutation({
      query: (arg) => {
        const { pk, id, name, value } = arg;
        return {
          url: `content/${pk}/${id}/property`,
          method: 'POST',
          body: {
            name: name,
            value: value
          }
        }
      },
      invalidatesTags: ['progress', 'item', 'items']
    }),
    addProperty: builder.mutation({
      query: (arg) => {
        const { pk, id, name, value } = arg;
        return {
          url: `content/${pk}/${id}/property/add`,
          method: 'POST',
          body: {
            name: name,
            value: value
          }
        }
      },
      invalidatesTags: ['progress', 'item', 'items']
    }),
    deleteProperty: builder.mutation({
      query: (arg) => {
        const { pk, id, name } = arg;
        return {
          url: `content/${pk}/${id}/property/delete`,
          method: 'POST',
          body: {
            name: name
          }
        }
      },
      invalidatesTags: ['progress', 'item']
    }),
    createItem: builder.mutation({
      query: (arg) => {
        const { data } = arg;
        return {
          url: `content`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      },
      invalidatesTags: ['items']
    }),
    deleteItem: builder.mutation({
      query: (arg) => {
        const { pk, id } = arg;
        return {
          url: `content/${pk}/${id}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      },
      invalidatesTags: ['items']
    }),
    shareItem: builder.mutation({
      query: (arg) => {
        const { pk, id, userid } = arg;
        return {
          url: `content/share/${pk}/${id}/add/${userid}`,
          method: 'POST'
        }
      },
      invalidatesTags: ['item']
    }),
    unshareItem: builder.mutation({
      query: (arg) => {
        const { label, pk, id, userid } = arg;
        return {
          url: `content/share/${label}/${pk}/${id}/remove/${userid}`,
          method: 'POST'
        }
      },
      invalidatesTags: ['item']
    }),
    importPart: builder.mutation({
      query: (arg) => {
        const { pk, id, sourcepk, sourceid } = arg;
        return {
          url: `content/${pk}/${id}/import/${sourcepk}/${sourceid}`,
          method: 'POST'
        }
      },
      invalidatesTags: ['item']
    }),
    deleteImportPart: builder.mutation({
      query: (arg) => {
        const { pk, id, sourcepk, sourceid } = arg;
        return {
          url: `content/${pk}/${id}/import/${sourcepk}/${sourceid}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['item']
    }),
    publish: builder.mutation({
      query: (arg) => {
        const { label, pk, id } = arg;
        return {
          url: `publish/${label}/${pk}/${id}`,
          method: 'POST'
        }
      },
      invalidatesTags: ['progress', 'items']
    }),
    getPublishedItems: builder.query({
      query: (arg) => {
        const { pk } = arg;
        return {
          url: `published/${pk}`,
          method: 'GET'
        }
      },
      invalidatesTags: ['progress', 'items']
    }),
    getPublishedItem: builder.query({
      query: (arg) => {
        const { pk, id } = arg;
        return {
          url: `published/${pk}/${id}`,
          method: 'GET'
        }
      },
      invalidatesTags: ['progress', 'items']
    }),
    getGlobalItems: builder.query({
      query: (arg) => {
        const { label } = arg;
        return {
          url: `global/${label}`,
          method: 'GET'
        }
      }
    }),
    getGlobalItem: builder.query({
      query: (arg) => {
        const { pk, id } = arg;
        return {
          url: `global/${pk}/${id}`,
          method: 'GET'
        }
      }
    }),
    addToLibrary: builder.mutation({
      query: (arg) => {
        const { pk, id } = arg;
        return {
          url: `library/item/${pk}/${id}/add`,
          method: 'POST'
        }
      },
      invalidatesTags: ['items']
    }),
    getLibraryItems: builder.query({
      query: (arg) => {
        const { label } = arg;
        return {
          url: `library/items/${label}`,
          method: 'GET'
        }
      },
      invalidatesTags: ['items']
    }),
    getLibraryItem: builder.query({
      query: (arg) => {
        const { id } = arg;
        return {
          url: `library/item/${id}`,
          method: 'GET'
        }
      }
    }),
  })
})

export const {
  useGetUserQuery,
  useGetInstagramQuery,
  useGetProfilesQuery,
  useGetProfileQuery,
  useGetContentItemsQuery,
  useGetContentItemQuery,
  useGetSharedContentItemQuery,
  useGetConnectedAllQuery,
  useGetConnectedQuery,
  useGetRelatedQuery,
  useGetCollectionQuery,
  useUpdatePropertyMutation,
  useAddPropertyMutation,
  useDeletePropertyMutation,
  useCreateItemMutation,
  useDeleteItemMutation,
  useShareItemMutation,
  useUnshareItemMutation,
  useImportPartMutation,
  useDeleteImportPartMutation,
  usePublishMutation,
  useGetPublishedItemsQuery,
  useGetPublishedItemQuery,
  useGetGlobalItemsQuery,
  useGetGlobalItemQuery,
  useAddToLibraryMutation,
  useGetLibraryItemsQuery,
  useGetLibraryItemQuery,
  useGetContentQuery,
  useGetItemQuery,
  useGetItemsQuery
} = docApi
