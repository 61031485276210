import tinycolor from "tinycolor2";

export const drawCircle = (ctx, x, y, size, fillColor, strokeColor) => {
    ctx.beginPath();
    ctx.arc(x, y, size / 2, 0, 2 * Math.PI);
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawSquare = (ctx, x, y, size, fillColor, strokeColor) => {
    ctx.beginPath();
    ctx.rect(x - size / 2, y - size / 2, size, size);
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawTriangle = (ctx, x, y, size, fillColor, strokeColor) => {
    ctx.beginPath();
    ctx.moveTo(x, y - size / 2);
    ctx.lineTo(x + size / 2, y + size / 2);
    ctx.lineTo(x - size / 2, y + size / 2);
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawStar = (ctx, x, y, size, fillColor, strokeColor) => {
    const outerRadius = size / 2;
    const innerRadius = outerRadius / 2.5;
    const points = 5;
    ctx.beginPath();
    for (let i = 0; i < points; i++) {
        const angle = (i * 2 * Math.PI) / points - Math.PI / 2;
        const outerX = x + outerRadius * Math.cos(angle);
        const outerY = y + outerRadius * Math.sin(angle);
        ctx.lineTo(outerX, outerY);
        const innerAngle = angle + Math.PI / points;
        const innerX = x + innerRadius * Math.cos(innerAngle);
        const innerY = y + innerRadius * Math.sin(innerAngle);
        ctx.lineTo(innerX, innerY);
    }
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawDiamond = (ctx, x, y, size, fillColor, strokeColor) => {
    ctx.beginPath();
    ctx.moveTo(x, y - size / 2);
    ctx.lineTo(x + size / 2, y);
    ctx.lineTo(x, y + size / 2);
    ctx.lineTo(x - size / 2, y);
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawX = (ctx, x, y, size, fillColor, strokeColor) => {
    ctx.beginPath();
    ctx.moveTo(x - size / 2, y - size / 2);
    ctx.lineTo(x + size / 2, y + size / 2);
    ctx.moveTo(x + size / 2, y - size / 2);
    ctx.lineTo(x - size / 2, y + size / 2);
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawHexagon = (ctx, x, y, size, fillColor, strokeColor) => {
    const points = Array.from({ length: 6 }, (_, i) => {
        const angle = (i * 2 * Math.PI) / 6 - Math.PI / 2;
        return {
            x: x + (size / 2) * Math.cos(angle),
            y: y + (size / 2) * Math.sin(angle),
        };
    });
    ctx.beginPath();
    points.forEach((point, i) => {
        if (i === 0) ctx.moveTo(point.x, point.y);
        else ctx.lineTo(point.x, point.y);
    });
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawPentagon = (ctx, x, y, size, fillColor, strokeColor) => {
    const points = Array.from({ length: 5 }, (_, i) => {
        const angle = (i * 2 * Math.PI) / 5 - Math.PI / 2;
        return {
            x: x + (size / 2) * Math.cos(angle),
            y: y + (size / 2) * Math.sin(angle),
        };
    });
    ctx.beginPath();
    points.forEach((point, i) => {
        if (i === 0) ctx.moveTo(point.x, point.y);
        else ctx.lineTo(point.x, point.y);
    });
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawCross = (ctx, x, y, size, fillColor, strokeColor) => {
    const thickness = size / 5;
    ctx.beginPath();
    ctx.rect(x - thickness / 2, y - size / 2, thickness, size); // Vertical bar
    ctx.rect(x - size / 2, y - thickness / 2, size, thickness); // Horizontal bar
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawEllipse = (ctx, x, y, size, fillColor, strokeColor) => {
    const width = size;
    const height = size / 1.5;
    ctx.beginPath();
    ctx.ellipse(x, y, width / 2, height / 2, 0, 0, 2 * Math.PI);
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawHeart = (ctx, x, y, size, fillColor, strokeColor) => {
    const topCurveHeight = size * 0.3;
    ctx.beginPath();
    ctx.moveTo(x, y + size / 4);
    ctx.bezierCurveTo(x - size / 2, y - topCurveHeight, x - size, y + size / 2, x, y + size);
    ctx.bezierCurveTo(x + size, y + size / 2, x + size / 2, y - topCurveHeight, x, y + size / 4);
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawParallelogramRight = (ctx, x, y, size, fillColor, strokeColor) => {
    const offset = size / 4;
    ctx.beginPath();
    ctx.moveTo(x - size / 2 + offset, y - size / 2);
    ctx.lineTo(x + size / 2 + offset, y - size / 2);
    ctx.lineTo(x + size / 2 - offset, y + size / 2);
    ctx.lineTo(x - size / 2 - offset, y + size / 2);
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawParallelogramLeft = (ctx, x, y, size, fillColor, strokeColor) => {
    const offset = size / 4;
    ctx.beginPath();
    ctx.moveTo(x - size / 2 - offset, y - size / 2);
    ctx.lineTo(x + size / 2 - offset, y - size / 2);
    ctx.lineTo(x + size / 2 + offset, y + size / 2);
    ctx.lineTo(x - size / 2 + offset, y + size / 2);
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawOctagon = (ctx, x, y, size, fillColor, strokeColor) => {
    const points = Array.from({ length: 8 }, (_, i) => {
        const angle = (i * 2 * Math.PI) / 8 - Math.PI / 8;
        return {
            x: x + (size / 2) * Math.cos(angle),
            y: y + (size / 2) * Math.sin(angle),
        };
    });
    ctx.beginPath();
    points.forEach((point, i) => {
        if (i === 0) ctx.moveTo(point.x, point.y);
        else ctx.lineTo(point.x, point.y);
    });
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawArrow = (ctx, x, y, size, fillColor, strokeColor) => {
    ctx.beginPath();
    ctx.moveTo(x - size / 2, y);
    ctx.lineTo(x, y - size / 2);
    ctx.lineTo(x, y - size / 4);
    ctx.lineTo(x + size / 2, y - size / 4);
    ctx.lineTo(x + size / 2, y + size / 4);
    ctx.lineTo(x, y + size / 4);
    ctx.lineTo(x, y + size / 2);
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawChevronRight = (ctx, x, y, size, fillColor, strokeColor) => {
    ctx.beginPath();
    ctx.moveTo(x - size / 2, y - size / 2);
    ctx.lineTo(x, y);
    ctx.lineTo(x - size / 2, y + size / 2);
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const drawChevronLeft = (ctx, x, y, size, fillColor, strokeColor) => {
    ctx.beginPath();
    ctx.moveTo(x + size / 2, y - size / 2);
    ctx.lineTo(x, y);
    ctx.lineTo(x + size / 2, y + size / 2);
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
};

export const shapeMap = {
    circle: drawCircle,
    square: drawSquare,
    triangle: drawTriangle,
    star: drawStar,
    diamond: drawDiamond,
    x: drawX,
    hexagon: drawHexagon,
    pentagon: drawPentagon,
    cross: drawCross,
    ellipse: drawEllipse,
    heart: drawHeart,
    "parallelogram-right": drawParallelogramRight,
    "parallelogram-left": drawParallelogramLeft,
    octagon: drawOctagon,
    arrow: drawArrow,
    "chevron-right": drawChevronRight,
    "chevron-left": drawChevronLeft,
};

export function getShapeColor(color) {
    if (!color) return "#f3f3f3";
    const tc = tinycolor(color);
    return tc.isLight() ? 'black' : 'white';
  }
  