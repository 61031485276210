import { Card, Icon } from '@blueprintjs/core';
import { appstyles } from '../styles/appstyles';
import { useState } from 'react';
import { useUpdatePropertyMutation } from '../store/docApi';

import LayoutEditor from '../Layout/LayoutEditor';
import ColorChartThumbnail from '../Charts/ColorChartThumbnail';

export default function Layouts({ item, motifs }) {
    const [updateProperty] = useUpdatePropertyMutation();
    const [editLayoutIndex, setEditLayoutIndex] = useState();

    const addLayout = async () => {
        let layouts = item.layouts ? JSON.parse(JSON.stringify(item.layouts)) : [];
        console.log(layouts);
        layouts.push({ id: "layout" + layouts.length, name: "New Layout" });
        await updateProperty({ label: "collection", pk: item.pk, id: item.id, name: "layouts", value: layouts });
        setEditLayoutIndex(layouts.length - 1);
        //setSelectedTab("layouts");
    }

    const saveLayout = async (layout) => {
        let layouts = item.layouts ? JSON.parse(JSON.stringify(item.layouts)) : [];
        layouts[editLayoutIndex] = layout;
        await updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "layouts", value: layouts });
        setEditLayoutIndex();
    }

    const deleteLayout = async () => {
        let layouts = item.layouts ? JSON.parse(JSON.stringify(item.layouts)) : [];
        layouts.splice(editLayoutIndex, 1);
        await updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "layouts", value: layouts });
        setEditLayoutIndex();
    }

    const cancelEditLayout = () => {
        setEditLayoutIndex();
    }

    return <div>
        {editLayoutIndex === undefined && <div style={appstyles.tileGrid}>
            <Card style={appstyles.card} interactive onClick={() => addLayout()}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 16 }}>
                    <Icon icon="plus" size={32} />
                    <b>Add new layout</b>
                </div>
            </Card>
            {item.layouts && item.layouts.map((layout, i) =>
                <Card key={i} style={appstyles.tile} interactive onClick={() => setEditLayoutIndex(i)}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h3>{layout.name}</h3>
                        {layout.numrows && <p>Rows: {layout.numrows}</p>}
                        {layout.steps && layout.steps.map((step, j) =>
                            <ColorChartThumbnail key={j} data={step.data} colors={layout.colors} mincols={24} />
                        )}
                    </div>
                </Card>
            )}
        </div>}
        <br />
        {item.layouts && editLayoutIndex < item.layouts.length && <div>
            <LayoutEditor availableSteps={motifs} initialLayout={item.layouts[editLayoutIndex]} initialColors={item.layouts[editLayoutIndex].colors}
                onSave={saveLayout} onDelete={deleteLayout} onCancel={cancelEditLayout} />
        </div>}
    </div>
}

