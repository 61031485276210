import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetGlobalItemQuery } from '../store/docApi';
import Links from '../Widgets/Links';

const Yarn = () => {
    let params = useParams();
    const { data } = useGetGlobalItemQuery({ pk: params.pk, id: params.id });
    const [item, setItem] = useState(null);

    useEffect(() => {
        if (data) {
            const sortedData = { ...data };
            sortedData.chromas = data && data.chromas && [...data.chromas].sort((a, b) => (b.amount || 0) - (a.amount || 0));
            setItem(sortedData);
        }
    }, [data]);

    return (
        <div>
            {item && <div>
                <h1>{item.brand}</h1>
                <h2>{item.name}</h2>
                <p>{item.description}</p>
                <div><b>Brand:</b> {item.brand}</div>
                <div><b>Weight:</b> {item.weight}</div>
                <div><b>Yardage:</b> {item.yardage}</div>
                <div><b>Fiber:</b> {item.fiber}</div>
                {item.links && <Links links={item.links} />}
                <h3>Available Colors</h3>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {item.chromas && item.chromas.map((colorway, index) => (
                        <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 10 }}>
                            <img src={colorway.picture} alt={colorway.name} width="100" height="100" />
                            <div style={{ backgroundColor: colorway.color, width: 100, height: 20 }}></div>
                            {colorway.code && <div>{colorway.code}</div>}
                            {colorway.name && <div>{colorway.name}</div>}
                            {colorway.shade && <div>{colorway.shade}</div>}
                        </div>
                    ))}
                </div>
            </div>}
        </div>
    );
};

export default Yarn;
