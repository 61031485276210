import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAddPropertyMutation } from '../store/docApi';
import { Button, Card, Classes, Dialog, FormGroup, InputGroup } from '@blueprintjs/core';
import ColorChartThumbnail from '../Charts/ColorChartThumbnail';
import { appstyles } from '../styles/appstyles';
import MotifBrowser from './MotifBrowser';

export default function Motifs({ item }) {
  const navigate = useNavigate();
  const [addProperty] = useAddPropertyMutation();
  const [isMotifDialogOpen, setIsMotifDialogOpen] = useState(false);
  const [isMotifBrowserOpen, setIsMotifBrowserOpen] = useState(false);
  const [newMotif, setNewMotif] = useState({ id: Date.now(), name: "", data: [] });

  function onAddMotif() {
    setIsMotifDialogOpen(false);
    let updatedMotifs = item.motifs ? [...item.motifs] : [];
    updatedMotifs.push({ ...newMotif, id: Date.now() });
    addProperty({ label: item.label, pk: item.pk, id: item.id, name: 'motifs', value: newMotif });
    setNewMotif({ id: Date.now(), name: '', data: [] });
  }

  function onSelectMotif(motif) {
    setIsMotifBrowserOpen(false);
    let updatedMotifs = item.motifs ? [...item.motifs] : [];
    updatedMotifs.push(motif);
    addProperty({ label: item.label, pk: item.pk, id: item.id, name: 'motifs', value: motif });
  }

  return (
    <div>
      <h4>Motifs</h4>
      <div style={appstyles.tileGrid}>
        {item.motifs && item.motifs.map((motif, index) =>
          <Card interactive key={index} style={styles.card} onClick={() => navigate(`/design/${item.pk}/${item.id}/motif/${index}`)}>
            {motif.data && <ColorChartThumbnail data={motif.data} colors={item.colors || motif.colors} mincols={24} norepeat={motif.norepeat} />}
            {motif.name && <div style={styles.name}>{motif.name}</div>}
            {motif.keywords && <div style={styles.keywords}>{motif.keywords}</div>}
          </Card>
        )}
      </div>
      <br/>
      <Button outlined icon="plus" onClick={() => setIsMotifDialogOpen(true)}>Add motif</Button>
      &nbsp;
      <Button outlined icon="import" onClick={() => setIsMotifBrowserOpen(true)}>Import motif</Button>
      <Dialog isOpen={isMotifDialogOpen} onClose={() => setIsMotifDialogOpen(false)} title="Add New Motif">
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="Id">
            <InputGroup value={newMotif.id} onChange={(e) => setNewMotif({ ...newMotif, id: e.target.value })} />
          </FormGroup>
          <FormGroup label="Name">
            <InputGroup value={newMotif.name} onChange={(e) => setNewMotif({ ...newMotif, name: e.target.value })} />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Cancel" onClick={() => setIsMotifDialogOpen(false)} />
            <Button intent="primary" text="Add" onClick={onAddMotif} />
          </div>
        </div>
      </Dialog>
      <Dialog isOpen={isMotifBrowserOpen} onClose={() => setIsMotifBrowserOpen(false)} title="Import Motif">
        <MotifBrowser onSelectMotif={onSelectMotif} onClose={() => setIsMotifBrowserOpen(false)} />
      </Dialog>
    </div>
  );
}

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  img: {
    maxHeight: 100,
    maxWidth: 100,
  },
  name: {
    textAlign: 'center',
    marginTop: 8
  },
  keywords: {
    textAlign: 'center',
    fontSize: 10,
    marginTop: 4
  }
};
