import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetContentItemsQuery } from '../store/docApi';
import { appstyles } from '../styles/appstyles';
import { Button, Card, Tag, InputGroup, Switch } from '@blueprintjs/core';
import ColorChartThumbnail from '../Charts/ColorChartThumbnail';

export default function ContentPage() {
    const navigate = useNavigate();
    const { data: items, error, isLoading } = useGetContentItemsQuery({ label: "design" });
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isExpandedView, setIsExpandedView] = useState(false);

    useEffect(() => {
        if (items) {
            setFilteredItems(items);
        }
    }, [items]);

    useEffect(() => {
        if (searchKeyword) {
            const filtered = items ? items.filter(item => 
                item.motifs && item.motifs.some(motif => 
                    (motif.keywords || '').toLowerCase().includes(searchKeyword.toLowerCase())
                )
            ) : [];
            setFilteredItems(filtered);
        } else {
            setFilteredItems(items || []);
        }
    }, [searchKeyword, items]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading content</div>;

    return (
        <div style={appstyles.content}>
            <div style={appstyles.titleWithButtons}>
                <h2>Designs</h2>
                <Button outlined icon="plus" onClick={() => navigate('/content/create')}>Create</Button>
            </div>
            <InputGroup
                leftIcon="search"
                placeholder="Search by keyword..."
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                style={{ marginBottom: '20px' }}
            />
                <Switch
                    checked={isExpandedView}
                    label="Show motifs"
                    onChange={() => setIsExpandedView(!isExpandedView)}
                />
            <div style={isExpandedView ? appstyles.expandedTileGrid : appstyles.tileGrid}>
                {filteredItems.map((item, i) =>
                    <div key={i}>
                        {isExpandedView ? (
                            <Card key={item.id} style={appstyles.expandedTile}>
                                    <b>{item.name}</b>
                                    <p>{item.description}</p>
                                    <div style={appstyles.tileGrid}>
                                        {item.motifs && item.motifs
                                            .filter(motif => (motif.keywords || '').toLowerCase().includes(searchKeyword.toLowerCase()))
                                            .map((motif, index) => (
                                                <Card key={index} style={appstyles.motifCard} interactive={true}
                                                    onClick={() => navigate(`/${item.label}/${item.pk}/${item.id}/motif/${index}`)}>
                                                    <b>{motif.name}</b>
                                                    <ColorChartThumbnail data={motif.data} colors={motif.colors} mincols={24} />
                                                    <p style={styles.description}>{motif.keywords}</p>
                                                </Card>
                                            ))}
                                    </div>
                    </Card>
                ) : (
                    <Card key={item.id} style={appstyles.tile} interactive={true}
                        onClick={() => navigate(`/${item.label}/${item.pk}/${item.id}`)}>
                                {item.picture && <img style={appstyles.tileImg} src={item.picture} alt={item.name} />}
                                <b>{item.name}</b>
                                <p style={styles.description}>{item.description}</p>
                                {item.motifs && item.motifs.length > 0 && <Tag minimal>{item.motifs.length} motifs</Tag>}
                            </Card>
                        )
                    }
                    </div>
                )}
            </div>
                
            </div>
    );
};

const styles = {
    description: {
        fontSize: 9,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    tileImg: {
        width: '50px',
        height: 'auto',
        marginBottom: '10px'
    },
    expandedTileImg: {
        width: '50px',
        height: 'auto',
        marginRight: '20px'
    }
};
