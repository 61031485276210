import { useGetItemQuery } from '../store/docApi';
import { useParams } from 'react-router-dom';
import { Card } from '@blueprintjs/core';
import { formatDateTime, formatDateShort } from "../utils";
import Picture from '../Widgets/Picture';
import Links from '../Widgets/Links';
import Tags from '../Widgets/Tags';
import Notes from '../Widgets/Notes';

export default function EventDetails() {
    let params = useParams();

    const { data: item } = useGetItemQuery({ container: 'event', label: 'event', pk: params.pk, id: params.id });
    function isToday(date) {
        let d = new Date(date);
        let now = new Date(Date.now());
        if (d.getDate() === now.getDate() && d.getMonth() === now.getMonth() && d.getFullYear() === now.getFullYear())
            return true;
        return false;
    }

    return item &&
        <div>
            {item.name && <b>{item.name} </b>}
            <div className="bp4-text-small">
                {item.startdate && formatDateShort(item.startdate)}
                {item.enddate && <span> - {formatDateShort(item.enddate)}</span>}
            </div>
            <Picture picture={item.picture} pictures={item.pictures} />
            {item.description}
            {item.host && <div>Hosted by {item.host}</div>}
            {item.hashtags && <Tags tags={item.hashtags} />}
            <Links links={item.links} />
            <div>
                {item.steps && item.steps.map((step, i) => (
                    <Card key={i} style={isToday(step.date) ? styles.highlightCard : styles.card}>
                        {step.id} <b>{step.name}</b>
                        <div className="bp4-text-small">
                            {formatDateShort(step.date)}
                            {step.startdate && formatDateTime(step.startdate)}
                            {step.enddate && <span> - {formatDateTime(step.enddate)}</span>}
                        </div>

                        <div>{step.note}</div>
                        {step.host}
                        <Notes notes={step.notes} />
                        <Links links={step.links} />
                        <a href={step.website} target="_blank" rel="noreferrer">{step.website}</a>
                    </Card>
                ))}
            </div>
            <div align='right' className="bp4-text-small"><br /><i>{item.id}</i></div>
        </div>
}

const styles = {
    card: {
        margin: 10,
        padding: 10
    },
    highlightCard: {
        margin: 10,
        padding: 10,
        backgroundColor: 'lightyellow'
    }
}
