import EditableProperty from './EditableProperty';
import Motifs from './Motifs';
import PictureManager from './PictureManager';

export default function DesignDetails({ item }) {

    return (<div>
        <EditableProperty title="Name" item={item} propname="name" propvalue={item.name} placeholder="Enter design name..." />
        <EditableProperty title="Description" item={item} propname="description" propvalue={item.description} placeholder="Enter design description..." multiline={true} />
        <b>Pictures</b>
        <PictureManager item={item} />
        <Motifs item={item} />
    </div>
    );
}