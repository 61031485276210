import { useNavigate } from "react-router-dom";
import { Button, Icon } from "@blueprintjs/core";
import { appstyles } from "../styles/appstyles";

export default function WelcomePage() {
  const navigate = useNavigate();
  return (
    <center>
      <div style={styles.title}>
        <Icon icon="grid-view" size={22} color="lightgrey" style={{ marginRight: 8 }} />
        <span style={appstyles.title}>STITCH PORTAL</span>
      </div>
      <Button onClick={() => navigate("/login")} style={styles.loginButton}>Login</Button>
    </center>
  );
}

const styles = {
  loginButton: { margin: 48, color: 'white', backgroundColor: 'teal' },
  title: { marginTop: 32 }
}


