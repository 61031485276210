import { useNavigate } from "react-router-dom";
import { Card } from "@blueprintjs/core";
//import { store } from "../../store/store";
//import { showMotifAction } from "../../store/partSlice";
import ColorChartThumbnail from "../Charts/ColorChartThumbnail";
import KnitChart from "../Charts/KnitChart";
import { appstyles } from "../styles/appstyles";

export default function PartCard({ part, colors }) {
  const navigate = useNavigate();
/*
  function showMotifDetails() {
    store.dispatch(showMotifAction({
      show: true,
      part: part
    }));
  }
*/
  return (
    <Card interactive style={styles.card} onClick={() => navigate(`/part/${part.pk}/${part.id}`)}>
      {part.picture && <img style={styles.img} src={part.picture} alt={part.name} />}
      {part.colorchart && part.data && <ColorChartThumbnail data={part.data} colors={colors || part.colors} mincols={24} />}
      {part.knitchart && <KnitChart steps={part.steps} item={part} chartid={part.id} thumbnail={true} />}
      <div style={styles.name}>
          {part.name ? part.name : part.id}
      </div>
      <div style={appstyles.id}>{part.id}</div>
    </Card>
  )
}

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  img: {
    maxHeight: 100,
    maxWidth: 100,
  },
  name: {
    textAlign: 'center',
    marginTop: 8
  }
}
