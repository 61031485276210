import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import { docApi } from './docApi';
import { mediaApi } from './mediaApi';
import { itemSlice } from './itemSlice';
import { motifSlice } from './motifSlice';
import { userSlice } from './userSlice';
import { searchApi } from './searchApi';
import { openaiApi } from './openaiApi';
import { stripeApi } from './stripeApi';
import { appApi } from './appApi';

export const store = configureStore ({
    reducer: {
        [docApi.reducerPath]: docApi.reducer,
        [searchApi.reducerPath]: searchApi.reducer,
        [mediaApi.reducerPath]: mediaApi.reducer,
        [openaiApi.reducerPath]: openaiApi.reducer,
        [stripeApi.reducerPath]: stripeApi.reducer,
        [userSlice.name]: userSlice.reducer,
        [itemSlice.name]: itemSlice.reducer,
        [motifSlice.name]: motifSlice.reducer,
        [appApi.reducerPath]: appApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(docApi.middleware)
        .concat(mediaApi.middleware)
        .concat(searchApi.middleware)
        .concat(openaiApi.middleware)
        .concat(stripeApi.middleware)
        .concat(appApi.middleware)
});

setupListeners(store.dispatch)
