import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetItemQuery } from '../store/docApi';
import { Button } from '@blueprintjs/core';
import FileUpload from '../Widgets/FileUpload';
import Links from '../Widgets/Links';
import { useUpdatePropertyMutation } from '../store/docApi';
import { appstyles } from '../styles/appstyles';
import PublishItem from '../Widgets/PublishItem';

const Yarn = () => {
    let params = useParams();
    const { data } = useGetItemQuery({ label: 'yarn', pk: params.pk, id: params.id });
    const [updateProperty] = useUpdatePropertyMutation();
    const [item, setItem] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (data) {
            const sortedData = { ...data };
            sortedData.chromas = data && data.chromas && [...data.chromas].sort((a, b) => (b.amount || 0) - (a.amount || 0));
            setItem(sortedData);
        }
    }, [data]);

    const setColor = (index, color) => {
        setItem({ ...item, chromas: item.chromas.map((cw, i) => i === index ? { ...cw, color: color } : cw) });
    }

    const setAmount = (index, amount) => {
        setItem({ ...item, chromas: item.chromas.map((cw, i) => i === index ? { ...cw, amount: amount } : cw) });
    }

    const handleColorSave = async () => {
        updateProperty({ pk: item.pk, id: item.id, name: "chromas", value: item.chromas });
        setIsEditing(false);
    };

    const processImage = (file, index) => {
        if (!(file instanceof Blob)) {
            console.error('Invalid file type');
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, img.width, img.height);

                const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
                let r = 0, g = 0, b = 0, count = 0;

                for (let i = 0; i < imageData.length; i += 4) {
                    r += imageData[i];
                    g += imageData[i + 1];
                    b += imageData[i + 2];
                    count++;
                }

                r = Math.min(255, Math.floor(r / count) + 20);
                g = Math.min(255, Math.floor(g / count) + 20);
                b = Math.min(255, Math.floor(b / count) + 20);

                const hexColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

                console.log("color", hexColor);
                setColor(index, hexColor);
            };
        };
        reader.readAsDataURL(file);
    };

    return (
        <div>
            {item && <div>
                <h1>{item.name}</h1>
                <p>{item.description}</p>
                <div><b>Brand:</b> {item.brand}</div>
                <div><b>Weight:</b> {item.weight}</div>
                <div><b>Yardage:</b> {item.yardage}</div>
                <div><b>Fiber:</b> {item.fiber}</div>
                {item.links && <Links links={item.links} />}
                <div style={appstyles.titleWithButtons}>
                    <h2>Available Colors</h2>
                    <div>
                    {!isEditing && <Button onClick={() => setIsEditing(true)}>Edit</Button>}
                    {isEditing && <Button onClick={() => handleColorSave()}>Save</Button>}
                    <PublishItem item={item} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {item.chromas && item.chromas.map((colorway, index) => (
                        <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 10 }}>
                            <img src={colorway.picture} alt={colorway.name} width="100" height="100" />
                            <div style={{ backgroundColor: colorway.color, width: 100, height: 20 }}></div>
                            {colorway.code && <div>{colorway.code}</div>}
                            {colorway.name && <div>{colorway.name}</div>}
                            {colorway.shade && <div>{colorway.shade}</div>}
                            {colorway.amount && <div>Amount: {colorway.amount}</div>}
                            {isEditing && <input type="color" value={colorway.color} onChange={(e) => setColor(index, e.target.value)} />}
                            {isEditing && <input type="text" value={colorway.amount} onChange={(e) => setAmount(index, e.target.value)} />}
                            {isEditing && (
                                <>
                                    <input type="file" accept="image/*" onChange={(e) => processImage(e.target.files[0], index)} />
                                </>
                            )}
                        </div>
                    ))}
                </div>
                <FileUpload folder={item.pk} item={item} />
            </div>}
        </div>
    );
};

export default Yarn;
