import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from '@blueprintjs/core';
import { usePublishMutation } from '../store/docApi';
import ProductCreate from '../Shop/ProductCreate';
import { AppToaster } from '../AppToaster';

const PublishItem = ({ item }) => {
  const [publish, result] = usePublishMutation();
  const [showPublish, setShowPublish] = useState(false);

  useEffect(() => {
    const showToast = async () => {
      if (result.isSuccess) {
        (await AppToaster).show({ message: `${item.name} published to the store.` });
      }
    };
    showToast();
  }, [result, item.name]);

  const onPublish = async () => {
    await publish({ label: item.label, pk: item.pk, id: item.id });
  };

  const onSell = () => {
    setShowPublish(true);
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip content="Publish" placement="bottom">
          <Button minimal icon="cloud-upload" onClick={onPublish} />
        </Tooltip>
        {false && <Tooltip content="Sell" placement="bottom">
          <Button minimal icon="shop" onClick={onSell} />
        </Tooltip>}
      </div>
      {showPublish && <ProductCreate item={item} />}
    </div>
  );
};

export default PublishItem;
