import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { Drawer, Button, Menu, MenuDivider, MenuItem, NavbarHeading, NavbarGroup, Navbar } from '@blueprintjs/core';
import { useGetUserQuery } from './store/docApi';
import { appstyles } from './styles/appstyles';

export default function NavMenu() {
  const { data: userinfo } = useGetUserQuery({}, { skip: !auth.currentUser });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const navigate = useNavigate();
  let user = auth.currentUser;

  function menuClicked(path) {
    setIsMenuOpen(false);
    setIsUserMenuOpen(false);
    navigate(path);
  }

  function logoutClicked() {
    setIsMenuOpen(false);
    setIsUserMenuOpen(false);
    signOut(auth).then(() => window.location.reload());
    navigate("/");
  }

  const userMenu = user && (
    <Menu>
      <MenuItem icon="person" text="Profile" onClick={() => menuClicked('/profile')} />
      <MenuItem icon="th-list" text="Orders" onClick={() => menuClicked("/orders")} />
      <MenuItem icon="log-out" text="Logout" onClick={() => logoutClicked()} />
    </Menu>
  );

  const menu = user && (
    <Menu>
      <MenuItem icon="home" text="Home" onClick={() => menuClicked("/")} />
      <MenuDivider />
      <MenuItem icon="grid-view" text="Designs" onClick={() => menuClicked("/designs")} />
      <MenuItem icon="cube" text="Projects" onClick={() => menuClicked("/projects")} />
      <MenuDivider />
      <MenuItem icon="manual" text="Library" onClick={() => menuClicked("/library")} />
      <MenuItem icon="book" text="Resources" onClick={() => menuClicked("/resources")} />
      <MenuDivider />
      <MenuItem icon="search" text="Explore" onClick={() => menuClicked("/explore")} />
      {/*
      <MenuItem text="Other" />
      <MenuItem icon="shop" text="Marketplace" onClick={() => menuClicked("/shops")} />
      <MenuItem icon="learning" text="How To" onClick={() => menuClicked("/howto")} />
      <MenuDivider />
      <MenuItem icon="book" text="References" onClick={() => menuClicked("/references")} />
      <MenuDivider />
      <MenuItem text="Designs" />
      <MenuItem icon="layout-grid" text="Patterns" onClick={() => menuClicked("/patterns")} />
      <MenuItem icon="shapes" text="Parts" onClick={() => menuClicked("/parts")} />
      <MenuItem icon="waves" text="Motifs" onClick={() => menuClicked("/motifs")} />
      <MenuItem icon="layout-three-rows" text="Layouts" onClick={() => menuClicked("/layouts")} />
      <MenuItem icon="folder-close" text="Collections" onClick={() => menuClicked("/collections")} />
      <MenuItem icon="style" text="Palettes" onClick={() => menuClicked("/palettes")} />
      <MenuDivider />
      <MenuItem text="Experiences" />
      <MenuItem icon="annotation" text="Notes" onClick={() => menuClicked("/notes")} />
      <MenuItem icon="calendar" text="Events" onClick={() => menuClicked("/events")} />
      <MenuItem icon="airplane" text="Trips" onClick={() => menuClicked("/trips")} />
      <MenuItem icon="geosearch" text="Quests" onClick={() => menuClicked("/quests")} />
      <MenuItem icon="map-marker" text="Places" onClick={() => menuClicked("/places")} />
      */}
    </Menu>
  );

  /*
      <MenuDivider />
      {userinfo && userinfo.name &&
        <MenuItem icon="user" text={`@${userinfo.name} Projects`} onClick={() => menuClicked(`/@${userinfo.name}`)} />
      }
      <MenuDivider />
      <MenuItem icon="search" text="Search" onClick={() => menuClicked("/search")} />
      <MenuItem icon="layout-grid" text="Items" onClick={() => menuClicked("/item")} />
      <MenuDivider />
      <MenuItem icon="cube" text="New project" onClick={() => menuClicked(`@${userinfo.name}/create`)}
        selected={location.pathname.startsWith("/project/create")} />
      <MenuItem icon="snowflake" text="New motif" onClick={() => menuClicked("/motif/create")}
        selected={location.pathname.startsWith("/motif/create")} />
      <MenuItem icon="book" text="New collection" onClick={() => menuClicked("/collection/create")}
        selected={location.pathname.startsWith("/collection/create")} />
  */

  return <Navbar style={styles.navbar} fixedToTop={true}>
    <NavbarGroup>
      {user && <>
        <Button minimal icon="menu" onClick={() => setIsMenuOpen(true)} />
        <Drawer position="left" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}
          canOutsideClickClose canEscapeKeyClose size="200px">
          {menu}
        </Drawer>
      </>}
    </NavbarGroup>
    <NavbarGroup style={{ flex: 1, justifyContent: 'center' }}>
      <NavbarHeading style={{...appstyles.title}} onClick={() => navigate("/")}>
        Stitch Portal</NavbarHeading>
    </NavbarGroup>
    <NavbarGroup>
      {user && userinfo && <>
        {user && userinfo && userinfo.picture ?
          <img src={userinfo.picture} alt="avatar" style={styles.usernameCircleStyle}
            onClick={() => setIsUserMenuOpen(true)} /> :
          <div style={styles.usernameCircleStyle} onClick={() => setIsUserMenuOpen(true)}>
            {userinfo.name.slice(0, 2).toUpperCase()} </div>}
        <Drawer position="right" isOpen={isUserMenuOpen} onClose={() => setIsUserMenuOpen(false)}
          canOutsideClickClose canEscapeKeyClose size="200px" title={userinfo.name}>
          {userMenu}
        </Drawer>
      </>}
      {!user &&
        <Button minimal onClick={() => menuClicked("/login")}>Login</Button>
      }
    </NavbarGroup>
  </Navbar>
}

const styles = {
  navbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
  },
  usernameCircleStyle: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    borderWidth: '1.5px',
    borderStyle: 'solid',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    fontSize: '18px',
    color: 'grey',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  menuicon: {
    cursor: 'pointer',
    color: 'grey',
    width: '40px',
    height: '40px'
  }
}