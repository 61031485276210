import React, { useEffect, useState } from 'react';
import { Button, Card, Colors, InputGroup, Label } from '@blueprintjs/core';
import ColorChartThumbnail from '../Charts/ColorChartThumbnail';
import { appstyles } from '../styles/appstyles';
// import ItemColors from '../Charts/ItemColors';

// shades of grey
//const defaultColors = ["#000000", "#1C1C1C", "#383838", "#545454", "#707070", "#8C8C8C", "#A8A8A8", "#C4C4C4", "#E0E0E0", "#FFFFFF",
//  "#2F2F2F", "#4B4B4B", "#676767", "#838383", "#9F9F9F", "#BBBBBB", "#D7D7D7", "#F3F3F3", "#1A1A1A", "#363636"];

export default function LayoutDetails({ item, initialLayout, onSave, onDelete, onCancel }) {
  const [name, setName] = useState(initialLayout.name);
  const [layoutSteps, setLayoutSteps] = useState(initialLayout.steps || []);
  const [numrows, setNumRows] = useState(0);
  const [draggedItem, setDraggedItem] = useState(null);
  const [hoveredStep, setHoveredStep] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [colors, setColors] = useState(initialLayout.colors ? initialLayout.colors : null);

  useEffect(() => {
    let _numrows = layoutSteps.length;
    setNumRows(_numrows);
  }, [layoutSteps]);

  const selectColorway = (index) => {
    if (item.colorways && index < item.colorways.length) {
      const colorway = item.colorways[index];
      if (colorway.chromas && colorway.chromas.length > 0) {
        const colors = colorway.chromas.map(chroma => chroma.color);
        if (colors.length > 0) {
          setColors(colors);
        }
      }
    }
  }

  const handleSave = () => {
    onSave({ ...initialLayout, name, numrows, steps: layoutSteps, colors: colors });
  };

  const handleDrop = (e, rowIndex, colIndex) => {
    e.preventDefault();
    setDragOverIndex(null);

    try {
      const droppedData = JSON.parse(e.dataTransfer.getData('text/plain'));
      const { fromLayout, ...item } = droppedData;

      let newLayoutSteps = layoutSteps.map(row => ({
        ...row,
        steps: row.steps.map(step => ({ ...step }))
      }));

      if (rowIndex === layoutSteps.length) {
        // Adding to a new row
        newLayoutSteps.push({ steps: [{ ...item }] });
      } else {
        if (fromLayout) {
          const dragRowIndex = newLayoutSteps.findIndex(row => row.steps.some(step => step.id === item.id));
          const dragColIndex = newLayoutSteps[dragRowIndex].steps.findIndex(step => step.id === item.id);
          newLayoutSteps[dragRowIndex].steps.splice(dragColIndex, 1);
        }
        newLayoutSteps[rowIndex].steps.splice(colIndex, 0, { ...item });
      }

      setLayoutSteps(newLayoutSteps);
    } catch (error) {
      console.error('Error handling drop:', error);
    }
  };

  const handleTouchEnd = (e, rowIndex, colIndex) => {
    if (!draggedItem) return;

    if (typeof rowIndex === 'number' && typeof colIndex === 'number') {
      let newLayoutSteps = layoutSteps.map(row => ({
        ...row,
        steps: row.steps.map(step => ({ ...step }))
      }));

      if (rowIndex === layoutSteps.length) {
        // Adding to a new row
        newLayoutSteps.push({ steps: [{ ...draggedItem }] });
      } else {
        if (draggedItem.fromLayout) {
          const dragRowIndex = newLayoutSteps.findIndex(row => row.steps.some(step => step.id === draggedItem.id));
          const dragColIndex = newLayoutSteps[dragRowIndex].steps.findIndex(step => step.id === draggedItem.id);
          newLayoutSteps[dragRowIndex].steps.splice(dragColIndex, 1);
        }
        newLayoutSteps[rowIndex].steps.splice(colIndex, 0, { ...draggedItem });
      }

      setLayoutSteps(newLayoutSteps);
    }

    setDraggedItem(null);
  };

  const handleDragStart = (e, item, fromLayout = false) => {
    e.dataTransfer.setData('text/plain', JSON.stringify({ ...item, fromLayout }));
    setDraggedItem(item);
  };

  const handleTouchStart = (e, item, fromLayout = false) => {
    setDraggedItem({ ...item, fromLayout });
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    setDragOverIndex(null);
  };

  const handleDragOver = (e, rowIndex, colIndex) => {
    e.preventDefault();
    setDragOverIndex({ rowIndex, colIndex });
  };

  const handleDragLeave = () => {
    setDragOverIndex(null);
  };

  const addPart = (step) => {
    let newLayoutSteps = [...layoutSteps];
    newLayoutSteps.push({ steps: [{ ...step }] });
    setLayoutSteps(newLayoutSteps);
  }

  const removePart = (rowIndex, colIndex) => {
    let newLayoutSteps = layoutSteps.map(row => ({
      ...row,
      steps: row.steps.map(step => ({ ...step }))
    }));
    newLayoutSteps[rowIndex].steps.splice(colIndex, 1);
    if (newLayoutSteps[rowIndex].steps.length === 0) {
      newLayoutSteps.splice(rowIndex, 1);
    }
    setLayoutSteps(newLayoutSteps);
  }

  const movePartUp = (rowIndex, colIndex) => {
    if (rowIndex > 0) {
      let newLayoutSteps = layoutSteps.map(row => ({
        ...row,
        steps: row.steps.map(step => ({ ...step }))
      }));
      const step = newLayoutSteps[rowIndex].steps.splice(colIndex, 1)[0];
      newLayoutSteps[rowIndex - 1].steps.push(step);
      setLayoutSteps(newLayoutSteps);
    }
  }

  const movePartDown = (rowIndex, colIndex) => {
    if (rowIndex < layoutSteps.length - 1) {
      let newLayoutSteps = layoutSteps.map(row => ({
        ...row,
        steps: row.steps.map(step => ({ ...step }))
      }));
      const step = newLayoutSteps[rowIndex].steps.splice(colIndex, 1)[0];
      newLayoutSteps[rowIndex + 1].steps.unshift(step);
      setLayoutSteps(newLayoutSteps);
    }
  }

  const movePartLeft = (rowIndex, colIndex) => {
    if (colIndex > 0) {
      let newLayoutSteps = layoutSteps.map(row => ({
        ...row,
        steps: row.steps.map(step => ({ ...step }))
      }));
      const step = newLayoutSteps[rowIndex].steps.splice(colIndex, 1)[0];
      newLayoutSteps[rowIndex].steps.splice(colIndex - 1, 0, step);
      setLayoutSteps(newLayoutSteps);
    }
  }

  const movePartRight = (rowIndex, colIndex) => {
    if (colIndex < layoutSteps[rowIndex].steps.length - 1) {
      let newLayoutSteps = layoutSteps.map(row => ({
        ...row,
        steps: row.steps.map(step => ({ ...step }))
      }));
      const step = newLayoutSteps[rowIndex].steps.splice(colIndex, 1)[0];
      newLayoutSteps[rowIndex].steps.splice(colIndex + 1, 0, step);
      setLayoutSteps(newLayoutSteps);
    }
  }

  return (
    <div>
      <Card>
        <div style={appstyles.titleWithButtons}>
          <Label>Name
            <InputGroup type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </Label>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }} >
            <Button icon="trash" text="Delete" onClick={onDelete} />
            <Button icon="floppy-disk" text="Save" onClick={() => handleSave()} />
            <Button icon="cross" text="Cancel" onClick={onCancel} />
          </div>
        </div>
        <div>Rows: {numrows} &nbsp; Motifs: {layoutSteps.reduce((acc, row) => acc + (row.steps ? row.steps.length : 0), 0)}</div>
        {/*<ItemColors item={{ colors: colors }} onChange={setColors} />*/}
        <div style={{ display: 'flex', gap: 8, marginTop: 8 }}>
        {item.colorways && item.colorways.map((colorway, index) => (
          <Card key={index} interactive={true} style={{ padding: '10px', width: 100 }} onClick={()=>selectColorway(index)} >
            <span>{colorway.name}</span>
            <div style={{ display: 'flex' }}>
              {colorway.chromas && colorway.chromas.map((color, index) => (
                <div key={index} style={{ width: 10, height: 10, backgroundColor: color.color }}></div>
              ))}
            </div>
          </Card>
        ))}
        </div>
        </Card>
        <br />
        <div style={styles.container}>
          <div style={{ ...styles.panel, ...styles.availableStepsPanel }}>
            <h2 style={styles.heading}>Available Motifs</h2>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              {item.motifs && item.motifs.map(step => (
                <div key={step.id} draggable="true"
                  onDragStart={(e) => handleDragStart(e, step)}
                  onDragEnd={handleDragEnd}
                  onTouchStart={(e) => handleTouchStart(e, step)}
                  onTouchEnd={handleTouchEnd}
                  onMouseEnter={() => setHoveredStep(step.id)}
                  onMouseLeave={() => setHoveredStep(null)}
                  style={{
                    ...styles.availableStep,
                    ...(hoveredStep === step.id ? styles.stepHover : {}),
                    ...(draggedItem?.id === step.id ? styles.dragging : {})
                  }}
                >
                  <div>
                    {step.name}
                    <ColorChartThumbnail data={step.data} mincols={24} colors={step.colors} norepeat={step.norepeat}/>
                  </div>
                  <Button icon="plus" minimal onClick={() => addPart(step)} />
                </div>
              ))}
            </div>
          </div>

          <div style={{ ...styles.panel, ...styles.layoutPanel }}>
            <h2 style={styles.heading}>{name}</h2>
            <div>
              {layoutSteps.map((row, rowIndex) => (
                <div key={`row-${rowIndex}`} style={{ marginBottom: '1rem' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                    {row.steps && row.steps.map((step, colIndex) => (
                      <div key={`${step.id}-${colIndex}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ ...styles.dropZone, ...(dragOverIndex?.rowIndex === rowIndex && dragOverIndex?.colIndex === colIndex ? styles.dragOver : {}) }}
                          onDragOver={(e) => handleDragOver(e, rowIndex, colIndex)}
                          onDragLeave={handleDragLeave}
                          onDrop={(e) => handleDrop(e, rowIndex, colIndex)}
                          onTouchEnd={(e) => handleTouchEnd(e, rowIndex, colIndex)}
                        />

                        <div draggable="true"
                          onDragStart={(e) => handleDragStart(e, step, true)}
                          onDragEnd={handleDragEnd}
                          onTouchStart={(e) => handleTouchStart(e, step, true)}
                          onTouchEnd={handleTouchEnd}
                          onMouseEnter={() => setHoveredStep(step.id)}
                          onMouseLeave={() => setHoveredStep(null)}
                          style={{
                            ...styles.step,
                            ...(hoveredStep === step.id ? styles.stepHover : {}),
                            ...(draggedItem?.id === step.id ? styles.dragging : {})
                          }}
                        >
                          <ColorChartThumbnail data={step.data} mincols={24} size={2} colors={colors ? colors : step.colors} norepeat={step.norepeat} />
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Button icon="arrow-up" minimal small onClick={() => movePartUp(rowIndex, colIndex)} />
                            <Button icon="cross" minimal small onClick={() => removePart(rowIndex, colIndex)} />
                            <Button icon="arrow-down" minimal small onClick={() => movePartDown(rowIndex, colIndex)} />
                            <Button icon="arrow-left" minimal small onClick={() => movePartLeft(rowIndex, colIndex)} />
                            <Button icon="arrow-right" minimal small onClick={() => movePartRight(rowIndex, colIndex)} />
                          </div>
                        </div>
                      </div>
                    ))}
                    <div style={{ ...styles.finalDropZone, ...(dragOverIndex?.rowIndex === rowIndex && dragOverIndex?.colIndex === row.steps.length ? styles.dragOver : {}) }}
                      onDragOver={(e) => handleDragOver(e, rowIndex, row.steps.length)}
                      onDragLeave={handleDragLeave}
                      onDrop={(e) => handleDrop(e, rowIndex, row.steps.length)}
                      onTouchEnd={(e) => handleTouchEnd(e, rowIndex, row.steps.length)}
                    >
                      Drop here to add motif
                    </div>
                  </div>
                </div>
              ))}
              <div style={{ ...styles.finalDropZone, ...(dragOverIndex?.rowIndex === layoutSteps.length ? styles.dragOver : {}) }}
                onDragOver={(e) => handleDragOver(e, layoutSteps.length, 0)}
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, layoutSteps.length, 0)}
                onTouchEnd={(e) => handleTouchEnd(e, layoutSteps.length, 0)}
              >
                Drop here to add new row
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    gap: 8,
  },
  panel: {
    backgroundColor: '#f3f4f6',
    padding: 8,
    borderRadius: '0.375rem'
  },
  availableStepsPanel: {
    width: 180
  },
  layoutPanel: {
    flex: 1,
    minHeight: '24rem'
  },
  heading: {
    fontSize: '1.125rem',
    fontWeight: '600',
    marginBottom: '1rem'
  },
  stepContainer: {
    marginBottom: '0.5rem'
  },
  step: {
    backgroundColor: 'white',
    cursor: 'move',
    transition: 'background-color 0.2s',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0
  },
  availableStep: {
    padding: 12,
    backgroundColor: 'white',
    borderRadius: '0.375rem',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
    cursor: 'move',
    transition: 'background-color 0.2s',
    display: 'flex',
    justifyContent: 'space-between',
  },
  stepHover: {
    backgroundColor: '#f9fafb'
  },
  dragging: {
    opacity: 0.5,
    backgroundColor: '#f9fafb'
  },
  dragOver: {
    backgroundColor: Colors.GREEN5
  },
  dropZone: {
    height: 10,
    width: '100%'
  },
  finalDropZone: {
    height: '6rem',
    border: '2px dashed #d1d5db',
    borderRadius: '0.375rem',
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#9ca3af'
  }
};
