import { renderShapeSvg } from '../Charts/shapesSvg';

export default function ColorwayLegend({ colorway }) {

    return colorway && (
        <div>
            <div style={styles.title}>KEY</div>
            <p>Pattern Colors are in <b>BOLD</b></p>
            <table cellPadding={2}>
                <tbody>
                    {colorway.chromas && colorway.chromas.map((chroma, index) => (
                        <tr key={index}>
                            <td>
                                <div style={{ ...styles.colorsquare, backgroundColor: chroma.color }}>
                                <svg width="10" height="10" viewBox="0 0 10 10" style={{ display: 'block', margin: 'auto', transform: 'translate(0, 4px)' }}>
                                    {renderShapeSvg(chroma.shape, chroma.color, chroma.isSolid)}
                                </svg>
                                </div>
                            </td>
                            <td style={{ ...styles.id, fontWeight: chroma.isPattern ? 'bold' : ''}}>{chroma.id}</td>
                            <td>{(chroma.name || chroma.code) ? <span style={chroma.isPattern ? styles.bold : {}}>
                                {chroma.code} {chroma.name} </span> : <span style={styles.blank}>&nbsp;</span>}
                                &nbsp;
                                <i>{chroma.note && `(${chroma.note})`}</i>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const styles = {
    title: {
        fontSize: 20,
        fontWeight: '500',
        marginBottom: 8,
    },
    colorsquare: {
        width: 18,
        height: 18,
        margin: '0 auto',
        border: '1px solid black',
    },
    id: {
        textAlign: 'center',
        paddingRight: 8,
        paddingLeft: 8,
        fontSize: 16,
    },
    bold: {
        fontWeight: 'bold',
    },
    blank: {
        display: 'inline-block',
        borderBottom: '1px solid black',
        width: 200,
    }
}
