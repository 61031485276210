import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import { useGetItemsQuery } from '../store/docApi';
import { formatDateTime } from '../utils';
import { appstyles } from '../styles/appstyles';

export default function Notes() {
    const navigate = useNavigate();
    const { data } = useGetItemsQuery({ container: 'note', label: 'note' });
    const [notes, setNotes] = useState([]);

    useEffect(() => {
        if (data) {
            let items = [...data]
            items.sort((p1, p2) => { return p2._ts - p1._ts });
            setNotes(items);
        }
    }, [data]);

    return <div style={appstyles.content}>
        <div style={appstyles.titleWithButtons}>
            <h2>Notes</h2>
            <Button text="New Note" onClick={() => navigate('/note/create')} />
        </div>
        {notes && notes.map((note, index) => (
            <div key={index} className="note">
                <div><b>{note.name}</b></div>
                <div><b>{formatDateTime(note.startDate)}</b></div>
                <div>{note.id}</div>
                <div><i>{note.note}</i></div>
                <Button minimal small intent='primary' text="Details" onClick={() => navigate(`/note/${note.pk}/${note.id}`)} />
                <hr />
            </div>
        ))}
    </div>
};
