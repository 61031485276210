import React from 'react';
import tinycolor from "tinycolor2";

export const drawCircleSvg = (x, y, size, strokeColor, fillColor = "none") => (
  <circle cx={x} cy={y} r={size / 2} stroke={strokeColor} fill={fillColor} />
);

export const drawSquareSvg = (x, y, size, strokeColor, fillColor = "none") => (
  <rect x={x - size / 2} y={y - size / 2} width={size} height={size} stroke={strokeColor} fill={fillColor} />
);

export const drawTriangleSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const points = [
    `${x},${y - size / 2}`,
    `${x + size / 2},${y + size / 2}`,
    `${x - size / 2},${y + size / 2}`
  ].join(' ');
  return <polygon points={points} stroke={strokeColor} fill={fillColor} />;
};

export const drawStarSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const outerRadius = size / 2;
  const innerRadius = outerRadius / 2.5;
  const points = 5;
  const starPoints = [];
  for (let i = 0; i < points; i++) {
    const angle = (i * 2 * Math.PI) / points - Math.PI / 2;
    const outerX = x + outerRadius * Math.cos(angle);
    const outerY = y + outerRadius * Math.sin(angle);
    starPoints.push(`${outerX},${outerY}`);
    const innerAngle = angle + Math.PI / points;
    const innerX = x + innerRadius * Math.cos(innerAngle);
    const innerY = y + innerRadius * Math.sin(innerAngle);
    starPoints.push(`${innerX},${innerY}`);
  }
  return <polygon points={starPoints.join(' ')} stroke={strokeColor} fill={fillColor} />;
};

export const drawDiamondSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const points = [
    `${x},${y - size / 2}`,
    `${x + size / 2},${y}`,
    `${x},${y + size / 2}`,
    `${x - size / 2},${y}`
  ].join(' ');
  return <polygon points={points} stroke={strokeColor} fill={fillColor} />;
};

export const drawXSvg = (x, y, size, strokeColor, fillColor = "none") => (
  <>
    <line x1={x - size / 2} y1={y - size / 2} x2={x + size / 2} y2={y + size / 2} stroke={strokeColor} />
    <line x1={x + size / 2} y1={y - size / 2} x2={x - size / 2} y2={y + size / 2} stroke={strokeColor} />
  </>
);

export const drawHexagonSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const points = Array.from({ length: 6 }, (_, i) => {
    const angle = (i * 2 * Math.PI) / 6 - Math.PI / 2;
    const px = x + (size / 2) * Math.cos(angle);
    const py = y + (size / 2) * Math.sin(angle);
    return `${px},${py}`;
  }).join(' ');
  return <polygon points={points} stroke={strokeColor} fill={fillColor} />;
};

export const drawPentagonSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const points = Array.from({ length: 5 }, (_, i) => {
    const angle = (i * 2 * Math.PI) / 5 - Math.PI / 2;
    const px = x + (size / 2) * Math.cos(angle);
    const py = y + (size / 2) * Math.sin(angle);
    return `${px},${py}`;
  }).join(' ');
  return <polygon points={points} stroke={strokeColor} fill={fillColor} />;
};

export const drawCrossSvg = (x, y, size, strokeColor, fillColor = "none") => (
  <>
    <rect x={x - size / 10} y={y - size / 2} width={size / 5} height={size} stroke={strokeColor} fill={fillColor} />
    <rect x={x - size / 2} y={y - size / 10} width={size} height={size / 5} stroke={strokeColor} fill={fillColor} />
  </>
);

export const drawEllipseSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const width = size;
  const height = size / 1.5;
  return <ellipse cx={x} cy={y} rx={width / 2} ry={height / 2} stroke={strokeColor} fill={fillColor} />;
};

export const drawHeartSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const path = `
    M ${x},${y + size / 4}
    C ${x - size / 2},${y - size / 2} ${x - size},${y + size / 2} ${x},${y + size}
    C ${x + size},${y + size / 2} ${x + size / 2},${y - size / 2} ${x},${y + size / 4}
  `;
  return <path d={path} stroke={strokeColor} fill={fillColor} />;
};

export const drawParallelogramRightSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const offset = size / 4;
  const points = [
    `${x - size / 2 + offset},${y - size / 2}`,
    `${x + size / 2 + offset},${y - size / 2}`,
    `${x + size / 2 - offset},${y + size / 2}`,
    `${x - size / 2 - offset},${y + size / 2}`
  ].join(' ');
  return <polygon points={points} stroke={strokeColor} fill={fillColor} />;
};


export const drawParallelogramLeftSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const offset = size / 4;
  const points = [
    `${x - size / 2 - offset},${y - size / 2}`,
    `${x + size / 2 - offset},${y - size / 2}`,
    `${x + size / 2 + offset},${y + size / 2}`,
    `${x - size / 2 + offset},${y + size / 2}`
  ].join(' ');
  return <polygon points={points} stroke={strokeColor} fill={fillColor} />;
};

export const drawOctagonSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const points = Array.from({ length: 8 }, (_, i) => {
    const angle = (i * 2 * Math.PI) / 8 - Math.PI / 8;
    const px = x + (size / 2) * Math.cos(angle);
    const py = y + (size / 2) * Math.sin(angle);
    return `${px},${py}`;
  }).join(' ');
  return <polygon points={points} stroke={strokeColor} fill={fillColor} />;
};

export const drawArrowSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const points = [
    `${x - size / 2},${y}`,
    `${x},${y - size / 2}`,
    `${x},${y - size / 4}`,
    `${x + size / 2},${y - size / 4}`,
    `${x + size / 2},${y + size / 4}`,
    `${x},${y + size / 4}`,
    `${x},${y + size / 2}`
  ].join(' ');
  return <polygon points={points} stroke={strokeColor} fill={fillColor} />;
};

export const drawChevronRightSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const points = [
    `${x - size / 2},${y - size / 2}`, // Top-left
    `${x},${y}`,                      // Center
    `${x - size / 2},${y + size / 2}`  // Bottom-left
  ].join(' ');
  return <polygon points={points} stroke={strokeColor} fill={fillColor} />;
};

export const drawChevronLeftSvg = (x, y, size, strokeColor, fillColor = "none") => {
  const points = [
    `${x + size / 2},${y - size / 2}`, // Top-right
    `${x},${y}`,                      // Center
    `${x + size / 2},${y + size / 2}`  // Bottom-right
  ].join(' ');
  return <polygon points={points} stroke={strokeColor} fill={fillColor} />;
};

export const shapeOptions = [
  { label: '', value: '', draw: null },
  { label: 'Circle', value: 'circle', draw: drawCircleSvg },
  { label: 'Square', value: 'square', draw: drawSquareSvg },
  { label: 'Triangle', value: 'triangle', draw: drawTriangleSvg },
  { label: 'Star', value: 'star', draw: drawStarSvg },
  { label: 'Diamond', value: 'diamond', draw: drawDiamondSvg },
  { label: 'X', value: 'x', draw: drawXSvg },
  { label: 'Hexagon', value: 'hexagon', draw: drawHexagonSvg },
  { label: 'Pentagon', value: 'pentagon', draw: drawPentagonSvg },
  { label: 'Cross', value: 'cross', draw: drawCrossSvg },
  { label: 'Ellipse', value: 'ellipse', draw: drawEllipseSvg },
  { label: 'Heart', value: 'heart', draw: drawHeartSvg },
  { label: 'Parallelogram Right', value: 'parallelogram-right', draw: drawParallelogramRightSvg },
  { label: 'Parallelogram Left', value: 'parallelogram-left', draw: drawParallelogramLeftSvg },
  { label: 'Octagon', value: 'octagon', draw: drawOctagonSvg },
  { label: 'Arrow', value: 'arrow', draw: drawArrowSvg },
  { label: 'Chevron Right', value: 'chevronRight', draw: drawChevronRightSvg },
  { label: 'Chevron Left', value: 'chevronLeft', draw: drawChevronLeftSvg },
];

export function drawShapeSvg(shape, x, y, size, strokeColor, fillColor) {
  const option = shapeOptions.find(option => option.value === shape);
  return option ? option.draw(x, y, size, strokeColor, fillColor) : null;
}

export function getShapeColor(color) {
  if (!color) return "#f3f3f3";
  const tc = tinycolor(color);
  return tc.isLight() ? 'black' : 'white';
}

export const renderShapeSvg = (shape, shapeColor, isSolid) => {
  const drawFunction = shapeOptions.find(option => option.value === shape)?.draw;
  if (!drawFunction) return null;

  shapeColor = getShapeColor(shapeColor);
  let fillColor = isSolid ? shapeColor : "none";
  return drawFunction(5, 5, 6, shapeColor, fillColor);
};



