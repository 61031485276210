import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardList, Colors, ControlGroup, HTMLSelect, Icon, InputGroup, Label } from '@blueprintjs/core';
import { useGetItemQuery, useUpdatePropertyMutation } from '../store/docApi';
import { Spinner } from '@blueprintjs/core';
import { appstyles } from '../styles/appstyles';
import { generateUniqueId } from '../utils';

const ReferenceDetails = () => {
    const params = useParams();
    const id = params.id;
    const pk = params.pk;
    const { data: item, isLoading } = useGetItemQuery({ container: 'reference', label: 'reference', pk: pk, id: id });
    const [updateProperty] = useUpdatePropertyMutation();

    const [editingReference, setEditingReference] = useState(null);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [sortedReferences, setSortedReferences] = useState([]);
    const [sortedBy, setSortedBy] = useState('name');

    useEffect(() => {
        if (item) {
            console.log("SORTING BY", sortedBy);
            let _newsteps = item && item.steps ? JSON.parse(JSON.stringify(item.steps)) : [];
            _newsteps.sort((a, b) => {
                if (a[sortedBy] < b[sortedBy]) return -1;
                if (a[sortedBy] > b[sortedBy]) return 1;
                return 0;
            });
            setSortedReferences(_newsteps);
        }
    }, [item, sortedBy]);

    const formatReferencesHtml = (references) => {
        if (!references) return '';
        let refs = '';
        let _newrefs = references && references ? JSON.parse(JSON.stringify(references)) : [];
        refs += "<h3>Books</h3>";
        _newrefs.sort((r1, r2) => { return r1.author.localeCompare(r2.author) });
        for (const ref of _newrefs) {
            if (ref.type !== 'book') continue;
            refs += formatCitationHtml(ref);
            refs += "<br/>";
        }
        refs += "<h3>Articles</h3>";
        for (const ref of _newrefs) {
            if (ref.type !== 'article') continue;
            refs += formatCitationHtml(ref);
            refs += "<br/>";
        }
        _newrefs.sort((r1, r2) => { return r1.title.localeCompare(r2.title) });
        refs += "<h3>Websites</h3>";
        for (const ref of _newrefs) {
            if (ref.type !== 'website') continue;
            refs += formatCitationHtml(ref);
            refs += "<br/>";
        }
        refs += "<h3>Videos</h3>";
        for (const ref of _newrefs) {
            if (ref.type !== 'youtube') continue;
            refs += formatCitationHtml(ref);
            refs += "<br/>";
        }
        return refs;
    };

    const formatCitationHtml = (ref) => {
        return "<span>" +
            (ref.author ? "<span>" + ref.author + ". </span>" : "") +
            (ref.subtitle ? "<span>" + ref.subtitle + ". </span>" : "") +
            (ref.title ? "<i>" + ref.title + ". </i>" : "") +
            (ref.publisher ? "<span>" + ref.publisher + ", </span>" : "") +
            (ref.when ? "<span>" + ref.when + " </span>" : "") +
            (ref.pages ? "<span>pp: " + ref.pages + " </span>" : "") +
            (ref.url ? "<span><a href='" + ref.url + "'>" + ref.url + "</a> </span>" : "") +
            (ref.timestamp ? "<span>Timestamp: " + ref.timestamp + "</span>" : "") +
            "</span>";
    };

    const handlePrint = () => {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(`
           <html><head><title>${item.name}</title>
           <style>
             @page {
               margin: 0
             }
             body {
               font-family: "Times New Roman", Times, serif;
               font-size: 12pt;
               margin: 1in;
             }
             h3 {
               margin-bottom: 10px;
             }
             p {
               font-size: 12pt;
             }
           </style>  
           </head>
           <body>
               <h1>${item.name || ''}</h1>
               <p>${formatReferencesHtml(sortedReferences)}</p>
               <br/><br/>
           </body>
           </html>`);
        printWindow.document.close();
        printWindow.print();
    };

    const ReferenceForm = ({ reference, onSave, onCancel }) => {
        const [formData, setFormData] = useState(reference || {
            name: '',
            description: '',
            type: 'book',
            author: '',
            title: '',
            subtitle: '',
            publisher: '',
            when: '',
            pages: '',
            url: '',
            timestamp: ''
        });

        const handleSubmit = (e) => {
            e.preventDefault();
            onSave(formData);
        };

        return (
            <form onSubmit={handleSubmit}>
                <Label>Name (e.g. Name of the technique)
                    <InputGroup value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                </Label>
                <Label>Description (e.g. Description of the technique)
                    <InputGroup value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                </Label>
                <Label>Type
                    <HTMLSelect value={formData.type} onChange={(e) => setFormData({ ...formData, type: e.target.value })}>
                        <option value="book">Book</option>
                        <option value="article">Article</option>
                        <option value="website">Website</option>
                        <option value="youtube">YouTube</option>
                    </HTMLSelect>
                </Label>
                <Label>Author (Last name, first name)
                    <InputGroup value={formData.author} onChange={(e) => setFormData({ ...formData, author: e.target.value })} />
                </Label>
                <Label>Subtitle (e.g. Article name)
                    <InputGroup value={formData.subtitle} onChange={(e) => setFormData({ ...formData, subtitle: e.target.value })} />
                </Label>
                <Label>Title (e.g. book or webpage)
                    <InputGroup value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
                </Label>
                <ControlGroup fill>
                    <Label>Publisher
                        <InputGroup value={formData.publisher} onChange={(e) => setFormData({ ...formData, publisher: e.target.value })} />
                    </Label>
                    <Label>When (e.g. Year or Volume / Date)
                        <InputGroup value={formData.when} onChange={(e) => setFormData({ ...formData, when: e.target.value })} />
                    </Label>
                </ControlGroup>
                <Label>URL
                    <InputGroup value={formData.url} onChange={(e) => setFormData({ ...formData, url: e.target.value })} />
                </Label>
                <ControlGroup fill>
                    <Label>Pages
                        <InputGroup value={formData.pages} onChange={(e) => setFormData({ ...formData, pages: e.target.value })} />
                    </Label>
                    <Label>Timestamp
                        <InputGroup value={formData.timestamp} onChange={(e) => setFormData({ ...formData, timestamp: e.target.value })} />
                    </Label>
                </ControlGroup>
                <br />
                <div style={appstyles.tabButtonGroup}>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel} text="Cancel" />
                </div>
            </form>
        );
    };

    const handleEdit = (reference) => {
        setEditingReference(reference);
        setIsAddingNew(false);
    };

    const handleSave = (updatedReference) => {
        let _newsteps = item.steps ? JSON.parse(JSON.stringify(item.steps)) : [];
        if (isAddingNew) {
            const newReference = { ...updatedReference, id: generateUniqueId(6) };
            _newsteps.push(newReference);
        } else {
            _newsteps = _newsteps.map(ref => ref.id === editingReference.id ? { ...updatedReference, id: ref.id } : ref);
        }
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'steps', value: _newsteps });
        setEditingReference(null);
        setIsAddingNew(false);
    };

    const handleAddNew = () => {
        setIsAddingNew(true);
        setEditingReference(null);
    };

    const handleDelete = (reference) => {
        const _newsteps = item.steps.filter((c) => c.id !== reference.id);
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'steps', value: _newsteps });
    };

    const formatCitation = (citation) => {
        return <span>
            {citation.author && <span>{citation.author}. </span>}
            {citation.subtitle && <span>{citation.subtitle}. </span>}
            {citation.title && <i>{citation.title}. </i>}
            {citation.publisher && <span>{citation.publisher}, </span>}
            {citation.when && <span>{citation.when} </span>}
            {citation.pages && <span>pp: {citation.pages} </span>}
            {citation.url && <span><a href={citation.url} target="_blank" rel="noreferrer">{citation.url}</a> </span>}
            {citation.timestamp && <span>Timestamp: {citation.timestamp}</span>}
        </span>
    };

    const getIcon = (type) => {
        switch (type) {
            case 'book':
                return 'manual';
            case 'article':
                return 'document';
            case 'website':
                return 'globe';
            case 'youtube':
                return 'video';
            default:
                return 'blank';
        }
    };

    return isLoading ? <Spinner /> : (
        <div style={appstyles.content}>
            <div style={appstyles.titleWithButtons}>
                <h2>{item.name}</h2>
            </div>
            <Button onClick={handlePrint} text="Print" />
            <div><Label>Sort by:
                <HTMLSelect value={sortedBy} onChange={(e) => setSortedBy(e.target.value)}>
                    <option value="name">Name</option>
                    <option value="type">Type</option>
                    <option value="author">Author</option>
                    <option value="title">Title</option>
                </HTMLSelect>
            </Label>
            </div>
            <CardList>
                {sortedReferences.map((step, i) => (
                    <Card key={i} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            {step.name && <div><b>{step.name}</b><br /> </div>}
                            {step.description && <div>{step.description}<br /> </div>}
                            <div>
                                <Icon icon={getIcon(step.type)} style={{ color: Colors.DARK_GRAY3, paddingRight: 8 }} />
                                {formatCitation(step)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <Button icon="edit" minimal onClick={() => handleEdit(step)} />
                            <Button icon="trash" minimal onClick={() => handleDelete(step)} />
                        </div>

                    </Card>
                ))}
            </CardList>
            <br />
            <Button onClick={handleAddNew} text="Add" />
            <br /><br />
            {(isAddingNew || editingReference) && (
                <Card>
                    <ReferenceForm
                        reference={editingReference}
                        onSave={handleSave}
                        onCancel={() => {
                            setEditingReference(null);
                            setIsAddingNew(false);
                        }}
                    />
                </Card>
            )}
        </div>
    );
};

export default ReferenceDetails;
