import { useNavigate } from 'react-router-dom';
import { useGetGlobalItemsQuery } from '../store/docApi';
import { appstyles } from '../styles/appstyles';
import { Card } from '@blueprintjs/core';

const ContentPage = () => {
    const navigate = useNavigate();
    const { data: items, error, isLoading } = useGetGlobalItemsQuery({ label: "yarn" });

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading yarn</div>;

    return (
        <div style={appstyles.content}>
            <h2>Yarn</h2>
            <div style={appstyles.tileGrid}>
                {items.map(item => (
                    <Card key={item.id} style={appstyles.tile} interactive={true}
                        onClick={() => navigate(`/resources/${item.label}/${item.pk}/${item.id}`)}>
                        {item.picture && <img style={appstyles.tileImg} src={item.picture} alt={item.name} />}
                        <b>{item.name}</b>
                        <p>{item.label}</p>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default ContentPage;