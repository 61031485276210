import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Alert, Button, Spinner, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { useGetItemQuery, useDeleteItemMutation } from '../store/docApi';
import Picture from '../Widgets/Picture';
import ItemMetadata from '../Widgets/ItemMetadata';
import FileUpload from '../Widgets/FileUpload';
import { appstyles } from '../styles/appstyles';
import Layouts from '../Layout/Layouts';
import Motif from '../Design/Motifs';
import PublishItem from '../Widgets/PublishItem';

export default function CollectionDetails() {
  let params = useParams();
  const navigate = useNavigate();
  const { data: item, error, isLoading } = useGetItemQuery({ label: "collection", pk: params.pk, id: params.id });
  const [deleteItem] = useDeleteItemMutation();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("motifs");

  function deleteCollection() {
    setDeleteConfirmOpen(false);
    deleteItem({ label: "collection", pk: item.pk, id: item.id });
    navigate("/collections");
  }

  const actionMenu = (
    <Menu>
      <MenuItem minimal icon='trash' onClick={() => setDeleteConfirmOpen(true)} text="Delete collection" />
    </Menu>
  );

  return error ? <span>Error {error.status}</span> :
    isLoading ? (<Spinner style={appstyles.spinner}>Loading...</Spinner>) :
      <div style={appstyles.content}>
        <h2>{item.name}</h2>
        <div style={appstyles.tabContainer}>
          <div style={appstyles.tabButtonGroup}>
            <Button minimal style={selectedTab === "motifs" ? appstyles.activeTabButton : appstyles.tabButton}
              onClick={() => setSelectedTab("motifs")}>Motifs</Button>
            <Button minimal style={selectedTab === "layouts" ? appstyles.activeTabButton : appstyles.tabButton}
              onClick={() => setSelectedTab("layouts")}>Layouts</Button>
          </div>
          <div style={appstyles.tabButtonGroup}>
            <Popover content={actionMenu} position="bottom">
              <Button minimal icon="more" />
            </Popover>
            <PublishItem item={item} />
          </div>
        </div>
        <br />
        <Picture picture={item.picture} pictures={item.pictures} />
        {selectedTab === "motifs" && <Motif item={item} />}
        {selectedTab === "layouts" && <Layouts item={item} motifs={item.motifs} />}
        <br />
        <FileUpload folder={item.pk} item={item} />
        <ItemMetadata item={item} />
        <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
          isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
          onConfirm={deleteCollection}>
          <p>Delete this collection?</p>
        </Alert>
      </div>
}
