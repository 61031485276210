import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Callout, FormGroup, InputGroup } from '@blueprintjs/core';
import { useCreateItemMutation } from '../store/docApi';
import { generateNumericId } from '../utils';
import { appstyles } from '../styles/appstyles';

export default function PartCreate() {
  const [createItem, result] = useCreateItemMutation();
  const navigate = useNavigate();
  const params = useParams();
  console.log(params);

  const label = "part";
  const [id, setId] = useState(generateNumericId(8));
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  async function handleSubmit(event) {
    event.preventDefault();
    var newMotif = {
      label: label,
      id: id,
      name: name
    };
    if (params.pk && params.id) {
      newMotif.connections = [{ _id: `collection/${params.pk}/${params.id}` }];
    }
    if (description.trim().length !== 0)
      newMotif.description = description;
    await createItem({ data: newMotif });
  }

  useEffect(() => {
    if (result && result.isSuccess) {
      navigate(`/part/${result.data.pk}/${result.data.id}`);
    }
  }, [result, navigate]);


  function handleCancel() {
    navigate(-1);
  }

  return (
    <div style={appstyles.content}>
      {result && result.isError && <Callout intent="danger">Error creating</Callout>}
      {result && result.isSuccess && <Callout intent="success">Created successfully!</Callout>}
      <form onSubmit={handleSubmit}>
        <h3>Create Part</h3>
        <FormGroup label="Id">
          <InputGroup name="id" value={id} onChange={(e) => setId(e.target.value)} />
        </FormGroup>
        <FormGroup label="Name">
          <InputGroup name="name" value={name} onChange={(e) => setName(e.target.value)} />
        </FormGroup>
        <FormGroup label="Description">
          <InputGroup name="description" value={description} onChange={(e) => setDescription(e.target.value)} />
        </FormGroup>
        <Button type="submit" intent="primary">Submit</Button>
        &nbsp;&nbsp;&nbsp;
        <Button onClick={handleCancel}>Cancel</Button>
      </form>
    </div>
  );
}

