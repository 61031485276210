import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, InputGroup, Label, TextArea } from '@blueprintjs/core';
import { useGetItemQuery, useDeleteItemMutation, useUpdatePropertyMutation } from '../store/docApi';
import ItemMetadata from '../Widgets/ItemMetadata';
import { formatDateTime } from '../utils';
import TiptapEditor, { markdownToHtml } from './TipTapEditor';
import Markdown from '../Widgets/Markdown';
import TextViewer from './TextViewer';
import { appstyles } from '../styles/appstyles';
import { getReferencesHtml, NoteReferences } from './NoteReferences';

export default function NoteDetails() {
    const params = useParams();
    const id = params.id;
    const pk = params.pk;
    const { data: item } = useGetItemQuery({ container: 'note', label: 'note', pk: pk, id: id });
    const [deleteItem] = useDeleteItemMutation();
    const [updateProperty] = useUpdatePropertyMutation();
    const navigate = useNavigate();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [note, setNote] = useState();
    const [startDate, setStartDate] = useState();
    const [referenceId, setReferenceId] = useState();
    const { data: reference } = useGetItemQuery({ _id: referenceId }, { skip: referenceId === undefined });

    useEffect(() => {
        if (item) {
            setName(item.name);
            setDescription(item.description);
            setNote(item.note);
            setStartDate(item.startDate);
            // find a connection with a _type of 'reference and retrieve it
            const reference = item.connections.find(c => c._type === 'reference');
            if (reference) {
                setReferenceId(reference._id);
            }
        }
    }, [item]);

    function updateReferences({ newrefs }) {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'references', value: newrefs });        
    }
    function deleteConfirm() {
        setDeleteConfirmOpen(true);
    }

    function deleteNote() {
        setDeleteConfirmOpen(false);
        deleteItem({ label: "note", pk: item.pk, id: item.id });
        navigate("/notes");
    }

    const saveNote = () => {
        setIsEditing(false);
        if (name !== item.name)
            updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'name', value: name });
        if (description !== item.description)
            updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'description', value: description });
        if (note !== item.note)
            updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'note', value: note });
        if (startDate !== item.startDate)
            updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'startDate', value: startDate });
    };

    const handleChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handlePrint = () => {
        const printWindow = window.open("", "_blank");
        const htmltext = markdownToHtml(item.note);
        printWindow.document.write(`
        <html><head><title>${item.name}</title>
        <style>
          @page {
            margin: 0
          }
          body {
            font-family: "Times New Roman", Times, serif;
            font-size: 12pt;
            margin: 1in;
          }
          h3 {
            margin-bottom: 10px;
          }
          p {
            font-size: 12pt;
          }
        </style>  
        </head>
        <body>
            <h1>${item.name || ''}</h1>
            <h2>${item.description || ''}</h2>
            <p>${htmltext}</p>
            ${item.references && item.references.length > 0 ? '<h3>References</h3>' : ''}
            <p>${getReferencesHtml(item.references, reference)}</p>
        </body>
        </html>`);
        printWindow.document.close();
        printWindow.print();
    };

    return item && <div style={appstyles.content}>
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                <div>
                    <h1>{item.name}</h1>
                    <h2>{item.description}</h2>
                </div>
                {isEditing ?
                    <span>
                        <Button icon='tick' onClick={() => saveNote()} />
                        &nbsp;
                        <Button icon='cross' onClick={() => setIsEditing(false)} />
                    </span> :
                    <span>
                        <Button icon='edit' onClick={() => setIsEditing(true)} />
                        &nbsp;&nbsp;
                        <Button icon='print' onClick={() => handlePrint()} />
                        &nbsp;
                        <Button icon='trash' onClick={() => deleteConfirm()} />
                    </span>}
            </div>
            {isEditing ? <div>
                <Label>Name:</Label>
                <InputGroup type="text" value={name} onChange={handleChange(setName)} />
                <Label>Description:</Label>
                <InputGroup type="text" value={description} onChange={handleChange(setDescription)} />
                <Label>Date:</Label>
                <InputGroup type="date" value={startDate} onChange={handleChange(setStartDate)} />
                <Label>Note:</Label>
                <TextArea value={note} onChange={handleChange(setNote)} />
                <TiptapEditor content={note} setContent={setNote} />
            </div> : <div>
                <p>{formatDateTime(item.startDate)}</p>
                {item.connections && item.connections.map((connection, i) => (
                    <li key={i}>
                        ({connection._type})&nbsp;
                        <Link to={`/${connection._id}`}>{connection._name ? connection._name : connection._id}</Link>
                        {connection._step_id && <span> [{connection._step_id}]</span>}
                    </li>
                ))}
                <br />
                {false && <Markdown text={item.note} />}
                <br />
                <TextViewer content={item.note} />
            </div>}
        </div>
        <NoteReferences references={item.references} reference={reference} updateReferences={updateReferences} />
        <ItemMetadata item={item} />
        <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
            isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
            onConfirm={() => deleteNote()}>
            <p>Delete this note?</p>
        </Alert>
    </div>

};
