export const updateGrid = (data, loc, add) => {
    let newdata = data.map(innerArray => innerArray.slice());
    if (newdata.length === 0) {
      if (add) newdata.push([0]);
      return newdata;
    }
    let count = 1;
    switch (loc) {
      case 'top':
        if (add) newdata.unshift(Array(newdata[0].length).fill(0));
        else newdata.splice(0, count);
        break;
      case 'bottom':
        if (add) newdata.push(Array(newdata[0].length).fill(0));
        else newdata.splice(newdata.length - count, count);
        break;
      case 'left':
        for (let i = 0; i < newdata.length; i++) {
          if (add) newdata[i] = Array(count).fill(0).concat(newdata[i]);
          else newdata[i] = newdata[i].slice(count);
        }
        break;
      case 'right':
        for (let i = 0; i < newdata.length; i++) {
          if (add) newdata[i] = newdata[i].concat(Array(count).fill(0));
          else newdata[i] = newdata[i].slice(0, newdata[i].length - count);
        }
        break;
      default:
        return data;
    }
    return newdata;
  };
  