import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useGetItemsQuery, useGetItemQuery } from "../store/docApi";
import { useDeleteItemMutation } from "../store/docApi";
import { useUpdatePropertyMutation } from "../store/docApi";
import Picture from "../Widgets/Picture";
import Steps from "../components/Steps";
import KnitChart from "../Charts/KnitChart";
import ColorChart from "../Charts/ColorChart";
import Links from "../Widgets/Links";
import { Alert, Button, Dialog } from "@blueprintjs/core";
import ItemMetadata from "../Widgets/ItemMetadata";
import ColorChartCanvas from "../Charts/ColorChartCanvas";
import ColorChartThumbnail from "../Charts/ColorChartThumbnail";
import { _getItemId } from "../utils";
import { appstyles } from "../styles/appstyles";
import ColorwayList from "../Colorway/ColorwayList";
import ItemColors from "../Charts/ItemColors";

export default function MotifDetails() {
    let params = useParams();
    const { data: item } = useGetItemQuery({ container: 'design', label: 'motif', pk: params.pk, id: params.id });
    const { data: collections, error: collectionsError, isLoading: collectionsIsLoading } = useGetItemsQuery({ container: 'design', label: 'collection' })
    const [deleteItem] = useDeleteItemMutation();
    const [updateProperty] = useUpdatePropertyMutation();
    const navigate = useNavigate();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [addToCollectionOpen, setAddToCollectionOpen] = useState(false);
    const [colors, setColors] = useState(item && item.colors ? item.colors : ["#ffffff", "#d3d3d3", "#808080", "#3d3d3d"]);

    function deleteConfirm() {
        setDeleteConfirmOpen(true);
    }

    function deleteMotif() {
        setDeleteConfirmOpen(false);
        deleteItem({ pk: item.pk, id: item.id });
        navigate("/motifs");
    }

    function addToCollection(collection) {
        const _id = _getItemId(collection);
        if (item.connections && item.connections.find(c => c._id === _id)) {
            return;
        }
        let newConnections = item.connections ? [...item.connections] : [];
        newConnections.push({ _id: _id });
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'connections', value: newConnections });
        setAddToCollectionOpen(false);
    }

    function onSaveProperty(name, value) {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: name, value: value });
    }

    function updateColorways(colorways) {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'colorways', value: colorways });
    }   

    function onSelectColorway(colorway) {
        if (colorway.chromas && colorway.chromas.length > 0) {
            const colors = colorway.chromas.map(chroma => chroma.color);
            if (colors.length > 0) {
                setColors(colors);
            }
        }
    }

    return item &&
        <div style={appstyles.content}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: 'center'}}>
                {item.name && <h2>{item.name}</h2>}
                <span style={appstyles.tabButtonGroup}>
                    <Button icon='box' text="Add to Collection" onClick={()=>setAddToCollectionOpen(true)} />
                        &nbsp;
                    <Button icon='trash' onClick={()=>deleteConfirm()} />
                </span>
            </div>
            {item.author && <div>Designed by {item.author} </div>}
            {item.cols > 1 && <div>Multiple of {item.cols} sts {item.extracols && <span>plus {item.extracols}</span>}</div>}
            <Picture picture={item.picture} pictures={item.pictures} />
            {item.description}
            
            <ColorwayList colorways={item.colorways} setColorways={updateColorways} selectColorway={onSelectColorway} />
            <ItemColors item={item} />
            {item.colorchart && <ColorChart item={item} chartid={item.id} colors={colors} />}
            {item.colorchart && <ColorChartThumbnail data={item.data} colors={colors} mincols={96} />}
            {item.knitchart && item.steps && <div><br/><KnitChart steps={item.steps} item={item} chartid={item.id} /></div>}
            {item.steps && <Steps steps={item.steps} showVerbal={true} expandAll={true} />}
            <Links links={item.links} />
            {item.sources && item.sources.map((source, i) => {
                return <div key={i}>{source.id} {source.chapter} {source.page}</div>
            })}
            {item.connections && <h4>Collections</h4>}
            {item.connections && item.connections.map((connection, i) => {
                return <div key={i}>
                    <Link to={"/"+connection._id} onClick={()=>navigate("/"+connection._id)}>{connection._id}</Link>
                    <Button minimal icon='trash' onClick={()=>updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'connections', value: item.connections.filter((c, j) => j !== i) })} />
                </div>
            })}
            {item.isPartOf && item.isPartOf._id && <div>
                <b>Part of</b> <Link to={"/"+item.isPartOf._id}>{item.isPartOf._id}</Link>
            </div>}
            <ColorChartCanvas colors={colors} data={item.data} onSaveProperty={onSaveProperty} />
            <ItemMetadata item={item} />
            <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
                    isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
                    onConfirm={() => deleteMotif()}>
                    <p>Delete this motif?</p>
            </Alert>
            <Dialog isOpen={addToCollectionOpen} onClose={() => setAddToCollectionOpen(false)} title="Add to Collection" isCloseButtonShown={true} canOutsideClickClose={true}>
                <div style={{padding: "20px"}}>
                    <p>Choose a collection to add this motif to</p>
                    {collectionsError && <div>Error loading collections</div>}
                    {collectionsIsLoading && <div>Loading collections...</div>}
                    {collections && collections.map((collection, i) => {
                        return <div key={i} style={{ margin: 16 }}>
                            <Button icon='box' text={collection.name} onClick={()=>addToCollection(collection)} />
                        </div>
                    })}
                </div>
            </Dialog>
        </div>
}
