import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Callout, FormGroup, InputGroup } from '@blueprintjs/core';
import { useCreateItemMutation } from '../store/docApi';
import { generateNumericId } from '../utils';

export default function NoteCreate() {
  const [createItem, result] = useCreateItemMutation();
  const navigate = useNavigate();
  const params = useParams();
  console.log(params);

  const label = "note";
  const [pk, setPk] = useState(); // for testing
  const [id, setId] = useState(generateNumericId(8));
  const [name, setName] = useState(id);
  const [note, setNote] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    var newNote = {
      pk: pk,
      label: label,
      id: id,
      name: name,
      note: note,
      startDate: new Date().toISOString(),
    };
    if (params.pk && params.id) {
      newNote.connections = [{ _id: `collection/${params.pk}/${params.id}` }];
    }
    if (note.trim().length !== 0)
      newNote.note = note;
    await createItem({ label: "note", data: newNote });
  }

  useEffect(() => {
    if (result && result.isSuccess) {
      navigate(`/note/${result.data.pk}/${result.data.id}`);
    }
  }, [result, navigate]);


  function handleCancel() {
    navigate(-1);
  }

  return (
    <div style={styles.page}>
      {result && result.isError && <Callout intent="danger">Error creating</Callout>}
      {result && result.isSuccess && <Callout intent="success">Created successfully!</Callout>}
      <form onSubmit={handleSubmit}>
        <h3>Create note</h3>
        <FormGroup label="PK">
          <InputGroup name="pk" value={pk} onChange={(e) => setPk(e.target.value)} />
        </FormGroup>
        <FormGroup label="Id">
          <InputGroup name="id" value={id} onChange={(e) => setId(e.target.value)} />
        </FormGroup>
        <FormGroup label="Name">
          <InputGroup name="name" value={name} onChange={(e) => setName(e.target.value)} />
        </FormGroup>
        <FormGroup label="Note">
          <InputGroup name="note" value={note} onChange={(e) => setNote(e.target.value)} />
        </FormGroup>
        <Button type="submit" intent="primary">Submit</Button>
        &nbsp;&nbsp;&nbsp;
        <Button onClick={handleCancel}>Cancel</Button>
      </form>
    </div>
  );
}

const styles = {
  page: {
    maxWidth: 400
  }
};
