import { Button, Card } from '@blueprintjs/core';
import { appstyles } from '../styles/appstyles';
import { useState } from 'react';
import { useUpdatePropertyMutation } from '../store/docApi';

import LayoutDetails from './LayoutDetails';
import ColorChartThumbnail from '../Charts/ColorChartThumbnail';
//import LayoutMotifs from './LayoutMotifs';

export default function Layouts({ item }) {
    const [updateProperty] = useUpdatePropertyMutation();
    const [editLayoutIndex, setEditLayoutIndex] = useState();

    const addLayout = async () => {
        let layouts = item.layouts ? JSON.parse(JSON.stringify(item.layouts)) : [];
        layouts.push({ id: "layout" + layouts.length, name: "New Layout" });
        await updateProperty({ pk: item.pk, id: item.id, name: "layouts", value: layouts });
        setEditLayoutIndex(layouts.length - 1);
        //setSelectedTab("layouts");
    }

    const saveLayout = async (layout) => {
        let layouts = item.layouts ? JSON.parse(JSON.stringify(item.layouts)) : [];
        layouts[editLayoutIndex] = layout;
        await updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "layouts", value: layouts });
        setEditLayoutIndex();
    }

    const deleteLayout = async () => {
        let layouts = item.layouts ? JSON.parse(JSON.stringify(item.layouts)) : [];
        layouts.splice(editLayoutIndex, 1);
        await updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "layouts", value: layouts });
        setEditLayoutIndex();
    }

    const cancelEditLayout = () => {
        setEditLayoutIndex();
    }

    return <div>
        <h4>Layouts</h4>
        {editLayoutIndex === undefined && <div style={appstyles.tileGrid}>
            {item.layouts && item.layouts.map((layout, i) =>
                <Card key={i} style={appstyles.tile} interactive onClick={() => setEditLayoutIndex(i)}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h3>{layout.name}</h3>
                        {layout.steps && layout.steps.map((row, rowIndex) =>
                            <div key={rowIndex} style={{ display: 'flex', flexDirection: 'row' }}>
                                {row.steps && row.steps.map((step, colIndex) =>
                                    <ColorChartThumbnail key={colIndex} data={step.data} colors={layout.colors} />
                                )}
                            </div>
                        )}
                    </div>
                </Card>
            )}
        </div>}
        <br />
        <Button outlined icon="plus" onClick={() => addLayout()}>Add layout</Button>
        <br />
        <br/>
        {item.layouts && editLayoutIndex < item.layouts.length && <div>
            <LayoutDetails item={item} initialLayout={item.layouts[editLayoutIndex]}
                onSave={saveLayout} onDelete={deleteLayout} onCancel={cancelEditLayout} />
            {/*<br /> <hr /> <br />
            <LayoutMotifs item={item} layoutIndex={editLayoutIndex} isEdit={false} colors={item.colors} />*/}
        </div>}
    </div>
}

