import { _getItemId, _tsToDateTime } from '../utils';

export default function ItemMetadata({ item }) {
  return (
    <div style={{ marginTop: 24}}>
        <br/>
        <div style={styles.id}>{_getItemId(item)}</div>
        <div style={styles.id}>Last updated {_tsToDateTime(item._ts)}</div>
    </div>
  )
}

const styles = {
  id: { fontSize: 'small', color: 'grey', fontStyle: 'italic',  textAlign: 'right' }
}