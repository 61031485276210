import React, { useEffect, useState } from 'react';
import { Alert, Button, InputGroup, FormGroup, Dialog, Classes, HTMLSelect } from '@blueprintjs/core';
import { useGetGlobalItemsQuery } from '../store/docApi';
import { shapeOptions, renderShapeSvg } from '../Charts/shapesSvg';

export default function Colorway({ initialColorway, onSave, onDelete, onClose }) {
    const { data: yarns } = useGetGlobalItemsQuery({ label: 'yarn' });
    const [colorway, setColorway] = useState(initialColorway);
    const [availableChromas, setAvailableChromas] = useState([]);
    const [filteredChromas, setFilteredChromas] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isNewChromaDialogOpen, setIsNewChromaDialogOpen] = useState(false);
    const [newChroma, setNewChroma] = useState({
        id: '',
        color: '#000000',
        shape: '',
        isSolid: false,
        name: '',
        code: '',
        amount: '',
        note: '',
        isPattern: false
    });
    const [browsingChromaIndex, setBrowsingChromaIndex] = useState(null);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(true); // Tracks whether the table is in edit mode

    useEffect(() => {
        if (yarns && yarns.length > 0) {
            setAvailableChromas(yarns[0].chromas);
            setFilteredChromas(yarns[0].chromas);
        }
    }, [yarns]);

    useEffect(() => {
        setColorway(initialColorway);
    }, [initialColorway]);

    useEffect(() => {
        if (searchKeyword) {
            const filtered = availableChromas.filter(chroma =>
                chroma.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
                chroma.code.toLowerCase().includes(searchKeyword.toLowerCase())
            );
            setFilteredChromas(filtered);
        } else {
            setFilteredChromas(availableChromas);
        }
    }, [searchKeyword, availableChromas]);

    const handleSelectChroma = (chroma) => {
        if (browsingChromaIndex !== null) {
            // Update the chroma at the browsingChromaIndex
            const updatedChromas = colorway.chromas.map((c, i) =>
                i === browsingChromaIndex ? { ...c, color: chroma.color, code: chroma.code, name: chroma.name } : c
            );
            setColorway({ ...colorway, chromas: updatedChromas });
            setBrowsingChromaIndex(null); // Reset the browsing index
        } else {
            // If browsingChromaIndex is null, update the newChroma state
            setNewChroma({ ...newChroma, color: chroma.color, code: chroma.code, name: chroma.name });
        }
        setIsDialogOpen(false); // Close the dialog
    };

    const handleEditChroma = (index, updatedChroma) => {
        const updatedChromas = colorway.chromas.map((c, i) =>
            i === index ? { ...c, ...updatedChroma } : c
        );
        setColorway({ ...colorway, chromas: updatedChromas });
    };

    const handleAddNewChroma = () => {
        setColorway({ ...colorway, chromas: [...colorway.chromas, newChroma] });
        setNewChroma({ id: '', color: '#000000', shape: '', name: '', code: '', amount: '', note: '' });
        setIsNewChromaDialogOpen(false);
    };


    const handleSave = () => {
        onSave(colorway);
        setIsEditing(false);
    };

    return colorway && (
        <div>
            {isEditing ? (
                <>
                    <InputGroup
                        value={colorway.name || ''}
                        onChange={(e) => {
                            const newName = e.target.value;
                            setColorway({ ...colorway, name: newName }); // Update the name in the colorway object
                        }}
                        placeholder="Enter colorway name"
                        style={{ marginBottom: '10px' }}
                    />
                    <table className="bp4-html-table bp4-html-table-bordered bp4-html-table-condensed" style={{ width: '100%', marginTop: '10px' }}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Pattern?</th>
                                <th>Color</th>
                                <th>Shape</th>
                                <th>Solid?</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Note</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {colorway.chromas && colorway.chromas.map((chroma, index) => (
                                <tr key={index}>
                                    <td>
                                        <InputGroup
                                            value={chroma.id || ''}
                                            onChange={(e) => handleEditChroma(index, { id: e.target.value })}
                                            style={{ width: '40px' }}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={chroma.isPattern || false}
                                            onChange={(e) => handleEditChroma(index, { isPattern: e.target.checked })}
                                        />
                                    </td>
                                    <td><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ ...styles.colorsquare, backgroundColor: chroma.color }}>
                                            <svg width="10" height="10" viewBox="0 0 10 10" style={{ display: 'block', margin: 'auto', transform: 'translate(0, 4px)' }}>
                                                {renderShapeSvg(chroma.shape, chroma.color, chroma.isSolid)}
                                            </svg>
                                        </div>

                                        <input
                                            type="color"
                                            value={chroma.color}
                                            onChange={(e) => handleEditChroma(index, { color: e.target.value })}
                                            style={{ marginTop: '5px', width: '22px', height: '22px' }} // Match the smaller color square size
                                        />
                                    </div>
                                    </td>
                                    <td>
                                        <HTMLSelect
                                            value={chroma.shape || ''}
                                            onChange={(e) => handleEditChroma(index, { shape: e.target.value })}
                                            options={shapeOptions.map(option => ({ label: option.label, value: option.value }))}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={chroma.isSolid || false}
                                            onChange={(e) => handleEditChroma(index, { isSolid: e.target.checked })}
                                        />
                                    </td>

                                    <td>
                                        <InputGroup
                                            value={chroma.code || ''}
                                            onChange={(e) => handleEditChroma(index, { code: e.target.value })}
                                        />
                                    </td>
                                    <td>
                                        <InputGroup
                                            value={chroma.name || ''}
                                            onChange={(e) => handleEditChroma(index, { name: e.target.value })}
                                        />
                                    </td>
                                    <td>
                                        <InputGroup
                                            value={chroma.amount || ''}
                                            onChange={(e) => handleEditChroma(index, { amount: e.target.value })}
                                            style={{ width: '40px' }}
                                        />
                                    </td>
                                    <td>
                                        <InputGroup
                                            value={chroma.note || ''}
                                            onChange={(e) => handleEditChroma(index, { note: e.target.value })}
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            icon="search"
                                            minimal
                                            onClick={() => {
                                                setBrowsingChromaIndex(index);
                                                setIsDialogOpen(true);
                                            }}
                                        />
                                        <Button
                                            icon="trash"
                                            minimal
                                            onClick={() =>
                                                setColorway({
                                                    ...colorway,
                                                    chromas: colorway.chromas.filter((_, i) => i !== index),
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <br />
                    <Button outlined icon="add" text="Add Chroma" onClick={() => setIsNewChromaDialogOpen(true)} />
                    &nbsp;
                    <Button outlined icon="floppy-disk" text="Save" onClick={handleSave} />
                    &nbsp;
                    <Button outlined icon="trash" text="Delete" onClick={() => setDeleteConfirmOpen(true)} />
                    &nbsp;
                    <Button outlined icon="cross" text="Close" onClick={onClose} />
                </>
            ) : (
                <>
                    <p><b>{colorway.name}</b></p>
                    <table>
                        <tbody>
                            {colorway.chromas && colorway.chromas.map((chroma, index) => (
                                <tr key={index}>
                                    <td>
                                        <div style={{ width: 20, height: 20, backgroundColor: chroma.color, margin: '0 auto' }}>
                                            {renderShapeSvg(chroma.shape, chroma.color)}
                                        </div>
                                    </td>
                                    <td>{chroma.id}</td>
                                    <td>{chroma.code} {chroma.name}</td>
                                    <td>{chroma.amount && `(${chroma.amount})`}</td>
                                    <td><i>{chroma.note}</i></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <br />
                    <Button outlined icon="edit" text="Edit" onClick={() => setIsEditing(true)} />
                    &nbsp;
                    <Button outlined icon="trash" text="Delete" onClick={() => setDeleteConfirmOpen(true)} />
                    &nbsp;
                    <Button outlined icon="cross" text="Close" onClick={onClose} />
                </>
            )}

            {/* Dialog for Browsing Yarns */}
            <Dialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} title="Browse Yarns">
                <div className={Classes.DIALOG_BODY} style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <InputGroup
                        leftIcon="search"
                        placeholder="Search chromas..."
                        value={searchKeyword}
                        onChange={(e) => setSearchKeyword(e.target.value)}
                        style={{ marginBottom: '20px' }}
                    />
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px' }}>
                        {filteredChromas && filteredChromas.map((chroma, index) => (
                            <div key={index}
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => handleSelectChroma(chroma)}>
                                <div style={{ width: 20, height: 20, backgroundColor: chroma.color, marginRight: 10 }}></div>
                                <span>{chroma.code} {chroma.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </Dialog>

            {/* Dialog for Adding New Chroma */}
            <Dialog isOpen={isNewChromaDialogOpen} onClose={() => setIsNewChromaDialogOpen(false)} title="Add New Chroma">
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup label="ID">
                        <InputGroup value={newChroma.id} onChange={(e) => setNewChroma({ ...newChroma, id: e.target.value })} />
                    </FormGroup>
                    <FormGroup label="Color">
                        <input type="color" value={newChroma.color} onChange={(e) => setNewChroma({ ...newChroma, color: e.target.value })} />
                    </FormGroup>
                    <FormGroup label="Shape">
                        <HTMLSelect
                            value={newChroma.shape}
                            onChange={(e) => setNewChroma({ ...newChroma, shape: e.target.value })}
                            options={shapeOptions.map(option => ({ label: option.label, value: option.value }))}
                        />
                    </FormGroup>
                    <FormGroup label="Code">
                        <InputGroup value={newChroma.code} onChange={(e) => setNewChroma({ ...newChroma, code: e.target.value })} />
                    </FormGroup>
                    <FormGroup label="Name">
                        <InputGroup value={newChroma.name} onChange={(e) => setNewChroma({ ...newChroma, name: e.target.value })} />
                    </FormGroup>
                    <FormGroup label="Amount">
                        <InputGroup value={newChroma.amount} onChange={(e) => setNewChroma({ ...newChroma, amount: e.target.value })} />
                    </FormGroup>
                    <FormGroup label="Note">
                        <InputGroup value={newChroma.note} onChange={(e) => setNewChroma({ ...newChroma, note: e.target.value })} />
                    </FormGroup>
                    <FormGroup label="Pattern">
                        <input
                            type="checkbox"
                            checked={newChroma.isPattern}
                            onChange={(e) => setNewChroma({ ...newChroma, isPattern: e.target.checked })}
                        />
                    </FormGroup>
                    <Button icon="search" text="Browse Yarns" onClick={() => setIsDialogOpen(true)} />
                    &nbsp;
                    <Button icon="add" text="Add" onClick={handleAddNewChroma} />
                </div>
            </Dialog>

            {/* Alert for Delete Confirmation */}
            <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                icon="trash"
                intent="danger"
                isOpen={deleteConfirmOpen}
                onCancel={() => setDeleteConfirmOpen(false)}
                onConfirm={() => { setDeleteConfirmOpen(false); onDelete(); }}
            >
                <p>Delete this colorway {colorway.name}?</p>
            </Alert>
        </div>
    );
};

const styles = {
    colorsquare: {
        width: 18,
        height: 18,
        margin: '0 auto',
        border: '1px solid black',
    },
}
