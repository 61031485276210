import { useState } from "react";
import { EditableText, Button, ControlGroup, Label } from "@blueprintjs/core";
import { useUpdatePropertyMutation } from "../store/docApi";

export default function EditablePropery({ item, title, propname, propvalue, placeholder, multiline }) {
    const [updateProperty] = useUpdatePropertyMutation();
    const [value, setValue] = useState(propvalue);

    const handleCancel = () => {
        setValue(propvalue);
    }

    const handleSave = () => {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: propname, value: value });
    }

    return <ControlGroup>
        <Label><b>{title}</b><br />
            <EditableText value={value} onChange={(value) => setValue(value)} placeholder={placeholder} multiline={multiline} />
        </Label>
        {propvalue !== value && <Button minimal small icon="floppy-disk" onClick={() => handleSave()} />}
        {propvalue !== value && <Button minimal small icon="cross" onClick={() => handleCancel()} />}
    </ControlGroup>
}