const defaultColors = ["#ffffff", "#a9a9a9",	'#808080', '#696969' ];

export default function ColorChartThumbnail({ data, colors, minrows, mincols, size, norepeat }) {
  const rows = data ? data.length : 0;
  const cols = data && data[0] ? data[0].length : 0;
  const repeatrows = (!norepeat && rows && minrows && minrows > rows) ? Math.floor(minrows/rows) : 1;
  const repeatcols = (!norepeat && cols && mincols && mincols > cols) ? Math.floor(mincols/cols) : 1;

  function getCellColor(col) {
    if (colors && col < colors.length) {
      return colors[col];
    }
    else if (defaultColors && col < defaultColors.length)
      return defaultColors[col];
    else return 'black';
  }

  const cellsize = size && cols < 40 ? 4*size : 4;
  const cellstyle = {
    height: cellsize,
    width: cellsize,
    borderWidth: 0,
    borderColor: 'lightgrey',
    borderStyle: 'solid'
  };

  return data && (
    <div style={styles.chartContainer}>
      <div style={styles.horizontal}>
        <div style={styles.chart}>
          {Array(repeatrows).fill({}).map((_, x) => {
            return data.map((row, i) => (
              <div key={`${x}_${i}`} style={styles.row}>
                {Array(repeatcols).fill({}).map((_, y) => {
                  return row.map((col, j) => (
                    <div key={`${x}_${i}_${y}_${j}`} style={{ ...cellstyle, backgroundColor: getCellColor(col) }} />
                  ))
                })}
              </div>
            ))
          })}
        </div>
      </div>
    </div>
  )
}

const styles = {
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  chart: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start'
  },
}

