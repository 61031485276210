import React, { useEffect, useState } from 'react';
import { Button, InputGroup, FormGroup, Dialog, Classes, HTMLSelect } from '@blueprintjs/core';
import { useGetItemsQuery } from '../store/docApi';

const Colorway = ({ initialColorway, onSave }) => {
    const { data: yarns } = useGetItemsQuery({ label: "yarn" });
    const [colorway, setColorway] = useState(initialColorway);
    const [availableChromas, setAvailableChromas] = useState([]);
    const [newChroma, setNewChroma] = useState({ id: '', name: '', code: '', color: '#000000' });
    const [isCreating, setIsCreating] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editingChromaIndex, setEditingChromaIndex] = useState(null);

    useEffect(() => {
        if (yarns && yarns.length > 0) {
            setAvailableChromas(yarns[0].chromas);
        }
    }, [yarns]);

    useEffect(() => {
        setColorway(initialColorway);
    }, [initialColorway]);

    const handleAddChroma = () => {
        setColorway({ ...colorway, chromas: [...colorway.chromas, newChroma] });
        setNewChroma({ id: '', name: '', code: '', color: '#000000' });
        setIsCreating(false);
        setIsDialogOpen(false);
    };

    const handleSelectChroma = (chroma) => {
        let newchroma = { ...chroma };
        if (editingChromaIndex !== null) {
            const updatedChromas = [...colorway.chromas];
            // retain the id of the chroma being edited
            newchroma.id = updatedChromas[editingChromaIndex].id;
            newchroma.note = updatedChromas[editingChromaIndex].note;
            
            updatedChromas[editingChromaIndex] = newchroma;
            setColorway({ ...colorway, chromas: updatedChromas });
            setEditingChromaIndex(null);
        } else {
            setColorway({ ...colorway, chromas: [...colorway.chromas, newchroma] });
        }
        setIsDialogOpen(false);
    };

    const handleEditChroma = (index) => {
        setEditingChromaIndex(index);
        setIsDialogOpen(true);
    };

    return colorway && (
        <div>
            <b>{colorway.name}</b>
            {colorway.chromas && colorway.chromas.map((chroma, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ width: 20 }}>{chroma.id}</span>
                    <div style={{ width: 20, height: 20, backgroundColor: chroma.color, marginRight: 10 }}></div>
                    <input type="color" value={chroma.color} onChange={(e) => setColorway({ ...colorway, chromas: colorway.chromas.map((c) => c.id === chroma.id ? { ...c, color: e.target.value } : c) })} />
                    &nbsp;
                    <span> ({chroma.shade}) {chroma.name}</span>
                    {chroma.amount && <span>&nbsp;- {chroma.amount}</span>}
                    {chroma.note && <span>&nbsp; {chroma.note}</span>}
                    <Button icon="edit" minimal onClick={() => handleEditChroma(index)} />
                    <Button icon="trash" minimal onClick={() => setColorway({ ...colorway, chromas: colorway.chromas.filter((c) => c.id !== chroma.id) })} />
                </div>
            ))}
            <Button icon="add" text="Add" onClick={() => setIsDialogOpen(true)} />
            <Button icon="floppy-disk" text="Save" onClick={() => onSave(colorway)} />
            <Dialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)}
                title="Select or Create Chroma" >
                <div className={Classes.DIALOG_BODY} style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <HTMLSelect value="" onChange={(e) => console.log(e.target.value)}>
                        {yarns && yarns.map((yarn, index) => (
                            <option key={index} label={yarn.name} />
                        ))}
                    </HTMLSelect>
                    <br /><br />
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))', gap: '10px' }}>
                        {availableChromas && availableChromas.map((chroma, index) => (
                                chroma.amount && chroma.amount > 0 ? <div key={index}
                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                    onClick={() => handleSelectChroma(chroma)}>
                                    <div style={{ width: 20, height: 20, backgroundColor: chroma.color, marginRight: 10 }}></div>
                                    <span style={styles.colorname}>{chroma.shade} {chroma.name} ({chroma.amount})</span>
                                </div> : null
                        ))}
                    </div>
                    <hr />
                    <Button text="Close" onClick={() => setIsDialogOpen(false)} />
                    &nbsp;&nbsp;&nbsp;
                    <Button text="Create New" onClick={() => setIsCreating(true)} />
                    {isCreating && (
                        <div style={{ marginTop: 20 }}>
                            <FormGroup label="Id">
                                <InputGroup value={newChroma.id} onChange={(e) => setNewChroma({ ...newChroma, id: e.target.value })} />
                            </FormGroup>
                            <FormGroup label="Name">
                                <InputGroup value={newChroma.name} onChange={(e) => setNewChroma({ ...newChroma, name: e.target.value })} />
                            </FormGroup>
                            <FormGroup label="Code">
                                <InputGroup value={newChroma.code} onChange={(e) => setNewChroma({ ...newChroma, code: e.target.value })} />
                            </FormGroup>
                            <FormGroup label="Color">
                                <input type="color" value={newChroma.color} onChange={(e) => setNewChroma({ ...newChroma, color: e.target.value })} />
                            </FormGroup>
                            <Button icon="floppy-disk" text="Save" onClick={handleAddChroma} />
                        </div>
                    )}
                </div>
            </Dialog>
        </div>
    );
};

const styles = {
    colorname: {
        fontSize: 12,
    },
}

export default Colorway;