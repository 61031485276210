import { useState } from 'react';
import { Button, Callout, Card, Checkbox, ProgressBar, Radio, RadioGroup } from '@blueprintjs/core';
import { generateNumericId } from '../utils';
import { useUpdatePropertyMutation } from '../store/docApi';
import { useUploadImageMutation } from '../store/mediaApi';

const imgUrl = "https://m.stitchso.com/img";

export default function FileUpload({ folder, item, itemType }) {
  const [file, setFile] = useState();
  const [updateProperty] = useUpdatePropertyMutation();
  const [uploadImage, { isLoading: isUploading }] = useUploadImageMutation();
  const [filename, setFilename] = useState();
  const [uploadedFilename, setUploadedFilename] = useState();
  const [message, setMessage] = useState();
  const [transform, setTransform] = useState(false);
  const [size, setSize] = useState('medium');
  const [type, setType] = useState('webp');
  const [fileExt, setFileExt] = useState();

  function handleFileChange(e) {
    if (e.target.files) {
      let newfile = e.target.files[0];
      let tok = newfile.type.split("/");
      if (tok.length < 2) {
        setMessage("Invalid filename");
        return;
      }
      console.log(newfile.type)
      setFileExt(tok[tok.length - 1]);
      let newfilename = `${folder}/${item.id}/${generateNumericId(8)}`;
      setFilename(newfilename);
      setFile(newfile);
      setUploadedFilename();
    }
  };

  function clearInput() {
    setMessage();
    setFile();
    setFilename();
    setFileExt();
  }

  function handleUploadClick() {
    if (!file) {
      setMessage("Please select a file to upload")
      return;
    }
    setMessage();
    let ext = transform ? type : fileExt;
    let data = { file: file, filename: filename, ext: ext, transform: transform, size: size };
    uploadImage(data).unwrap().then((result) => {
      if (result && result.error) {
        setMessage("Error " + result.error.status);
      } else {
        // save the picture with the item
        let pictureFilename = `${imgUrl}/${filename}.${ext}`;
        setUploadedFilename(pictureFilename);
        let pictures = item.pictures ? [...item.pictures] : [];
        pictures.push(pictureFilename);
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "pictures", value: pictures })
        clearInput();
      }
    })
  };

  return (
    <Card>
      <p>Add Picture</p>
      {message && <Callout>{message}</Callout>}
      {isUploading && <div style={{ paddingBottom: 20 }}><ProgressBar intent="danger" /></div>}
      {file ? <div style={styles.fileInput}>{file.name} - {file.type}</div> :
        <input type="file" style={styles.fileInput} onChange={handleFileChange} accept="image/*" />}
      <br />
      <Checkbox checked={transform} onChange={(e) => setTransform(e.target.checked)}>Transform image</Checkbox>
      {transform &&
        <div>
          <RadioGroup inline label="Size" onChange={(e) => setSize(e.currentTarget.value)} selectedValue={size} >
            <Radio label="Small" value="small" />
            <Radio label="Medium" value="medium" />
            <Radio label="Original" value="original" />
          </RadioGroup>
          <RadioGroup inline label="Type" onChange={(e) => setType(e.currentTarget.value)} selectedValue={type} >
            <Radio label="png" value="png" />
            <Radio label="jpeg" value="jpeg" />
            <Radio label="webp" value="webp" />
          </RadioGroup>
        </div>
      }
      <Button intent="primary" onClick={() => handleUploadClick(true)} disabled={!file}>Upload</Button>&nbsp;
      <Button onClick={() => clearInput()} disabled={!file}>Clear</Button>
      <div>{filename}.{fileExt}</div>
      {uploadedFilename && <div>
        <br clear="all" />
        <br /><br />
        <img style={styles.image} src={uploadedFilename} alt={uploadedFilename} />
        <div>{uploadedFilename}</div>
      </div>
      }
    </Card>
  );
}

const styles = {
  image: {
    padding: 10,
    maxHeight: 600,
    maxWidth: 600
  },
  fileInput: {
    marginBottom: 5,
    border: '1px solid #999',
    padding: 5
  },
  control: {
    border: '1px solid #999',
    padding: 5
  }
}
