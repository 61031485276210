import { useState } from "react";
import { Card, HTMLSelect, Button, Label, InputGroup } from "@blueprintjs/core";
import { appstyles } from "../styles/appstyles";

const formatCitationHtml = (citation, reference) => {
    let ref = {};
    if (citation._id && reference && reference.steps) {
        ref = reference.steps.find(r => r.id === citation._id);
    }
    ref = { ...ref, ...citation };
    return "<span>" +
        //(ref.name ? "<span><b>" + ref.name + "</b>. <br/></span>" : "") +
        (ref.author ? "<span>" + ref.author + ". </span>" : "") +
        (ref.subtitle ? "<span>" + ref.subtitle + ". </span>" : "") +
        (ref.title ? "<i>" + ref.title + ". </i>" : "") +
        (ref.publisher ? "<span>" + ref.publisher + ", </span>" : "") +
        (ref.when ? "<span>" + ref.when + " </span>" : "") +
        (ref.pages ? "<span>pp: " + ref.pages + " </span>" : "") +
        (ref.url ? "<span><a href='" + ref.url + "'>" + ref.url + "</a> </span>" : "") +
        (ref.timestamp ? "<span>Timestamp: " + ref.timestamp + "</span>" : "") +
        "</span>";
};

export const getReferencesHtml = (references, reference) => {
    let refs = '';
    if (references) {
        let _newrefs = references ? JSON.parse(JSON.stringify(references)) : [];
        // group by name, then print each group
        _newrefs.sort((a, b) => a.name.localeCompare(b.name));
        let groupedReferences = _newrefs.reduce((acc, ref) => {
            if (!acc[ref.name]) {
                acc[ref.name] = [];
            }
            acc[ref.name].push(ref);
            return acc;
        }, {});

        const appendCitationHtml = (citation) => {
            refs += formatCitationHtml(citation, reference);
            refs += "<br/>";
        };

        for (const [name, citations] of Object.entries(groupedReferences)) {
            refs += "<b>" + name + "</b><br/>";
            citations.forEach(appendCitationHtml);
            refs += "<br/>";
        };
    }
    return refs;
};

export function NoteReferences({ references, referencesId, reference, updateReferences }) {
    const [refs, setRefs] = useState(references ? JSON.parse(JSON.stringify(references)) : []);
    const [isEditingReference, setIsEditingReference] = useState(false);
    const blankReference = { name: '', _id: '', pages: '' };
    const [editReference, setEditReference] = useState(blankReference);
    const [editReferenceIndex, setEditReferenceIndex] = useState();

    const formatCitation = (citation) => {
        let ref = {};
        if (citation._id && reference && reference.steps) {
            ref = reference.steps.find(r => r.id === citation._id);
        }
        ref = { ...ref, ...citation };
        return <span>
            {ref.name && <span><b>{ref.name}</b> <br /></span>}
            {ref.author && <span>{ref.author}. </span>}
            {ref.subtitle && <span>{ref.subtitle}. </span>}
            {ref.title && <i>{ref.title}. </i>}
            {ref.publisher && <span>{ref.publisher}, </span>}
            {ref.when && <span>{ref.when} </span>}
            {ref.pages && <span>pp: {ref.pages} </span>}
            {ref.url && <span><a href={ref.url} target="_blank" rel="noreferrer">{ref.url}</a> </span>}
            {ref.timestamp && <span>Timestamp: {ref.timestamp}</span>
            }
        </span>;
    }
    
    const handleSetEditReference = (citation, index) => {
        let ref = { ...blankReference, ...citation };
        setEditReference(ref);
        setEditReferenceIndex(index);
        setIsEditingReference(true);
    };

    const handleSave = () => {
        const _newref = {
            _id: editReference._id,
            name: editReference.name,
        }
        if (editReference.pages) _newref.pages = editReference.pages;

        let _newrefs = refs;
        if (editReferenceIndex !== undefined) {
            _newrefs[editReferenceIndex] = _newref;
        } else {
            _newrefs.push(editReference);
        }
        updateReferences({ id: referencesId, newrefs: _newrefs});
        setRefs(_newrefs);
        setEditReference(blankReference);
        setIsEditingReference(false);
    };

    const handleDelete = (index) => {
        let _newrefs = refs;
        _newrefs.splice(index, 1);
        updateReferences({ id: referencesId, newrefs: _newrefs});
    }

    const handleCancel = () => {
        setEditReference(blankReference);
        setIsEditingReference(false);
    }

    const updateReferenceSelection = (value) => {
        let ref = { ...editReference, _id: value };
        if (reference && reference.steps) {
            let _ref = reference.steps.find(r => r.id === value);
            if (_ref.name) ref.name = _ref.name;
            if (_ref.pages) ref.pages = _ref.pages;
        }
        setEditReference(ref);
    }

    return <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {refs && refs.length > 0 ? <b><br/>References</b> : <span />}
            <Button minimal text='Add Reference' intent='primary' onClick={() => setIsEditingReference(true)} />
        </div>
        {refs && refs.map((citation, i) => (
            <li key={i}>
                {formatCitation(citation)}
                <Button icon='edit' minimal onClick={() => handleSetEditReference(citation, i)} />
                <Button icon='cross' minimal onClick={() => handleDelete(i)} />
                <br />
            </li>
        ))}
        {refs && refs.length > 0 && <br />}
        
        {isEditingReference && <Card>
            <h3>Add Reference</h3>
            <Label>Name:
                <InputGroup type="text" value={editReference.name} onChange={(e) => setEditReference({ ...editReference, name: e.target.value })} />
            </Label>
            {reference && reference.steps ? <Label>_id
                <HTMLSelect value={editReference._id} onChange={(e) => updateReferenceSelection(e.target.value)} >
                    <option key='0' value=''>Select a reference</option>
                    {reference.steps.map(r => <option key={r.id} value={r.id}>
                        ({r.type && r.type.toUpperCase()[0]}) {r.author} - {r.title} - {r.publisher} - {r.when} ({r.id})
                    </option>)}
                </HTMLSelect>
            </Label> : <div><b>No references are available to select from!</b><br /><br /></div>}
            <Label>Pages:
                <InputGroup type="text" value={editReference.pages} onChange={(e) => setEditReference({ ...editReference, pages: e.target.value })} />
            </Label>
            <div style={appstyles.tabButtonGroup}>
                <Button small intent='primary' text='Save' onClick={handleSave} />
                <Button small text='Cancel' onClick={() => handleCancel()} />
            </div>
        </Card>}
        {false && reference && <div>
            <br />
            <hr />
            <h3>Available: {reference.name}</h3>
            {reference.steps && reference.steps.map((citation, i) => (
                <div key={i}>{formatCitation(citation)}</div>
            ))}
        </div>}

    </div>

}