import { Button, ControlGroup, Divider, FileInput, Label, Menu, MenuDivider, MenuItem, NumericInput, Popover, Position } from "@blueprintjs/core";
import React, { useCallback, useRef, useEffect, useState } from "react";
import { updateGrid } from "./gridUtils.js";
import { drawChart } from "./chartUtils.js";
import GridSettings from "./GridSettings.js";
import ColorPicker from "./ColorPicker.js";
import { appstyles } from "../styles/appstyles.js";

const defaultColors = ["#ffffff", "#a9a9a9", '#808080', '#696969'];

export default function ColorChartCanvas({ colors: initialColors, data: initialData, onSaveProperty, colorway }) {
  const canvasRef = useRef(null);
  const isDrawing = useRef(false); // Track whether the user is currently drawing
  const isSelecting = useRef(false); // Track whether the user is selecting an area
  const [colors, setColors] = useState(initialColors ? initialColors : defaultColors);
  const [data, setData] = useState(initialData ? initialData : [[0]]);
  const [rows, setRows] = useState(initialData ? initialData.length : 0);
  const [cols, setCols] = useState(initialData && initialData.length ? initialData[0].length : 0);
  const [currentColor, setCurrentColor] = useState(0); // Index of the selected color
  const [currentRow, setCurrentRow] = useState(-1); // Currently highlighted row
  const [clipboard, setClipboard] = useState(null); // Clipboard to store copied data
  const [selection, setSelection] = useState(null); // Current selection rectangle
  const [showFileImport, setShowFileImport] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [settings, setSettings] = useState({
    cellSize: 16,
    yscale: 1,
    thickLineEveryX: 5,
    thickLineEveryY: 5,
    showIcons: true
  });

  useEffect(() => {
    if (initialColors) {
      setColors(initialColors);
    }
  }, [initialColors]);

  // Copy the selected area or the whole chart
  const handleCopy = () => {
    if (selection) {
      const { startRow, startCol, endRow, endCol } = selection;
      const copiedData = data
        .slice(startRow, endRow + 1)
        .map((row) => row.slice(startCol, endCol + 1));
      setClipboard(copiedData);
      console.log("Selected area copied!");
    } else {
      setClipboard(data);
      console.log("Entire chart copied");
    }
  };

  // Cut the selected area: copy it to the clipboard and clear it from the chart
  const handleCut = () => {
    if (selection) {
      const { startRow, startCol, endRow, endCol } = selection;
      const copiedData = data
        .slice(startRow, endRow + 1)
        .map((row) => row.slice(startCol, endCol + 1));
      setClipboard(copiedData);

      // Clear the selection area in the chart
      setData((prevData) =>
        prevData.map((row, rowIndex) =>
          row.map((cell, colIndex) => {
            if (
              rowIndex >= startRow &&
              rowIndex <= endRow &&
              colIndex >= startCol &&
              colIndex <= endCol
            ) {
              return 0; // Clear the cell
            }
            return cell;
          })
        )
      );
      console.log("Selected area cut");
    } else {
      console.log("No selection to cut");
    }
  };

  // Paste the clipboard data into the chart
  const handlePaste = () => {
    if (!clipboard) {
      console.log("No chart copied to paste");
      return;
    }
    console.log("Pasting");

    // Determine where to paste: top-left of the selection or (0, 0)
    const pasteRow = selection ? selection.startRow : 0;
    const pasteCol = selection ? selection.startCol : 0;

    setData((prevData) => {
      const newData = prevData.map((row, rowIndex) =>
        row.map((cell, colIndex) => {
          // Determine where to paste the clipboard content
          const clipboardRow = rowIndex - pasteRow;
          const clipboardCol = colIndex - pasteCol;

          // Check if the current cell falls within the clipboard boundaries
          if (
            clipboardRow >= 0 &&
            clipboardRow < clipboard.length &&
            clipboardCol >= 0 &&
            clipboardCol < clipboard[0].length
          ) {
            return clipboard[clipboardRow][clipboardCol];
          }
          return cell; // Keep the existing cell value if outside the clipboard area
        })
      );
      console.log(`Chart pasted at row ${pasteRow + 1}, column ${pasteCol + 1}`);
      return newData;
    });
  };

  function handleSetRows(newRows) {
    onUpdateGrid('bottom', newRows > rows);
  }

  function handleSetCols(newCols) {
    onUpdateGrid('right', newCols > cols);
  }

  function onUpdateGrid(loc, add) {
    let newdata = updateGrid(data, loc, add);
    setData(newdata);
    setRows(newdata ? newdata.length : 0);
    setCols(newdata.length > 0 && newdata[0] ? newdata[0].length : 0);
  }

  // Draw the chart on the canvas
  const onDrawChart = useCallback(() => {
    drawChart(canvasRef, settings, cols, rows, data, colors, currentRow, selection, isEdit, colorway);
  }, [cols, rows, settings, data, colors, currentRow, selection, isEdit, colorway]);

  // Update the canvas whenever the data, colors, grid size, or line settings change
  useEffect(() => {
    onDrawChart();
  }, [onDrawChart]);

  // Update a single cell with the current color
  const updateCell = (x, y) => {
    const col = Math.floor(x / settings.cellSize);
    const row = Math.floor(y / settings.cellSize / settings.yscale);

    if (col >= 0 && col < cols && row >= 0 && row < rows) {
      setData((prevData) => {
        const newData = [...prevData.map((r) => [...r])];
        newData[row][col] = currentColor;
        return newData;
      });
    }
  };

  function handleStart(event) {
    if (isSelect) {
      handleStartSelect(event);
    } else if (isEdit) {
      handleStartDraw(event);
    } else {
      // select current row
      const rect = canvasRef.current.getBoundingClientRect();
      //const x = event.clientX;
      const y = event.clientY || event.touches[0].clientY;
      const row = Math.floor((y - rect.top) / settings.cellSize / settings.yscale);
      setCurrentRow(row);
    }
  }

  function handleMove(event) {
    if (isSelect) {
      handleMoveSelect(event);
    } else if (isEdit) {
      handleMoveDraw(event);
    }
  }

  function handleEnd() {
    if (isSelect) {
      handleEndSelect();
    } else if (isEdit) {
      handleEndDraw();
    }
  }

  // DRAWING FUNCTIONS
  // Handle mouse or touch start
  const handleStartDraw = (event) => {
    isDrawing.current = true;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX || event.touches[0].clientX;
    const y = event.clientY || event.touches[0].clientY;
    updateCell(x - rect.left, y - rect.top);
  };

  // Handle mouse or touch move
  const handleMoveDraw = (event) => {
    if (!isDrawing.current) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX || event.touches[0].clientX;
    const y = event.clientY || event.touches[0].clientY;
    updateCell(x - rect.left, y - rect.top);
  };

  // Handle mouse or touch end
  const handleEndDraw = () => {
    isDrawing.current = false;
  };

  // SELECTION FUNCTIONS
  // Handle mouse down for selection
  const handleStartSelect = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = (event.clientX || event.touches[0].clientX) - rect.left;
    const y = (event.clientY || event.touches[0].clientY) - rect.top;

    const startCol = Math.floor(x / settings.cellSize);
    const startRow = Math.floor(y / settings.cellSize / settings.yscale);

    setSelection({ startRow, startCol, endRow: startRow, endCol: startCol });
    isSelecting.current = true;
  };

  const handleMoveSelect = (event) => {
    if (!isSelecting.current) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = (event.clientX || event.touches[0].clientX) - rect.left;
    const y = (event.clientY || event.touches[0].clientY) - rect.top;

    const endCol = Math.floor(x / settings.cellSize);
    const endRow = Math.floor(y / settings.cellSize / settings.yscale);

    setSelection((prevSelection) => ({
      ...prevSelection,
      endRow: Math.max(0, Math.min(rows - 1, endRow)),
      endCol: Math.max(0, Math.min(cols - 1, endCol)),
    }));
  };

  const handleEndSelect = () => {
    isSelecting.current = false;
  };

  // Handle row navigation
  const handlePrevRow = () => {
    setCurrentRow((prevRow) => (prevRow + 1) % rows);
  };

  const handleNextRow = () => {
    setCurrentRow((prevRow) => (prevRow - 1 + rows) % rows);
  };

  // Export the data as a JSON file
  const handleSaveToFile = () => {
    const dataStr = JSON.stringify(data);
    const blob = new Blob([dataStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    // Create a temporary download link
    const link = document.createElement("a");
    link.href = url;
    link.download = "data.json";
    link.click();
    URL.revokeObjectURL(url);
  };

  // Export the canvas as an image
  const handleExportImage = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "chart.png";
    link.click();
  };

  // Load a saved data from a JSON file
  const handleLoadData = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const loadedData = JSON.parse(e.target.result);
        setData(loadedData);
        setRows(loadedData.length);
        setCols(loadedData[0].length);
      };
      reader.readAsText(file);
      setShowFileImport(false);
    }
  };

  // Clear the grid
  const handleClear = () => {
    setData(Array.from({ length: rows }, () => Array.from({ length: cols }, () => 0)));
  };

  // Reset the grid
  const handleReset = () => {
    setData(initialData ? initialData : [[0]]);
    setRows(initialData ? initialData.length : 0);
    setCols(initialData.length > 0 && initialData[0] ? initialData[0].length : 0);
  }

  const isDataChanged = JSON.stringify(data) !== JSON.stringify(initialData);

  const moreMenu = (
    <Menu>
      <MenuItem icon='select' onClick={() => setIsSelect(true)} text="Selection Mode" />
      <MenuItem icon="undo" onClick={handleReset} text="Undo all" />
      <MenuItem icon="eraser" onClick={handleClear} text="Clear" />
      <MenuDivider />
      <MenuItem icon='media' onClick={handleExportImage} text="Export as image" />
      <MenuItem icon='download' onClick={handleSaveToFile} text="Export to file" />
      <MenuItem icon="upload" onClick={() => setShowFileImport(true)} text="Import from file" />
    </Menu>
  );

  return (
    <div style={styles.container}>
      {isEdit && !isSelect && <ColorPicker initialColors={initialColors}
        colors={colors} setColors={setColors}
        currentColor={currentColor} setCurrentColor={setCurrentColor} onSaveProperty={onSaveProperty} />
      }

      <div style={{ ...appstyles.controlsHorizontal }}>
        <Button outlined text="Settings" icon="settings" onClick={() => setShowSettings(!showSettings)} active={showSettings} />
        {isEdit && <Popover content={moreMenu} position={Position.BOTTOM}>
          <Button icon="more" text="Options" outlined />
        </Popover>}
        {!isEdit && <Button outlined icon="edit" onClick={() => setIsEdit(!isEdit)} text="Edit" />}
        {isEdit && <Button outlined icon="floppy-disk" text="Save" disabled={!isDataChanged}
          onClick={() => { onSaveProperty("data", data); setIsEdit(false) }} />}
        {isEdit && <Button outlined icon="cross" onClick={() => { handleReset(); setIsEdit(false); setIsSelect(false) }} text="Cancel" />}
      </div>

      {showSettings && <GridSettings settings={settings} setSettings={setSettings} />}

      {showFileImport && <div>
        <Label>Load data from file
          <ControlGroup>
            <FileInput type="file" accept="application/json" onChange={handleLoadData} />
            <Button icon="cross" onClick={() => setShowFileImport(false)} />
          </ControlGroup>
        </Label>
      </div>}

      {isEdit && <div style={appstyles.controlsHorizontal}>
        <Label>Rows:
          <NumericInput value={rows} min="0" max="100" onValueChange={handleSetRows} style={appstyles.numeric} />
        </Label>
        <Label>Columns:
          <NumericInput value={cols} min="0" style={appstyles.numeric} onValueChange={handleSetCols} />
        </Label>
      </div>}

      {isSelect && <div style={appstyles.controlsHorizontal}>
        <Button icon='duplicate' onClick={handleCopy} disabled={selection === null} />
        <Button icon='cut' onClick={handleCut} disabled={selection === null} />
        <Button icon='clipboard-file' onClick={handlePaste} disabled={clipboard === null} />
        <Button icon='disable' onClick={() => { setSelection(null); setIsSelect(false) }} />
      </div>}

      {isEdit && <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 4 }}>
        <Button icon="add-column-left" onClick={() => onUpdateGrid('left', true)} />
        <Button icon="remove-column-left" onClick={() => onUpdateGrid('left', false)} />
        <Divider />
        <Button icon="add-row-top" onClick={() => onUpdateGrid('top', true)} />
        <Button icon="remove-row-top" onClick={() => onUpdateGrid('top', false)} />
        <Divider />
        <Button icon="add-row-bottom" onClick={() => onUpdateGrid('bottom', true)} />
        <Button icon="remove-row-bottom" onClick={() => onUpdateGrid('bottom', false)} />
        <Divider />
        <Button icon="add-column-right" onClick={() => onUpdateGrid('right', true)} />
        <Button icon="remove-column-right" onClick={() => onUpdateGrid('right', false)} />
      </div>}

      <div style={styles.canvasContainer}>
        <canvas ref={canvasRef} style={isEdit ? styles.canvasDrawMode : {}}
          onMouseDown={handleStart} onMouseMove={handleMove} onMouseUp={handleEnd} onMouseLeave={handleEnd}
          onTouchStart={handleStart} onTouchMove={handleMove} onTouchEnd={handleEnd} />
      </div>

      {!isEdit && <div style={appstyles.controlsHorizontal}>
        <Button icon="arrow-down" onClick={handlePrevRow} >Previous</Button>
        <Button icon="arrow-up" onClick={handleNextRow} >Next</Button>
      </div>}

    </div>
  );
};

const styles = {
  container: {
    marginTop: '20px',
  },
  colorWrapper: {
    textAlign: "center",
    fontSize: '10px'
  },
  canvasContainer: {
    overflowX: "auto",
    maxWidth: "100%",
    paddingTop: 8,
    borderStyle: "solid",
    borderWidth: "1px 0px 1px 0px",
    borderColor: "lightgray",
  },
  canvasDrawMode: {
    touchAction: "none", // Prevent touch scrolling
  },
};
