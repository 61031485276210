import React, { useState, useEffect } from 'react';
import { Card, InputGroup, Button, Spinner } from '@blueprintjs/core';
import { useGetItemsQuery, useGetLibraryItemsQuery } from '../store/docApi';
import { appstyles } from '../styles/appstyles';
import ColorChartThumbnail from '../Charts/ColorChartThumbnail';

const MotifBrowser = ({ onSelectMotif, onClose }) => {
  const { data: motifs, isLoading: isLoadingMotifs } = useGetItemsQuery({ label: 'motif' });
  const { data: designs, isLoading: isLoadingDesigns } = useGetItemsQuery({ label: 'design' });
  const { data: libraryDesigns } = useGetLibraryItemsQuery({ label: 'design' });
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLibraryDesigns, setFilteredLibraryDesigns] = useState([]);
  const [filteredDesigns, setFilteredDesigns] = useState([]);
  const [selectedDesign, setSelectedDesign] = useState(null);

  useEffect(() => {
    if (motifs) {
      setFilteredLibraryDesigns(motifs);
    }
    if (designs) {
      setFilteredDesigns(designs);
    }
  }, [motifs, designs]);

  useEffect(() => {
    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      setFilteredLibraryDesigns(
        libraryDesigns?.filter(
          (design) =>
            (design.name && design.name.toLowerCase().includes(lowercasedTerm)) ||
            (design.description && design.description.toLowerCase().includes(lowercasedTerm))
        ) || []
      );
      setFilteredDesigns(
        designs?.filter(
          (design) =>
            (design.name && design.name.toLowerCase().includes(lowercasedTerm)) ||
            (design.description && design.description.toLowerCase().includes(lowercasedTerm))
        ) || []
      );
    } else {
      setFilteredLibraryDesigns(libraryDesigns || []);
      setFilteredDesigns(designs || []);
    }
  }, [searchTerm, libraryDesigns, designs]);

  const handleSelectDesign = (design) => {
    setSelectedDesign(design);
  };

  const handleBackToDesigns = () => {
    setSelectedDesign(null);
  };

  return (
    <div>
      <div style={styles.header}>
        <InputGroup leftIcon="search" placeholder="Search motifs..."
          value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: '10px' }} />
        <Button text="Close" onClick={onClose} />
      </div>
      <div style={styles.content}>
        {isLoadingMotifs || isLoadingDesigns ? (
          <Spinner style={appstyles.spinner} />
        ) : (
          <div style={styles.content}>
            {selectedDesign ? (
              <div style={styles.gridContainer}>
                <Button icon="arrow-left" text="Back to designs" onClick={handleBackToDesigns} />
                {selectedDesign.motifs?.map((motif, index) => (
                  <Card key={index} interactive onClick={() => onSelectMotif(motif)} style={styles.card}>
                    {motif.name}
                    <ColorChartThumbnail data={motif.data} />
                    <span style={styles.description}>{motif.description}</span>
                  </Card>
                ))}
              </div>
            ) : (
              <>{true && <div>
                <p>Motifs</p>
                <div style={styles.gridContainer}>
                  {motifs.map((motif, index) => (
                    <Card key={index} interactive onClick={() => onSelectMotif(motif)} style={styles.card}>
                      {motif.name}
                      <ColorChartThumbnail data={motif.data} />
                      <span style={styles.description}>{motif.description}</span>
                    </Card>
                  ))}
                </div></div>}
                <p>Designs</p>
                <div style={styles.gridContainer}>
                  {filteredDesigns.map((design, index) => (
                    <Card key={index} interactive onClick={() => handleSelectDesign(design)} style={styles.card}>
                      <center>
                        {design.name}
                        {design.motifs && design.motifs.length > 0 && ` (${design.motifs.length})`}
                      </center>
                      {design.picture && <img
                        src={design.picture}
                        alt={design.name}
                        style={{ maxHeight: 100, maxWidth: 100 }} />}
                      <span style={styles.description}>{design.description}</span>
                    </Card>
                  ))}
                </div>
                <br />
                <p>Library</p>
                <div style={styles.gridContainer}>
                  {filteredLibraryDesigns && filteredLibraryDesigns.map((design, index) => (
                    <Card key={index} interactive onClick={() => handleSelectDesign(design)} style={styles.card}>
                      <center>
                        {design.name}
                        {design.motifs && design.motifs.length > 0 && ` (${design.motifs.length})`}
                      </center>
                      {design.picture && <img
                        src={design.picture}
                        alt={design.name}
                        style={{ maxHeight: 100, maxWidth: 100 }} />}
                      <span style={styles.description}>{design.description}</span>
                    </Card>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: 'white',
  },
  content: {
    padding: '10px',
    overflowY: 'auto',
    maxHeight: 650,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
    margin: 2,
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '8px',
  },
  description: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontSize: 10
  }
};

export default MotifBrowser;