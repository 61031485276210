import { shapeMap, getShapeColor } from "./shapes.js";

// Draw the chart on the canvas
export const drawChart = (canvasRef, settings, cols, rows, data, colors, currentRow, selection, isEdit, colorway) => {
    const margin = 24; // Space for row and column numbers
    const { cellSize, yscale, thickLineEveryX, thickLineEveryY } = settings;
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");

    // Clear the canvas
    canvas.width = cols * cellSize + margin;
    canvas.height = rows * cellSize * yscale + margin;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the cells
    for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
            const cellValue = data[row]?.[col] ?? 0;
            const chroma = colorway?.chromas?.[cellValue] || {};
            const strokeColor = getShapeColor(chroma.color);
            const fillColor = chroma.isSolid ? strokeColor : "none";
            const shapeName = chroma.shape || "circle"; // Default to circle if no shape is defined
            const drawShape = shapeMap[shapeName]; // Get the corresponding drawing function

            // Draw the cell background
            ctx.fillStyle = chroma.color ? chroma.color : colors[cellValue];
            ctx.fillRect(col * cellSize, row * cellSize * yscale, cellSize, cellSize);

            // Draw the shape in the middle of the cell
            if (settings.showIcons && drawShape) {
                const centerX = col * cellSize + cellSize / 2;
                const centerY = row * cellSize * yscale + (cellSize * yscale) / 2;
                const shapeSize = cellSize / 2;

                drawShape(ctx, centerX, centerY, shapeSize, fillColor, strokeColor);
            }
        }
    }

    // Dim all rows except the current row
    /*
    ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
    for (let row = 0; row < rows; row++) {
      if (row !== currentRow) {
        ctx.fillRect(0, row * cellSize * yscale, cols * cellSize, cellSize);
      }
    }
    */
    // Draw a yellow border around the current row
    if (currentRow >= 0 && !isEdit) {
        ctx.strokeStyle = "yellow";
        ctx.lineWidth = 3;
        ctx.strokeRect(0, currentRow * cellSize, cols * cellSize, cellSize + 1);
    }

    // Draw the grid lines
    ctx.lineWidth = 0.3; // Thin lines for regular grid
    ctx.strokeStyle = "black";

    // Horizontal lines
    for (let y = 0; y <= rows; y++) {
        ctx.beginPath();
        const yPos = Math.round(y * cellSize * yscale) + 0.5; // Align to pixel boundary
        ctx.moveTo(0, yPos);
        ctx.lineTo(cols * cellSize, yPos);
        ctx.stroke();
    }

    // Vertical lines
    for (let x = 0; x <= cols; x++) {
        ctx.beginPath();
        const xPos = Math.round(x * cellSize) + 0.5; // Align to pixel boundary
        ctx.moveTo(xPos, 0);
        ctx.lineTo(xPos, rows * cellSize * yscale);
        ctx.stroke();
    }

    // Thicker grid lines
    ctx.lineWidth = 1; // Thicker lines
    ctx.strokeStyle = "black";

    // Horizontal thick lines (from bottom to top)
    if (thickLineEveryY > 0 && thickLineEveryY < rows) {
        for (let y = rows - thickLineEveryY; y >= 0; y -= thickLineEveryY) {
            ctx.beginPath();
            const yPos = Math.round(y * cellSize * yscale) + 0.5; // Align to pixel boundary
            ctx.moveTo(0, yPos);
            ctx.lineTo(cols * cellSize, yPos);
            ctx.stroke();
        }
    }

    // Vertical thick lines (from right to left)
    if (thickLineEveryX > 0 && thickLineEveryX < cols) {
        for (let x = cols - thickLineEveryX; x >= 0; x -= thickLineEveryX) {
            ctx.beginPath();
            const xPos = Math.round(x * cellSize) + 0.5; // Align to pixel boundary
            ctx.moveTo(xPos, 0);
            ctx.lineTo(xPos, rows * cellSize * yscale);
            ctx.stroke();
        }
    }

    // Draw the selection rectangle
    if (selection) {
        const { startRow, startCol, endRow, endCol } = selection;
        ctx.strokeStyle = "blue";
        ctx.lineWidth = 2;
        ctx.strokeRect(
            startCol * cellSize,
            startRow * cellSize * yscale,
            (endCol - startCol + 1) * cellSize,
            (endRow - startRow + 1) * cellSize * yscale
        );
    }

    // Draw row and column numbers if the cells are large enough
    if (cellSize >= 16) {
        // Fill background for row numbers
        ctx.fillStyle = "white";
        ctx.fillRect(cols * cellSize, 0, margin, rows * cellSize * yscale);

        // Fill background for column numbers
        ctx.fillRect(0, rows * cellSize * yscale, cols * cellSize + margin, margin);

        // Draw reversed row numbers on the right
        ctx.font = "10px Verdana";
        ctx.textAlign = "left";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "black";
        for (let row = 0; row < rows; row++) {
            ctx.fillText(rows - row, cols * cellSize + 5, row * cellSize * yscale + cellSize / 2);
        }

        // Draw reversed column numbers at the bottom
        ctx.textAlign = "center";
        ctx.textBaseline = "top";
        for (let col = 0; col < cols; col++) {
            ctx.fillText(cols - col, col * cellSize + cellSize / 2, rows * cellSize * yscale + 5);
        }
    }
};
