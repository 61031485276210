import { useState } from 'react';
import { Button } from '@blueprintjs/core';

const ColorPicker = ({ initialColors, colors, setColors, currentColor, setCurrentColor, onSaveProperty }) => {
  const [isEdit, setIsEdit] = useState(false);

  const onCancel = () => {
    setColors(initialColors);
    setIsEdit(false);
  }

  const onSave = () => {
    onSaveProperty('colors', colors);
    setIsEdit(false);
  }

  return (<div>
    <b>Color palette</b>
    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 16, paddingTop: 8, gap: 8 }}>
      {colors.map((color, index) => (
        <div key={index} style={{ textAlign: 'center', fontSize: '10px', 
          display: 'flex', flexDirection: 'column', gap: 4, justifyContent: 'center', alignItems: 'center' }}> 
          {isEdit && <Button icon="trash" minimal onClick={() => setColors((prevColors) => {
            const newColors = [...prevColors];
            newColors.splice(index, 1);
            return newColors;
          })} />}
          {isEdit && <input type="color" value={color} onChange={(e) => setColors((prevColors) => {
            const newColors = [...prevColors];
            newColors[index] = e.target.value;
            return newColors;
          })} />}
          <Button onClick={() => setCurrentColor(index)}
            style={{ backgroundColor: color, border: currentColor === index ? '3px solid ' : '1px solid gray', width: 22 }} />
            
          <div>{color}</div>
          </div>
      ))}
      {!isEdit && <Button minimal icon='edit' onClick={() => setIsEdit(true)} />}
      </div>
      {isEdit && 
      <div>
        <Button outlined icon='add' text='Add' onClick={() => setColors((prevColors) => [...prevColors, '#ffffff'])} />
        &nbsp;
        <Button outlined icon='floppy-disk' text='Save' onClick={onSave} />
        &nbsp;
        <Button outlined icon='cross' text='Cancel' onClick={onCancel} />
        <br/>
        <hr/>
        <br/>
      </div>
      }
    </div>
  );
};

export default ColorPicker;
