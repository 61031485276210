import React, { useState } from 'react';
import { useAddPropertyMutation, useUpdatePropertyMutation, useDeletePropertyMutation } from '../store/docApi';
import { Button, Card, Elevation, Dialog, Classes, FormGroup, InputGroup } from '@blueprintjs/core';
import Colorway from './Colorway';

export default function Colorways({ item }) {
    const [addProperty] = useAddPropertyMutation();
    const [updateProperty] = useUpdatePropertyMutation();
    const [deleteProperty] = useDeletePropertyMutation();
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newColorway, setNewColorway] = useState({ id: '', name: '', chromas: [] });

    const handleSelectColorway = (index) => {
        setSelectedIndex(index);
    };

    const addColorway = () => {
        let updatedColorways = item.colorways ? [...item.colorways] : [];
        newColorway.id = Date.now();
        updatedColorways.push(newColorway);
        addProperty({ label: item.label, pk: item.pk, id: item.id, name: 'colorways', value: newColorway });
        setNewColorway({ id: '', name: '', chromas: [] });
        setIsDialogOpen(false);
    };

    const saveColorway = (colorway) => {
        if (item.colorways[selectedIndex].name !== colorway.name) {
            updateProperty({ label: item.label, pk: item.pk, id: item.id, name: `colorways/${selectedIndex}/name`, value: colorway.name });
        }
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: `colorways/${selectedIndex}/chromas`, value: colorway.chromas });
    };

    function deleteColorway() {
        deleteProperty({ pk: item.pk, id: item.id, name: `colorways/${selectedIndex}` });
        setSelectedIndex(null);
    }

    return (
        <div>
            <h4>Colorways</h4>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px' }}>
                {item.colorways && item.colorways.map((colorway, index) => (
                    <Card key={index} interactive={true} style={{ padding: '10px' }}
                        elevation={Elevation.ONE} onClick={() => handleSelectColorway(index)}>
                        <b>{colorway.name}</b>
                        <div style={{ display: 'flex' }}>
                            {colorway.chromas && colorway.chromas.map((color, index) => (
                                <div key={index} style={{ width: 20, height: 20, backgroundColor: color.color }}></div>
                            ))}
                        </div>
                    </Card>
                ))}
            </div>
            <br/>
            <Button outlined icon="plus" onClick={() => setIsDialogOpen(true)}>Add Colorway</Button>
            <Dialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                title="Add New Colorway"
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup label="Name">
                        <InputGroup value={newColorway.name} onChange={(e) => setNewColorway({ ...newColorway, name: e.target.value })} />
                    </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button text="Cancel" onClick={() => setIsDialogOpen(false)} />
                        <Button intent="primary" text="Add" onClick={addColorway} />
                    </div>
                </div>
            </Dialog>
            {selectedIndex !== null && item.colorways && selectedIndex < item.colorways.length && (
                <Card style={{ marginTop: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
                        <Colorway initialColorway={item.colorways[selectedIndex]} onSave={saveColorway} onDelete={deleteColorway} onClose={() => setSelectedIndex(null)} />
                    </div>
                </Card>
            )}
        </div>
    );
};
